import React, { useEffect, useState } from "react";

import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import rightArrow from "../../assets/images/right-arrow.svg";
import qr from "../../assets/images/qr-code.svg";
import sendQr from "../../assets/images/send-message.png";
import file from "../../assets/images/file.svg";
import exp from "../../assets/images/export.svg";
import expblack from "../../assets/images/black-export.svg";
import Loader from "../../Components/Loader";
import inc from "../../assets/images/increase.svg";
import "react-datepicker/dist/react-datepicker.css";
import { CreateCompany, EditCompany } from "redux/Companies/actions";
import Pagintation from "../../Components/Pagintation";
import {
    adminApprove,
    CreateAttende,
    ExportAttendee,
    exportFile,
    getRejectedList,
    getAttendee,
    PrintTag,
    uploadAttendee,
} from "redux/Attendee/actions";
import { getTicketTypesConst } from "redux/TicketTypes/actions";
import { useParams } from "react-router";
import Form from "./Form";
import { useFormik } from "formik";
import { doSendConfirmation } from "redux/Events/thunks";
import { useLoadingSelector } from "redux/selectors";
import { useSearchParams } from "react-router-dom";

const initalCompany = {
    name: "",
    lname: "",
    email: "",
    mobileno: "",
};
const initalinfo = {
    tickets: [],
};
function RejectList() {
    const dispatch = useDispatch();
    const {
        rejectattendee,
        loader,
        importAttendee,
        number_of_pages,
        attendees_len,
        reject_len,
    } = useSelector((state) => state.attendee);
    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [error, setError] = useState(false);
    const [newCompany, setNewCompany] = useState(initalCompany);
    const [info, setInfo] = useState(initalinfo);
    const { ticketTypesConst } = useSelector((state) => state.ticketTypes);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState("");
    /////////////edit
    const [isInEditMode, setIsInEditMode] = useState();
    /////////////////
    const [dynamicQuestions, setDynamicQuestions] = useState([]);
    const [optionIds, setOptionIds] = useState([]);

    useEffect(() => {
        setDynamicQuestions(
            ticketTypesConst
                ?.filter((item) => item.id == filter)[0]
                ?.questions.filter(
                    (item) =>
                        item.type == "radiobutton" || item.type == "checkbox"
                ) || []
        );
        setOptionIds([]);
    }, [filter]);
    const params = useParams();
    useEffect(() => {
        if (params?.id) {
            dispatch(getTicketTypesConst({ event_id: params.id }));
            dispatch(
                getRejectedList({
                    event_id: params.id,
                    pagenumber: pageNumber,
                    search: searchText,
                    tickettype_id: filter,
                    ...(optionIds.length && {
                        option_id: optionIds.map((item) => item.option_id),
                    }),
                })
            );
        }
    }, [params, pageNumber, filter, isInEditMode, searchParams, optionIds]);
    const downloadFile = () => {
        dispatch(exportFile());
    };
    const uploadFile = (file) => {
        const body = new FormData();
        body.append("file", file);
        body.append("event_id", params.id);
        dispatch(uploadAttendee({ body, selectedEvent: params.id }));
    };
    const exportAttende = () => {
        dispatch(ExportAttendee({ event_id: params.id }));
    };

    const addind = () => {
        if (
            newCompany.name == "" ||
            newCompany.lname == "" ||
            newCompany.email == "" ||
            newCompany.mobileno == ""
        ) {
            setError(true);
            return;
        }
        if (updateFlag != -1) {
            let form = { ...newCompany };
            form.id = parseInt(updateFlag);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(EditCompany({ body }));
            setEventModal(false);
        } else {
            let form = { ...newCompany };

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            body.append("event_id", params?.id);

            dispatch(CreateAttende({ body, event_id: params.id }));
            setEventModal(false);
        }
        setNewCompany(initalCompany);
        setError(false);
        setUpdateFlag(-1);
    };
    const handleKeyDown = (e) => {
        // if (e.key === "Enter") {
        if (params?.id) {
            dispatch(
                getRejectedList({
                    event_id: params.id,
                    pagenumber: pageNumber,
                    search: searchText,
                    tickettype_id: filter,
                    ...(optionIds.length && {
                        option_id: optionIds.map((item) => item.option_id),
                    }),
                })
            );
            dispatch(getTicketTypesConst({ event_id: params.id }));
            // }
        }
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };
    console.log(info, "rejectattendee");
    const onButtonClick = (file) => {
        fetch(`https://frontiers.o-projects.org/storage/${file}`, {
            method: "get",
            mode: "no-cors",
            referrerPolicy: "no-referrer",
        })
            .then((res) => res.blob())
            .then((res) => {
                const aElement = document.createElement("a");
                aElement.setAttribute("download", `${file}`);
                const href = URL.createObjectURL(res);
                aElement.href = href;
                aElement.setAttribute("target", "_blank");
                aElement.click();
                URL.revokeObjectURL(href);
            });
    };
    return (
        <>
            <Modal
                isOpen={detailsModal}
                onRequestClose={() => {
                    setDetailsModal(false);
                    setInfo(initalinfo);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                        Payment Details
                    </h1>
                    <h1 className="d-flex  card-title fw-bolder fs-1 px-5">
                        Payment Order #{info?.id}
                    </h1>
                    <h2
                        className="d-flex  card-title  fw-bolder  px-5"
                        style={{
                            fontSize: "18px",
                        }}
                    >
                        Paymob order id{" "}
                        {info?.orderid
                            ? `#${info?.orderid}`
                            : "(pending payment)"}
                    </h2>
                    <div className="flex-center">
                        <div className="row">
                            <div className="row mb-5">
                                <div
                                    className="col-12"
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span
                                        className="d-flex align-items-center py-2 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Ticket Type:{" "}
                                        {info?.tickets[0]?.type?.name}
                                    </span>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-1 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Name: {info?.tickets[0]?.name}
                                    </span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-1 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Mobile Number:{" "}
                                        {info?.tickets[0]?.mobileno}
                                    </span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-1 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Email: {info?.tickets[0]?.email}
                                    </span>
                                </div>
                            </div>
                            {/* <div className="row mb-2">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-1 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Gender: {info?.tickets[0]?.gender}
                                    </span>
                                </div>
                            </div> */}
                            {/* <div className="row mb-2">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-1 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Age: {info?.tickets[0]?.agegroup}
                                    </span>
                                </div>
                            </div> */}
                            <div>
                                {info?.questions?.map((ques, index) => (
                                    <div className="row mb-2" key={index}>
                                        <div className="col-12">
                                            <span
                                                className="d-flex align-items-center py-1 px-5"
                                                style={{ color: "black" }}
                                            >
                                                {ques.name}:{" "}
                                                {ques.type == "text" ? (
                                                    ques.answers[0]
                                                        ?.answeredvalue
                                                ) : ques.type == "image" ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                margin: "0 1rem",
                                                            }}
                                                        ></div>

                                                        <button
                                                            type="button"
                                                            id="kt_password_reset_submit"
                                                            className="save-btn fw-bolder"
                                                            onClick={() =>
                                                                onButtonClick(
                                                                    `${ques.answers[0]?.answeredvalue}`
                                                                )
                                                            }
                                                        >
                                                            <span>
                                                                Download
                                                            </span>
                                                        </button>
                                                        <div
                                                            style={{
                                                                margin: "0 1rem",
                                                            }}
                                                        ></div>
                                                        <button
                                                            type="button"
                                                            id="kt_password_reset_submit"
                                                            className="save-btn fw-bolder"
                                                            onClick={() =>
                                                                window.open(
                                                                    `https://frontiers.o-projects.org/storage/${ques.answers[0]?.answeredvalue}`,
                                                                    "_blank"
                                                                )
                                                            }
                                                        >
                                                            <span>Preview</span>
                                                        </button>
                                                    </div>
                                                ) : ques.type ==
                                                  "radiobutton" ? (
                                                    ques.answers[0]?.option
                                                        ?.name
                                                ) : ques.type == "session" ? (
                                                    ques.answers.map(
                                                        (ans, i) => (
                                                            <span key={i}>
                                                                {
                                                                    ans.session
                                                                        ?.title
                                                                }
                                                                {i !=
                                                                    ques.answers
                                                                        .length -
                                                                        1 && (
                                                                    <span>
                                                                        ,
                                                                    </span>
                                                                )}
                                                            </span>
                                                        )
                                                    )
                                                ) : (
                                                    ques.answers.map(
                                                        (ans, i) => (
                                                            <span key={i}>
                                                                {
                                                                    ans.option
                                                                        ?.name
                                                                }
                                                                {i !=
                                                                    ques.answers
                                                                        .length -
                                                                        1 && (
                                                                    <span>
                                                                        ,
                                                                    </span>
                                                                )}
                                                            </span>
                                                        )
                                                    )
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <hr></hr>
                            <div className="table-responsive">
                                <table className="table table-striped gy-2 gs-7">
                                    <thead>
                                        <tr
                                            className="fw-bolder fs-6  border-bottom border-gray-200"
                                            style={{
                                                backgroundColor: "#2B6EC8",
                                                borderRadius: "16px",
                                            }}
                                        >
                                            <th
                                                className="tfirst"
                                                style={{
                                                    width: "33%",
                                                    color: "white",
                                                }}
                                            >
                                                Name
                                            </th>
                                            <th
                                                style={{
                                                    width: "33%",
                                                    color: "white",
                                                }}
                                            >
                                                Phone Number
                                            </th>
                                            <th
                                                className="tlast"
                                                style={{
                                                    width: "33%",
                                                    color: "white",
                                                }}
                                            >
                                                Email
                                            </th>
                                        </tr>
                                        {info?.tickets?.map((code, index) => (
                                            <React.Fragment key={index}>
                                                {isInEditMode !== code?.id ? (
                                                    <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.name}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.mobileno}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.email}
                                                        </th>
                                                    </tr>
                                                ) : (
                                                    <Form
                                                        code={code}
                                                        setIsInEditMode={
                                                            setIsInEditMode
                                                        }
                                                        dispatch={dispatch}
                                                        setDetailsModal={
                                                            setDetailsModal
                                                        }
                                                    />
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </thead>
                                </table>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <button
                                    type="button"
                                    id="kt_password_reset_submit"
                                    className="save-btn fw-bolder"
                                    onClick={() => {
                                        const form = new FormData();
                                        form.append(
                                            "paymentorder_id",
                                            info?.id
                                        );
                                        form.append("is_admin_approve", 1);
                                        dispatch(
                                            adminApprove({
                                                body: form,
                                                event_id: params.id,
                                                tickettype_id: filter,
                                            })
                                        );
                                        setDetailsModal(false);
                                    }}
                                >
                                    Approve
                                </button>
                                <button
                                    style={{
                                        margin: "0 8px",
                                        color: "black",
                                        border: "1px solid",
                                        padding: "3px 11px",
                                        borderRadius: "16px",
                                        marginLeft: "5px",
                                        width: "20.313rem",
                                        height: "4.125rem",
                                        border: "1px solid #2b6ec8",
                                        background: "transparent  padding-box",
                                    }}
                                    className="delete-tab-btn"
                                    onClick={() => {
                                        const form = new FormData();
                                        form.append("paymentorder_id", info.id);
                                        form.append("is_admin_approve", 0);
                                        dispatch(
                                            adminApprove({
                                                body: form,
                                                id: params.id,
                                                tickettype_id: filter,
                                            })
                                        );
                                        setDetailsModal(false);
                                    }}
                                >
                                    Don't Approve
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewCompany(initalCompany);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create User
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Organization
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Attendee name
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.name
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="name"
                                        placeholder=""
                                        value={newCompany?.name}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Attendee last name
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.lname
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="lname"
                                        placeholder=""
                                        value={newCompany?.lname}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Email
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.email
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="email"
                                        placeholder=""
                                        value={newCompany?.email}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Mobile Number
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.mobileno
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="mobileno"
                                        placeholder=""
                                        value={newCompany?.mobileno}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                            <button
                                type="button"
                                id="kt_password_reset_submit"
                                className="save-btn fw-bolder"
                                onClick={addind}
                            >
                                <span>Save Changes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"Approval"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"rejected List"}
                        setSearchText={setSearchText}
                        EventName={params.id}
                        showUrl={true}
                        handleKeyDown={handleKeyDown}
                    ></PageHeader>
                    <div>
                        <Pagintation
                            setPageNumber={setPageNumber}
                            pageNumber={pageNumber}
                            pageCount={number_of_pages}
                        ></Pagintation>
                    </div>
                    <div
                        className="event-page"
                        style={{ marginInlineEnd: "2rem" }}
                    >
                        {!loader ? (
                            <>
                                <div className="row" style={{ margin: "0" }}>
                                    <>
                                        <div className="row">
                                            <div className="col-12 col-sm-5 col-lg-3 attendee-btn">
                                                <select
                                                    style={
                                                        error &&
                                                        !newCompany.type
                                                            ? {
                                                                  border: "1px solid red",
                                                                  width: "24rem",
                                                              }
                                                            : {
                                                                  width: "24rem",
                                                              }
                                                    }
                                                    type="text"
                                                    className="form-control form-control-lg form-control-solid"
                                                    name="type"
                                                    placeholder=""
                                                    value={filter}
                                                    onChange={(e) =>
                                                        setFilter(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option
                                                        value=""
                                                        disabled
                                                        selected
                                                    >
                                                        Select Ticket Type
                                                    </option>
                                                    {ticketTypesConst?.map(
                                                        (ticket, index) => (
                                                            <option
                                                                key={index}
                                                                value={
                                                                    ticket.id
                                                                }
                                                            >
                                                                {ticket.name}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                            {dynamicQuestions.map(
                                                (question, index) => (
                                                    <>
                                                        <div className="col-12 col-sm-5 col-lg-3 attendee-btn">
                                                            <select
                                                                style={
                                                                    error &&
                                                                    !newCompany.type
                                                                        ? {
                                                                              border: "1px solid red",
                                                                          }
                                                                        : {}
                                                                }
                                                                type="text"
                                                                className="form-control form-control-lg form-control-solid"
                                                                name={
                                                                    question.id
                                                                }
                                                                placeholder=""
                                                                value={
                                                                    optionIds?.find(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.id ==
                                                                            question.id
                                                                    )?.option_id
                                                                }
                                                                onChange={(e) =>
                                                                    setOptionIds(
                                                                        (
                                                                            prev
                                                                        ) => {
                                                                            if (
                                                                                optionIds.find(
                                                                                    (
                                                                                        item
                                                                                    ) =>
                                                                                        item.id ==
                                                                                        question.id
                                                                                )
                                                                            ) {
                                                                                return prev.map(
                                                                                    (
                                                                                        item
                                                                                    ) =>
                                                                                        item.id ==
                                                                                        question.id
                                                                                            ? {
                                                                                                  id: question.id,
                                                                                                  option_id:
                                                                                                      e
                                                                                                          .target
                                                                                                          .value,
                                                                                              }
                                                                                            : item
                                                                                );
                                                                            } else {
                                                                                return [
                                                                                    ...prev,
                                                                                    {
                                                                                        id: question.id,
                                                                                        option_id:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                    },
                                                                                ];
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                <option
                                                                    value=""
                                                                    disabled
                                                                    selected
                                                                >
                                                                    Select{" "}
                                                                    {
                                                                        question.name
                                                                    }
                                                                </option>
                                                                {question.options?.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                item.id
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                    </>
                                                )
                                            )}
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-striped gy-2 gs-7">
                                                <thead>
                                                    <tr
                                                        className="fw-bolder fs-6  border-bottom border-gray-200"
                                                        style={{
                                                            backgroundColor:
                                                                "#2B6EC8",
                                                            borderRadius:
                                                                "16px",
                                                        }}
                                                    >
                                                        <th
                                                            className="tfirst"
                                                            style={{
                                                                width: "20%",
                                                                color: "white",
                                                            }}
                                                        >
                                                            Name
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: "20%",
                                                                color: "white",
                                                            }}
                                                        >
                                                            Ticket Details
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: "20%",
                                                                color: "white",
                                                            }}
                                                        >
                                                            Phone Number
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: "20%",
                                                                color: "white",
                                                            }}
                                                        >
                                                            Email
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: "20%",
                                                                color: "white",
                                                            }}
                                                        >
                                                            Status
                                                        </th>
                                                    </tr>
                                                    {reject_len > 0 && (
                                                        <>
                                                            {rejectattendee?.map(
                                                                (
                                                                    code,
                                                                    index
                                                                ) => (
                                                                    <React.Fragment
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                                                                            <th
                                                                                style={{
                                                                                    color: "black",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    code.name
                                                                                }{" "}
                                                                                {
                                                                                    code.lname
                                                                                }
                                                                            </th>
                                                                            <th
                                                                                style={{
                                                                                    color: "black",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    code
                                                                                        .tickets
                                                                                        .length
                                                                                }

                                                                                X
                                                                                {
                                                                                    code
                                                                                        ?.tickets?.[0]
                                                                                        ?.tickete_type
                                                                                        ?.name
                                                                                }
                                                                                <span
                                                                                    style={{
                                                                                        display:
                                                                                            "block",
                                                                                        color: "#a0a0a0",
                                                                                    }}
                                                                                >
                                                                                    (
                                                                                    {
                                                                                        code
                                                                                            ?.tickets[0]
                                                                                            ?.price
                                                                                    }
                                                                                    EGP)
                                                                                </span>
                                                                            </th>
                                                                            <th
                                                                                style={{
                                                                                    color: "black",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    code.mobileno
                                                                                }
                                                                            </th>
                                                                            <th
                                                                                style={{
                                                                                    color: "black",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    code.email
                                                                                }
                                                                            </th>
                                                                            <th
                                                                                style={{
                                                                                    color: "black",
                                                                                }}
                                                                            >
                                                                                rejected
                                                                            </th>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                </thead>
                                            </table>
                                        </div>
                                    </>
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default RejectList;
