import { createReducer } from "@reduxjs/toolkit";
import { getFaqs } from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
    faqs: [],
    //sessionConst: [],
    loader: false,
    total: 0,
    number_of_pages: 0,
};

export const faqsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getFaqs.pending, (state) => {
            // state.errorpayload = true;
            state.loader = true;
        })
        .addCase(getFaqs.fulfilled, (state, { payload }) => {
            console.log(payload, "payload");
            state.faqs = payload?.data || [];
            state.loader = false;
            state.total = payload.total;
            state.number_of_pages = payload.total / payload.per_page;
        })
        .addCase(getFaqs.rejected, (state, { payload }) => {
            state.loader = false;

            //state.errorpayload = false;
        });
});

export default faqsReducer;
