import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { getTicketTypes } from "redux/TicketTypes/actions";

const route = process.env.REACT_APP_API;

export const editTicket = createAsyncThunk<any, any, any>(
    "attende/edit",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${route}attendances/modify`,
                params
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const ChangeTicketType = createAsyncThunk<any, any, any>(
    "edit/ticketTypeattend",
    async ({ data, selectedEvent, ticketId }: any, { dispatch }) => {
        try {
            const response = await axios({
                method: "get",
                url: `${route}paymentorder/updatetickettype?id=${data.id}&new_tickettypeid=${data.new_tickettypeid}&ticket_id=${ticketId}`,
            });
            if (response.status == 200 || response.status == 204) {
                toast.success("Ticket Type Edited successfully");
            }
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);
export const doSendConfirmation = createAsyncThunk<any, any, any>(
    "attende/send_conf",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${route}attendances/sendconfirmation`,
                params
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
