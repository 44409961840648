import { useEffect, useState } from "react";
import searchLogo from "../assets/images/search.svg";
import Logo from "../assets/images/Layer3.svg";
import Event from "../assets/images/FontAwsome (calendar-day).svg";
import Home from "../assets/images/home.svg";
import back from "../assets/images/arrow-ios-forward-outline.svg";
import log from "../assets/images/logout1.svg";
import navbar from "../assets/images/nav.svg";
import closenav from "../assets/images/close.svg";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { logOut } from "redux/Auth/actions";
import { Link, NavLink } from "react-router-dom";
import ModeratorProtect from "./ModeratorProtect";

const AsideMenu = ({ selected, setBack }) => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const params = useParams();
    const [expandQuestion, setExpandQuestion] = useState(false);
    const [expandEvent, setExpandEvent] = useState(false);
    const [expandTicket, setExpandTicket] = useState(false);
    const [expandRoles, setExpandRoles] = useState(false);
    const [expandAttendees, setExpandAttendess] = useState(false);
    const [expandReport, setExpandReport] = useState(false);
    const [eventAside, setEventAside] = useState(false);
    const { user } = useSelector((state) => state.user);
    const { selectedCompany, selectedEvent } = useSelector(
        (state) => state.events
    );
    const logout = () => {
        dispatch(logOut({ nav: nav }));
    };
    function myFunction() {
        var x = document.getElementById("kt_aside");
        var y = document.getElementById("logo");
        if (x.style.display === "none") {
            x.style.display = "flex";
            y.style.display = "none";
        } else {
            x.style.display = "none";
            y.style.display = "flex";
        }
    }
    return (
        <div>
            <div>
                <img
                    className="nav-logo"
                    id="logo"
                    src={navbar}
                    onClick={myFunction}
                ></img>
            </div>
            <div
                id="kt_aside"
                className="aside aside-dark aside-hoverable"
                style={{
                    height: "100vh",
                    overflow: "auto",
                    width: "230px",
                    position: "fixed",
                    zIndex: "9",
                }}
            >
                <div
                    className="aside-logo flex-column-auto mt-10"
                    id="kt_aside_logo"
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <span style={{ display: "flex", cursor: "pointer" }}>
                        {user?.user?.type == 1 && (
                            <img
                                alt="Logo"
                                src={back}
                                className="logo"
                                style={{ width: "0.8rem", margin: "0 7px" }}
                                onClick={() => nav(-1)}
                            />
                        )}

                        <NavLink
                            className={({ isActive }) => {
                                return isActive ? "activeNavLink" : "=";
                            }}
                            to="/"
                        >
                            <img
                                alt="Logo"
                                src={Logo}
                                className="logo"
                                style={{ width: "159px" }}
                            />
                        </NavLink>
                    </span>
                    <div
                        id="kt_aside_toggle"
                        className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
                    >
                        <img
                            src={closenav}
                            className="nav-logo"
                            style={{ width: "1.2rem" }}
                            onClick={myFunction}
                        ></img>
                    </div>
                </div>
                <>
                    <div className="aside-menu flex-column-fluid">
                        <div
                            className="hover-scroll-overlay-y my-2 py-5 py-lg-8"
                            id="kt_aside_menu_wrapper"
                        >
                            <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500">
                                {user?.user?.type != 103 && (
                                    <div className="menu-item">
                                        <NavLink
                                            className={({ isActive }) => {
                                                return isActive
                                                    ? "activeNavLink"
                                                    : "=";
                                            }}
                                            to={
                                                "/" +
                                                params.id +
                                                "/organiztion/events"
                                            }
                                        >
                                            <a
                                                className={
                                                    selected === "Events"
                                                        ? "menu-link active"
                                                        : "menu-link"
                                                }
                                                //onClick={() => router.push("/surveys")}
                                            >
                                                <span className="menu-icon">
                                                    <img
                                                        className="fs-3"
                                                        style={{
                                                            width: "0.9rem",
                                                        }}
                                                        src={Event}
                                                    />
                                                </span>

                                                <span className="menu-title">
                                                    DashBoard
                                                </span>
                                            </a>
                                        </NavLink>
                                    </div>
                                )}

                                {params.id && (
                                    <>
                                        {(user?.user?.type == 1 ||
                                            user?.user?.type == 103) && (
                                            <>
                                                <div
                                                    onClick={() =>
                                                        setExpandTicket(
                                                            !expandTicket
                                                        )
                                                    }
                                                    className={
                                                        expandTicket ||
                                                        selected ===
                                                            "TicketTypes" ||
                                                        selected ===
                                                            "EmailSettings" ||
                                                        selected ===
                                                            "Packages" ||
                                                        selected ===
                                                            "Questions" ||
                                                        selected === "Codes"
                                                            ? "menu-item menu-accordion show"
                                                            : "menu-item menu-accordion"
                                                    }
                                                    style={{
                                                        display: "block",
                                                    }}
                                                >
                                                    <span className="menu-link">
                                                        <span className="menu-icon"></span>

                                                        <span className="menu-title">
                                                            Tickets
                                                        </span>

                                                        <span className="menu-arrow"></span>
                                                    </span>

                                                    {user?.user?.type !=
                                                        103 && (
                                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                            <NavLink
                                                                className={({
                                                                    isActive,
                                                                }) => {
                                                                    return isActive
                                                                        ? "activeNavLink"
                                                                        : "=";
                                                                }}
                                                                to={
                                                                    "/" +
                                                                    params.id +
                                                                    "/ticket_types"
                                                                }
                                                            >
                                                                <a
                                                                    style={{
                                                                        paddingBottom: 0,
                                                                    }}
                                                                    className={
                                                                        selected ===
                                                                        "TicketTypes"
                                                                            ? "menu-link active"
                                                                            : "menu-link"
                                                                    }
                                                                >
                                                                    <span className="menu-icon"></span>
                                                                    <span
                                                                        className="menu-title"
                                                                        style={{
                                                                            padding:
                                                                                "0 2rem",
                                                                        }}
                                                                    >
                                                                        Ticket
                                                                        types
                                                                    </span>
                                                                </a>
                                                            </NavLink>
                                                        </div>
                                                    )}
                                                    {user?.user?.type !=
                                                        103 && (
                                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                            <NavLink
                                                                className={({
                                                                    isActive,
                                                                }) => {
                                                                    return isActive
                                                                        ? "activeNavLink"
                                                                        : "=";
                                                                }}
                                                                to={
                                                                    "/" +
                                                                    params.id +
                                                                    "/email-config/" +
                                                                    selectedCompany +
                                                                    ""
                                                                }
                                                            >
                                                                <a
                                                                    style={{
                                                                        paddingBottom: 0,
                                                                    }}
                                                                    className={
                                                                        selected ===
                                                                        "EmailSettings"
                                                                            ? "menu-link active"
                                                                            : "menu-link"
                                                                    }
                                                                >
                                                                    <span className="menu-icon"></span>
                                                                    <span
                                                                        className="menu-title"
                                                                        style={{
                                                                            padding:
                                                                                "0 2rem",
                                                                        }}
                                                                    >
                                                                        Email
                                                                        Settings
                                                                    </span>
                                                                </a>
                                                            </NavLink>
                                                        </div>
                                                    )}
                                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                        <NavLink
                                                            className={({
                                                                isActive,
                                                            }) => {
                                                                return isActive
                                                                    ? "activeNavLink"
                                                                    : "=";
                                                            }}
                                                            to={
                                                                "/" +
                                                                params.id +
                                                                "/ticket_list"
                                                            }
                                                        >
                                                            <a
                                                                style={{
                                                                    paddingBottom: 0,
                                                                }}
                                                                className={
                                                                    selected ===
                                                                    "TicketTypes"
                                                                        ? "menu-link active"
                                                                        : "menu-link"
                                                                }
                                                            >
                                                                <span className="menu-icon"></span>
                                                                <span
                                                                    className="menu-title"
                                                                    style={{
                                                                        padding:
                                                                            "0 2rem",
                                                                    }}
                                                                >
                                                                    Email
                                                                    Templates
                                                                </span>
                                                            </a>
                                                        </NavLink>
                                                    </div>
                                                    {user?.user?.type !=
                                                        103 && (
                                                        <>
                                                            {" "}
                                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                <NavLink
                                                                    className={({
                                                                        isActive,
                                                                    }) => {
                                                                        return isActive
                                                                            ? "activeNavLink"
                                                                            : "=";
                                                                    }}
                                                                    to={
                                                                        "/" +
                                                                        params.id +
                                                                        "/packages"
                                                                    }
                                                                >
                                                                    <a
                                                                        style={{
                                                                            paddingBottom: 0,
                                                                        }}
                                                                        className={
                                                                            selected ===
                                                                            "Packages"
                                                                                ? "menu-link active"
                                                                                : "menu-link"
                                                                        }
                                                                    >
                                                                        <span className="menu-icon"></span>
                                                                        <span
                                                                            className="menu-title"
                                                                            style={{
                                                                                padding:
                                                                                    "0 2rem",
                                                                            }}
                                                                        >
                                                                            Packages
                                                                        </span>
                                                                    </a>
                                                                </NavLink>
                                                            </div>
                                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                <NavLink
                                                                    className={({
                                                                        isActive,
                                                                    }) => {
                                                                        return isActive
                                                                            ? "activeNavLink"
                                                                            : "=";
                                                                    }}
                                                                    to={
                                                                        "/" +
                                                                        params.id +
                                                                        "/ticketsquestions"
                                                                    }
                                                                >
                                                                    <a
                                                                        style={{
                                                                            paddingBottom: 0,
                                                                        }}
                                                                        className={
                                                                            selected ===
                                                                            "Questions"
                                                                                ? "menu-link active"
                                                                                : "menu-link"
                                                                        }
                                                                    >
                                                                        <span className="menu-icon"></span>
                                                                        <span
                                                                            className="menu-title"
                                                                            style={{
                                                                                padding:
                                                                                    "0 2rem",
                                                                            }}
                                                                        >
                                                                            Questions
                                                                        </span>
                                                                    </a>
                                                                </NavLink>
                                                            </div>
                                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                <NavLink
                                                                    className={({
                                                                        isActive,
                                                                    }) => {
                                                                        return isActive
                                                                            ? "activeNavLink"
                                                                            : "=";
                                                                    }}
                                                                    to={
                                                                        "/" +
                                                                        params.id +
                                                                        "/code"
                                                                    }
                                                                >
                                                                    <a
                                                                        style={{
                                                                            paddingBottom: 0,
                                                                        }}
                                                                        className={
                                                                            selected ===
                                                                            "Codes"
                                                                                ? "menu-link active"
                                                                                : "menu-link"
                                                                        }
                                                                    >
                                                                        <span className="menu-icon"></span>
                                                                        <span
                                                                            className="menu-title"
                                                                            style={{
                                                                                padding:
                                                                                    "0 2rem",
                                                                            }}
                                                                        >
                                                                            Codes
                                                                        </span>
                                                                    </a>
                                                                </NavLink>
                                                            </div>{" "}
                                                        </>
                                                    )}
                                                </div>
                                                {user?.user?.type != 103 && (
                                                    <div
                                                        onClick={() =>
                                                            setExpandEvent(
                                                                !expandEvent
                                                            )
                                                        }
                                                        className={
                                                            expandEvent ||
                                                            selected ===
                                                                "Page Builder" ||
                                                            selected ===
                                                                "Templates"
                                                                ? "menu-item menu-accordion show"
                                                                : "menu-item menu-accordion"
                                                        }
                                                        style={{
                                                            display: "block",
                                                        }}
                                                    >
                                                        <span className="menu-link">
                                                            <span className="menu-icon"></span>

                                                            <span className="menu-title">
                                                                Event Type
                                                            </span>

                                                            <span className="menu-arrow"></span>
                                                        </span>
                                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                            <NavLink
                                                                className={({
                                                                    isActive,
                                                                }) => {
                                                                    return isActive
                                                                        ? "activeNavLink"
                                                                        : "=";
                                                                }}
                                                                to={
                                                                    "/" +
                                                                    params.id +
                                                                    "/page_builder"
                                                                }
                                                            >
                                                                <a
                                                                    style={{
                                                                        paddingBottom: 0,
                                                                    }}
                                                                    className={
                                                                        selected ===
                                                                        "Page Builder"
                                                                            ? "menu-link active"
                                                                            : "menu-link"
                                                                    }
                                                                >
                                                                    <span className="menu-icon"></span>
                                                                    <span
                                                                        className="menu-title"
                                                                        style={{
                                                                            padding:
                                                                                "0 2rem",
                                                                        }}
                                                                    >
                                                                        Page
                                                                        builder
                                                                    </span>
                                                                </a>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                            <NavLink
                                                                className={({
                                                                    isActive,
                                                                }) => {
                                                                    return isActive
                                                                        ? "activeNavLink"
                                                                        : "=";
                                                                }}
                                                                to={
                                                                    "/" +
                                                                    params.id +
                                                                    "/templates"
                                                                }
                                                            >
                                                                <a
                                                                    style={{
                                                                        paddingBottom: 0,
                                                                    }}
                                                                    className={
                                                                        selected ===
                                                                        "Templates"
                                                                            ? "menu-link active"
                                                                            : "menu-link"
                                                                    }
                                                                >
                                                                    <span className="menu-icon"></span>
                                                                    <span
                                                                        className="menu-title"
                                                                        style={{
                                                                            padding:
                                                                                "0 2rem",
                                                                        }}
                                                                    >
                                                                        Templates
                                                                    </span>
                                                                </a>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {user?.user?.type != 5 &&
                                            user?.user?.type != 103 && (
                                                <>
                                                    <div
                                                        onClick={() =>
                                                            setExpandQuestion(
                                                                !expandQuestion
                                                            )
                                                        }
                                                        className={
                                                            expandQuestion ||
                                                            selected ===
                                                                "Agenda" ||
                                                            selected ===
                                                                "Announcement" ||
                                                            selected ===
                                                                "Sponsors" ||
                                                            selected ===
                                                                "Partners" ||
                                                            selected ===
                                                                "Booths" ||
                                                            selected ===
                                                                "SlideShow" ||
                                                            selected ===
                                                                "Sessions" ||
                                                            selected ===
                                                                "Speakers"
                                                                ? "menu-item menu-accordion show"
                                                                : "menu-item menu-accordion"
                                                        }
                                                        style={{
                                                            display: "block",
                                                        }}
                                                    >
                                                        <span className="menu-link">
                                                            <span className="menu-icon"></span>

                                                            <span className="menu-title">
                                                                Hybrid and
                                                                Virtual
                                                            </span>

                                                            <span className="menu-arrow"></span>
                                                        </span>
                                                        {user?.user?.type !=
                                                            "101" && (
                                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                <div
                                                                    style={{
                                                                        border: "none",
                                                                    }}
                                                                    className="menu-item "
                                                                >
                                                                    <NavLink
                                                                        className={({
                                                                            isActive,
                                                                        }) => {
                                                                            return isActive
                                                                                ? "activeNavLink"
                                                                                : "=";
                                                                        }}
                                                                        to={
                                                                            "/" +
                                                                            params.id +
                                                                            "/agenda"
                                                                        }
                                                                    >
                                                                        <a
                                                                            style={{
                                                                                paddingBottom: 0,
                                                                            }}
                                                                            className={
                                                                                selected ===
                                                                                "Agenda"
                                                                                    ? "menu-link active"
                                                                                    : "menu-link"
                                                                            }
                                                                        >
                                                                            <span className="menu-icon"></span>
                                                                            <span
                                                                                className="menu-title"
                                                                                style={{
                                                                                    padding:
                                                                                        "0 2rem",
                                                                                }}
                                                                            >
                                                                                Agenda
                                                                            </span>
                                                                        </a>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {user?.user?.type !=
                                                            "101" && (
                                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                <div
                                                                    style={{
                                                                        border: "none",
                                                                    }}
                                                                    className="menu-item "
                                                                >
                                                                    <NavLink
                                                                        className={({
                                                                            isActive,
                                                                        }) => {
                                                                            return isActive
                                                                                ? "activeNavLink"
                                                                                : "=";
                                                                        }}
                                                                        to={
                                                                            "/" +
                                                                            params.id +
                                                                            "/announcement"
                                                                        }
                                                                    >
                                                                        <a
                                                                            style={{
                                                                                paddingBottom: 0,
                                                                            }}
                                                                            className={
                                                                                selected ===
                                                                                "Announcement"
                                                                                    ? "menu-link active"
                                                                                    : "menu-link"
                                                                            }
                                                                        >
                                                                            <span className="menu-icon"></span>
                                                                            <span
                                                                                className="menu-title"
                                                                                style={{
                                                                                    padding:
                                                                                        "0 2rem",
                                                                                }}
                                                                            >
                                                                                Announcement
                                                                            </span>
                                                                        </a>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {user?.user?.type !=
                                                            "101" && (
                                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                <div
                                                                    style={{
                                                                        border: "none",
                                                                    }}
                                                                    className="menu-item "
                                                                >
                                                                    <NavLink
                                                                        className={({
                                                                            isActive,
                                                                        }) => {
                                                                            return isActive
                                                                                ? "activeNavLink"
                                                                                : "=";
                                                                        }}
                                                                        to={
                                                                            "/" +
                                                                            params.id +
                                                                            "/sessions"
                                                                        }
                                                                    >
                                                                        <a
                                                                            style={{
                                                                                paddingBottom: 0,
                                                                            }}
                                                                            className={
                                                                                selected ===
                                                                                "Sessions"
                                                                                    ? "menu-link active"
                                                                                    : "menu-link"
                                                                            }
                                                                        >
                                                                            <span className="menu-icon"></span>
                                                                            <span
                                                                                className="menu-title"
                                                                                style={{
                                                                                    padding:
                                                                                        "0 2rem",
                                                                                }}
                                                                            >
                                                                                Sessions
                                                                            </span>
                                                                        </a>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {user?.user?.type !=
                                                            "101" && (
                                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                <div
                                                                    style={{
                                                                        border: "none",
                                                                    }}
                                                                    className="menu-item "
                                                                >
                                                                    <NavLink
                                                                        className={({
                                                                            isActive,
                                                                        }) => {
                                                                            return isActive
                                                                                ? "activeNavLink"
                                                                                : "=";
                                                                        }}
                                                                        to={
                                                                            "/" +
                                                                            params.id +
                                                                            "/speakers"
                                                                        }
                                                                    >
                                                                        <a
                                                                            style={{
                                                                                paddingBottom: 0,
                                                                            }}
                                                                            className={
                                                                                selected ===
                                                                                "Speakers"
                                                                                    ? "menu-link active"
                                                                                    : "menu-link"
                                                                            }
                                                                        >
                                                                            <span className="menu-icon"></span>
                                                                            <span
                                                                                className="menu-title"
                                                                                style={{
                                                                                    padding:
                                                                                        "0 2rem",
                                                                                }}
                                                                            >
                                                                                Speakers
                                                                            </span>
                                                                        </a>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(user?.user?.type !=
                                                            "101" ||
                                                            user?.user?.type !=
                                                                103) && (
                                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                <div
                                                                    style={{
                                                                        border: "none",
                                                                    }}
                                                                    className="menu-item "
                                                                >
                                                                    <NavLink
                                                                        className={({
                                                                            isActive,
                                                                        }) => {
                                                                            return isActive
                                                                                ? "activeNavLink"
                                                                                : "=";
                                                                        }}
                                                                        to={
                                                                            "/" +
                                                                            params.id +
                                                                            "/slide-show"
                                                                        }
                                                                    >
                                                                        <a
                                                                            style={{
                                                                                paddingBottom: 0,
                                                                            }}
                                                                            className={
                                                                                selected ===
                                                                                "SlideShow"
                                                                                    ? "menu-link active"
                                                                                    : "menu-link"
                                                                            }
                                                                        >
                                                                            <span className="menu-icon"></span>
                                                                            <span
                                                                                className="menu-title"
                                                                                style={{
                                                                                    padding:
                                                                                        "0 2rem",
                                                                                }}
                                                                            >
                                                                                SlideShow
                                                                            </span>
                                                                        </a>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                            <div
                                                                style={{
                                                                    border: "none",
                                                                }}
                                                                className="menu-item "
                                                            >
                                                                <NavLink
                                                                    className={({
                                                                        isActive,
                                                                    }) => {
                                                                        return isActive
                                                                            ? "activeNavLink"
                                                                            : "=";
                                                                    }}
                                                                    to={
                                                                        "/" +
                                                                        params.id +
                                                                        "/sponsors"
                                                                    }
                                                                >
                                                                    <a
                                                                        style={{
                                                                            paddingBottom: 0,
                                                                        }}
                                                                        className={
                                                                            selected ===
                                                                            "Sponsors"
                                                                                ? "menu-link active"
                                                                                : "menu-link"
                                                                        }
                                                                    >
                                                                        <span className="menu-icon">
                                                                            {/* <img className="fs-3" src="/assets/dot.svg" /> */}
                                                                        </span>
                                                                        <span
                                                                            className="menu-title"
                                                                            style={{
                                                                                padding:
                                                                                    "0 2rem",
                                                                            }}
                                                                        >
                                                                            Sponsors
                                                                        </span>
                                                                    </a>
                                                                </NavLink>
                                                            </div>
                                                        </div>

                                                        {(user?.user?.type !=
                                                            "101" ||
                                                            user?.user?.type !=
                                                                103) && (
                                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                <div
                                                                    style={{
                                                                        border: "none",
                                                                    }}
                                                                    className="menu-item "
                                                                >
                                                                    <NavLink
                                                                        className={({
                                                                            isActive,
                                                                        }) => {
                                                                            return isActive
                                                                                ? "activeNavLink"
                                                                                : "=";
                                                                        }}
                                                                        to={
                                                                            "/" +
                                                                            params.id +
                                                                            "/partners"
                                                                        }
                                                                    >
                                                                        <a
                                                                            style={{
                                                                                paddingBottom: 0,
                                                                            }}
                                                                            className={
                                                                                selected ===
                                                                                "Partners"
                                                                                    ? "menu-link active"
                                                                                    : "menu-link"
                                                                            }
                                                                        >
                                                                            <span className="menu-icon"></span>
                                                                            <span
                                                                                className="menu-title"
                                                                                style={{
                                                                                    padding:
                                                                                        "0 2rem",
                                                                                }}
                                                                            >
                                                                                Partners
                                                                            </span>
                                                                        </a>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                            <div
                                                                style={{
                                                                    border: "none",
                                                                }}
                                                                className="menu-item "
                                                            >
                                                                <NavLink
                                                                    className={({
                                                                        isActive,
                                                                    }) => {
                                                                        return isActive
                                                                            ? "activeNavLink"
                                                                            : "=";
                                                                    }}
                                                                    to={
                                                                        "/" +
                                                                        params.id +
                                                                        "/booths"
                                                                    }
                                                                >
                                                                    <a
                                                                        style={{
                                                                            paddingBottom: 0,
                                                                        }}
                                                                        className={
                                                                            selected ===
                                                                            "Booths"
                                                                                ? "menu-link active"
                                                                                : "menu-link"
                                                                        }
                                                                    >
                                                                        <span className="menu-icon">
                                                                            {/* <img className="fs-3" src="/assets/dot.svg" /> */}
                                                                        </span>
                                                                        <span
                                                                            className="menu-title"
                                                                            style={{
                                                                                padding:
                                                                                    "0 2rem",
                                                                            }}
                                                                        >
                                                                            Booths
                                                                        </span>
                                                                    </a>
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {user?.user?.type !=
                                                        103 && (
                                                        <div
                                                            onClick={() =>
                                                                setExpandRoles(
                                                                    !expandRoles
                                                                )
                                                            }
                                                            className={
                                                                expandRoles ||
                                                                selected ===
                                                                    "ushers" ||
                                                                selected ===
                                                                    "Moderator" ||
                                                                selected ===
                                                                    "ba" ||
                                                                selected ===
                                                                    "Bs"
                                                                    ? "menu-item menu-accordion show"
                                                                    : "menu-item menu-accordion"
                                                            }
                                                            style={{
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            <span className="menu-link">
                                                                <span className="menu-icon"></span>

                                                                <span className="menu-title">
                                                                    User Roles
                                                                </span>

                                                                <span className="menu-arrow"></span>
                                                            </span>

                                                            {(user?.user
                                                                ?.type !=
                                                                "101" ||
                                                                user?.user
                                                                    ?.type !=
                                                                    103) && (
                                                                <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                    <div
                                                                        style={{
                                                                            border: "none",
                                                                        }}
                                                                        className="menu-item "
                                                                    >
                                                                        <NavLink
                                                                            className={({
                                                                                isActive,
                                                                            }) => {
                                                                                return isActive
                                                                                    ? "activeNavLink"
                                                                                    : "=";
                                                                            }}
                                                                            to={
                                                                                "/" +
                                                                                params.id +
                                                                                "/event-user-roles/ushers"
                                                                            }
                                                                        >
                                                                            <a
                                                                                style={{
                                                                                    paddingBottom: 0,
                                                                                }}
                                                                                className={
                                                                                    selected ===
                                                                                    "ushers"
                                                                                        ? "menu-link active"
                                                                                        : "menu-link"
                                                                                }
                                                                            >
                                                                                <span className="menu-icon"></span>
                                                                                <span
                                                                                    className="menu-title"
                                                                                    style={{
                                                                                        padding:
                                                                                            "0 2rem",
                                                                                    }}
                                                                                >
                                                                                    Ushers
                                                                                </span>
                                                                            </a>
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                <div
                                                                    style={{
                                                                        border: "none",
                                                                    }}
                                                                    className="menu-item "
                                                                >
                                                                    <NavLink
                                                                        className={({
                                                                            isActive,
                                                                        }) => {
                                                                            return isActive
                                                                                ? "activeNavLink"
                                                                                : "=";
                                                                        }}
                                                                        to={
                                                                            "/" +
                                                                            params.id +
                                                                            "/event-user-roles/moderator"
                                                                        }
                                                                    >
                                                                        <a
                                                                            style={{
                                                                                paddingBottom: 0,
                                                                            }}
                                                                            className={
                                                                                selected ===
                                                                                "Moderator"
                                                                                    ? "menu-link active"
                                                                                    : "menu-link"
                                                                            }
                                                                        >
                                                                            <span className="menu-icon"></span>
                                                                            <span
                                                                                className="menu-title"
                                                                                style={{
                                                                                    padding:
                                                                                        "0 2rem",
                                                                                }}
                                                                            >
                                                                                Moderators
                                                                            </span>
                                                                        </a>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                <div
                                                                    style={{
                                                                        border: "none",
                                                                    }}
                                                                    className="menu-item "
                                                                >
                                                                    <NavLink
                                                                        className={({
                                                                            isActive,
                                                                        }) => {
                                                                            return isActive
                                                                                ? "activeNavLink"
                                                                                : "=";
                                                                        }}
                                                                        to={
                                                                            "/" +
                                                                            params.id +
                                                                            "/event-user-roles/admins"
                                                                        }
                                                                    >
                                                                        <a
                                                                            style={{
                                                                                paddingBottom: 0,
                                                                            }}
                                                                            className={
                                                                                selected ===
                                                                                "ba"
                                                                                    ? "menu-link active"
                                                                                    : "menu-link"
                                                                            }
                                                                        >
                                                                            <span className="menu-icon">
                                                                                {/* <img className="fs-3" src="/assets/dot.svg" /> */}
                                                                            </span>
                                                                            <span
                                                                                className="menu-title"
                                                                                style={{
                                                                                    padding:
                                                                                        "0 2rem",
                                                                                }}
                                                                            >
                                                                                Booth/Sponsors
                                                                                Admin
                                                                            </span>
                                                                        </a>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                <div
                                                                    style={{
                                                                        border: "none",
                                                                    }}
                                                                    className="menu-item "
                                                                >
                                                                    <NavLink
                                                                        className={({
                                                                            isActive,
                                                                        }) => {
                                                                            return isActive
                                                                                ? "activeNavLink"
                                                                                : "=";
                                                                        }}
                                                                        to={
                                                                            "/" +
                                                                            params.id +
                                                                            "/event-user-roles/sales"
                                                                        }
                                                                    >
                                                                        <a
                                                                            style={{
                                                                                paddingBottom: 0,
                                                                            }}
                                                                            className={
                                                                                selected ===
                                                                                "Bs"
                                                                                    ? "menu-link active"
                                                                                    : "menu-link"
                                                                            }
                                                                        >
                                                                            <span className="menu-icon">
                                                                                {/* <img className="fs-3" src="/assets/dot.svg" /> */}
                                                                            </span>
                                                                            <span
                                                                                className="menu-title"
                                                                                style={{
                                                                                    padding:
                                                                                        "0 2rem",
                                                                                }}
                                                                            >
                                                                                Booths/Sponsor
                                                                                Sales
                                                                            </span>
                                                                        </a>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                        {/* <div className="menu-item ">
                      <NavLink
                                        className={({ isActive }) => {
                                            return isActive
                                                ? "activeNavLink"
                                                : "=";
                                        }} to={"/" + params.id + "/ushers"}>
                        <a
                          style={{ paddingBottom: 0 }}
                          className={
                            selected === "Ushers"
                              ? "menu-link active"
                              : "menu-link"
                          }
                        >
                          <span className="menu-icon"></span>
                          <span className="menu-title">Ushers</span>
                        </a>
                      </NavLink>
                    </div>
                    <div className="menu-item ">
                      <NavLink
                                        className={({ isActive }) => {
                                            return isActive
                                                ? "activeNavLink"
                                                : "=";
                                        }} to={"/" + params.id + "/sponsor-booth/admin"}>
                        <a
                          style={{ paddingBottom: 0 }}
                          className={
                            selected === "BSAdmins"
                              ? "menu-link active"
                              : "menu-link"
                          }
                        >
                          <span className="menu-icon"></span>
                          <span className="menu-title">Booths/Sponsor Admins</span>
                        </a>
                      </NavLink>
                    </div> */}
                                        {(user?.user?.type == 1 ||
                                            user?.user?.type == 5 ||
                                            user?.user?.type == 103) && (
                                            <>
                                                <div
                                                    onClick={() =>
                                                        setExpandAttendess(
                                                            !expandAttendees
                                                        )
                                                    }
                                                    className={
                                                        expandAttendees ||
                                                        selected ===
                                                            "Attendee" ||
                                                        selected ===
                                                            "Approval" ||
                                                        selected === "Reject" ||
                                                        selected ===
                                                            "CheckIn" ||
                                                        selected ===
                                                            "TimeLine" ||
                                                        selected ===
                                                            "Unpaid Attendee"
                                                            ? "menu-item menu-accordion show"
                                                            : "menu-item menu-accordion"
                                                    }
                                                    style={{
                                                        display: "block",
                                                    }}
                                                >
                                                    <span className="menu-link">
                                                        <span className="menu-icon"></span>

                                                        <span className="menu-title">
                                                            Attendees
                                                        </span>

                                                        <span className="menu-arrow"></span>
                                                    </span>
                                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                        <NavLink
                                                            className={({
                                                                isActive,
                                                            }) => {
                                                                return isActive
                                                                    ? "activeNavLink"
                                                                    : "=";
                                                            }}
                                                            to={
                                                                "/" +
                                                                params.id +
                                                                "/attendee-list"
                                                            }
                                                        >
                                                            <a
                                                                style={{
                                                                    paddingBottom: 0,
                                                                }}
                                                                className={
                                                                    selected ===
                                                                    "Attendee"
                                                                        ? "menu-link active"
                                                                        : "menu-link"
                                                                }
                                                            >
                                                                <span className="menu-icon"></span>
                                                                <span
                                                                    className="menu-title"
                                                                    style={{
                                                                        padding:
                                                                            "0 2rem",
                                                                    }}
                                                                >
                                                                    Confirmed
                                                                    list
                                                                </span>
                                                            </a>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                        <NavLink
                                                            className={({
                                                                isActive,
                                                            }) => {
                                                                return isActive
                                                                    ? "activeNavLink"
                                                                    : "=";
                                                            }}
                                                            to={
                                                                "/" +
                                                                params.id +
                                                                "/approve-list"
                                                            }
                                                        >
                                                            <a
                                                                style={{
                                                                    paddingBottom: 0,
                                                                }}
                                                                className={
                                                                    selected ===
                                                                    "Approval"
                                                                        ? "menu-link active"
                                                                        : "menu-link"
                                                                }
                                                            >
                                                                <span className="menu-icon"></span>
                                                                <span
                                                                    className="menu-title"
                                                                    style={{
                                                                        padding:
                                                                            "0 2rem",
                                                                    }}
                                                                >
                                                                    Pending list
                                                                </span>
                                                            </a>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                        <NavLink
                                                            className={({
                                                                isActive,
                                                            }) => {
                                                                return isActive
                                                                    ? "activeNavLink"
                                                                    : "=";
                                                            }}
                                                            to={
                                                                "/" +
                                                                params.id +
                                                                "/rejected-list"
                                                            }
                                                        >
                                                            <a
                                                                style={{
                                                                    paddingBottom: 0,
                                                                }}
                                                                className={
                                                                    selected ===
                                                                    "Reject"
                                                                        ? "menu-link active"
                                                                        : "menu-link"
                                                                }
                                                            >
                                                                <span className="menu-icon"></span>
                                                                <span
                                                                    className="menu-title"
                                                                    style={{
                                                                        padding:
                                                                            "0 2rem",
                                                                    }}
                                                                >
                                                                    Rejected
                                                                    List
                                                                </span>
                                                            </a>
                                                        </NavLink>
                                                    </div>
                                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                        <NavLink
                                                            className={({
                                                                isActive,
                                                            }) => {
                                                                return isActive
                                                                    ? "activeNavLink"
                                                                    : "=";
                                                            }}
                                                            to={
                                                                "/" +
                                                                params.id +
                                                                "/check-in"
                                                            }
                                                        >
                                                            <a
                                                                style={{
                                                                    paddingBottom: 0,
                                                                }}
                                                                className={
                                                                    selected ===
                                                                    "CheckIn"
                                                                        ? "menu-link active"
                                                                        : "menu-link"
                                                                }
                                                            >
                                                                <span className="menu-icon"></span>
                                                                <span
                                                                    className="menu-title"
                                                                    style={{
                                                                        padding:
                                                                            "0 2rem",
                                                                    }}
                                                                >
                                                                    Check In
                                                                </span>
                                                            </a>
                                                        </NavLink>
                                                    </div>
                                                    {/* <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                        <NavLink
                                                            className={({
                                                                isActive,
                                                            }) => {
                                                                return isActive
                                                                    ? "activeNavLink"
                                                                    : "=";
                                                            }}
                                                            to={
                                                                "/" +
                                                                params.id +
                                                                "/time-line"
                                                            }
                                                        >
                                                            <a
                                                                style={{
                                                                    paddingBottom: 0,
                                                                }}
                                                                className={
                                                                    selected ===
                                                                    "TimeLine"
                                                                        ? "menu-link active"
                                                                        : "menu-link"
                                                                }
                                                            >
                                                                <span className="menu-icon"></span>
                                                                <span
                                                                    className="menu-title"
                                                                    style={{
                                                                        padding:
                                                                            "0 2rem",
                                                                    }}
                                                                >
                                                                    TimeLine
                                                                    list
                                                                </span>
                                                            </a>
                                                        </NavLink>
                                                    </div> */}
                                                    {
                                                        <>
                                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                                <NavLink
                                                                    className={({
                                                                        isActive,
                                                                    }) => {
                                                                        return isActive
                                                                            ? "activeNavLink"
                                                                            : "=";
                                                                    }}
                                                                    to={
                                                                        "/" +
                                                                        params.id +
                                                                        "/unpaid-attendee"
                                                                    }
                                                                >
                                                                    <a
                                                                        style={{
                                                                            paddingBottom: 0,
                                                                        }}
                                                                        className={
                                                                            selected ===
                                                                            "Unpaid Attendee"
                                                                                ? "menu-link active"
                                                                                : "menu-link"
                                                                        }
                                                                    >
                                                                        <span className="menu-icon"></span>
                                                                        <span
                                                                            className="menu-title"
                                                                            style={{
                                                                                padding:
                                                                                    "0 2rem",
                                                                            }}
                                                                        >
                                                                            Unpaid
                                                                            Attendees
                                                                        </span>
                                                                    </a>
                                                                </NavLink>
                                                            </div>
                                                        </>
                                                    }
                                                </div>

                                                {user?.user?.type != 103 && (
                                                    <div
                                                        onClick={() =>
                                                            setExpandReport(
                                                                !expandReport
                                                            )
                                                        }
                                                        className={
                                                            expandReport ||
                                                            selected ===
                                                                "ReportQues" ||
                                                            selected ===
                                                                "ReportAttend" ||
                                                            selected ===
                                                                "ReportGender" ||
                                                            selected ===
                                                                "ReportAge" ||
                                                            selected ===
                                                                "ReportTypes"
                                                                ? "menu-item menu-accordion show"
                                                                : "menu-item menu-accordion"
                                                        }
                                                        style={{
                                                            display: "block",
                                                        }}
                                                    >
                                                        <span className="menu-link">
                                                            <span className="menu-icon"></span>

                                                            <span className="menu-title">
                                                                Reports
                                                            </span>

                                                            <span className="menu-arrow"></span>
                                                        </span>

                                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                            <NavLink
                                                                className={({
                                                                    isActive,
                                                                }) => {
                                                                    return isActive
                                                                        ? "activeNavLink"
                                                                        : "=";
                                                                }}
                                                                to={
                                                                    "/" +
                                                                    params.id +
                                                                    "/reports/attendance"
                                                                }
                                                            >
                                                                <a
                                                                    style={{
                                                                        paddingBottom: 0,
                                                                    }}
                                                                    className={
                                                                        selected ===
                                                                        "ReportAttend"
                                                                            ? "menu-link active"
                                                                            : "menu-link"
                                                                    }
                                                                >
                                                                    <span className="menu-icon"></span>
                                                                    <span
                                                                        className="menu-title"
                                                                        style={{
                                                                            padding:
                                                                                "0 2rem",
                                                                        }}
                                                                    >
                                                                        Attendance
                                                                    </span>
                                                                </a>
                                                            </NavLink>
                                                        </div>

                                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                            <NavLink
                                                                className={({
                                                                    isActive,
                                                                }) => {
                                                                    return isActive
                                                                        ? "activeNavLink"
                                                                        : "=";
                                                                }}
                                                                to={
                                                                    "/" +
                                                                    params.id +
                                                                    "/reports/gender"
                                                                }
                                                            >
                                                                <a
                                                                    style={{
                                                                        paddingBottom: 0,
                                                                    }}
                                                                    className={
                                                                        selected ===
                                                                        "ReportGender"
                                                                            ? "menu-link active"
                                                                            : "menu-link"
                                                                    }
                                                                >
                                                                    <span className="menu-icon"></span>
                                                                    <span
                                                                        className="menu-title"
                                                                        style={{
                                                                            padding:
                                                                                "0 2rem",
                                                                        }}
                                                                    >
                                                                        Gender
                                                                    </span>
                                                                </a>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                            <NavLink
                                                                className={({
                                                                    isActive,
                                                                }) => {
                                                                    return isActive
                                                                        ? "activeNavLink"
                                                                        : "=";
                                                                }}
                                                                to={
                                                                    "/" +
                                                                    params.id +
                                                                    "/reports/types"
                                                                }
                                                            >
                                                                <a
                                                                    style={{
                                                                        paddingBottom: 0,
                                                                    }}
                                                                    className={
                                                                        selected ===
                                                                        "ReportTypes"
                                                                            ? "menu-link active"
                                                                            : "menu-link"
                                                                    }
                                                                >
                                                                    <span className="menu-icon"></span>
                                                                    <span
                                                                        className="menu-title"
                                                                        style={{
                                                                            padding:
                                                                                "0 2rem",
                                                                        }}
                                                                    >
                                                                        Types
                                                                    </span>
                                                                </a>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                            <NavLink
                                                                className={({
                                                                    isActive,
                                                                }) => {
                                                                    return isActive
                                                                        ? "activeNavLink"
                                                                        : "=";
                                                                }}
                                                                to={
                                                                    "/" +
                                                                    params.id +
                                                                    "/reports/age-group"
                                                                }
                                                            >
                                                                <a
                                                                    style={{
                                                                        paddingBottom: 0,
                                                                    }}
                                                                    className={
                                                                        selected ===
                                                                        "ReportAge"
                                                                            ? "menu-link active"
                                                                            : "menu-link"
                                                                    }
                                                                >
                                                                    <span className="menu-icon"></span>
                                                                    <span
                                                                        className="menu-title"
                                                                        style={{
                                                                            padding:
                                                                                "0 2rem",
                                                                        }}
                                                                    >
                                                                        Age
                                                                        Group
                                                                    </span>
                                                                </a>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                            <NavLink
                                                                className={({
                                                                    isActive,
                                                                }) => {
                                                                    return isActive
                                                                        ? "activeNavLink"
                                                                        : "=";
                                                                }}
                                                                to={
                                                                    "/" +
                                                                    params.id +
                                                                    "/reports/questions"
                                                                }
                                                            >
                                                                <a
                                                                    style={{
                                                                        paddingBottom: 0,
                                                                    }}
                                                                    className={
                                                                        selected ===
                                                                        "ReportQues"
                                                                            ? "menu-link active"
                                                                            : "menu-link"
                                                                    }
                                                                >
                                                                    <span className="menu-icon"></span>
                                                                    <span
                                                                        className="menu-title"
                                                                        style={{
                                                                            padding:
                                                                                "0 2rem",
                                                                        }}
                                                                    >
                                                                        Dynamic
                                                                        Questions
                                                                    </span>
                                                                </a>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}

                                <div
                                    className="aside-footer flex-column-auto pt-5 pb-7"
                                    id="kt_aside_footer"
                                    onClick={logout}
                                >
                                    <div
                                        style={{ border: "none" }}
                                        className="menu-item"
                                    >
                                        <a
                                            style={{
                                                paddingLeft: 25,
                                                paddingRight: 25,
                                            }}
                                            className="menu-link"
                                        >
                                            <span
                                                style={{
                                                    justifyContent:
                                                        "flex-start",
                                                }}
                                                className="menu-icon"
                                            >
                                                <img
                                                    width={15}
                                                    height={15}
                                                    className="fs-3"
                                                    src={log}
                                                />
                                            </span>
                                            <span
                                                style={{ color: "white" }}
                                                className="menu-title"
                                            >
                                                Sign Out
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </div>
    );
};

export default AsideMenu;
