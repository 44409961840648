import React, { useEffect, useMemo, useRef, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../../assets/images/addsponsor.svg";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import Pagintation from "../../Components/Pagintation";
import {
    CreateBooths,
    DeleteBooths,
    EditBooths,
    getBooths,
} from "redux/Booths/actions";
import {
    CreateSessions,
    DeleteSession,
    EditSession,
    getSessions,
} from "redux/Sessions/actions";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Switch, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getAgendaDays } from "../../redux/Agenda/actions";
import { useParams } from "react-router";
// import JoditEditor from "jodit-react";
import JoditEditor from "jodit-pro-react";

const route = process.env.REACT_APP_IMAGE_URI;

const initalSponsor = {
    title: "",
    description: "",
    location: "",
    logo: null,
    cover_image: null,
    video_link: "",
    start_time: "",
    end_time: "",
    agendaday_id: null,
    credit: 0,
    paid: 0,
    startDate: null,
    endDate: null,
    is_zoom: 0,
    webinar_topic: "",
    webinar_agenda: "",
    details_image: null,
    limit: "0",
    type: "offline",
    event_id: null,
};

function Sessions() {
    const defaultConfig = {
        license: "C464Q-1GIK1-BF6OK-K290Y",
        uploader: {
            url: "https://frontiers.o-projects.org/api/media/upload",
            format: "json",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            isSuccess: function (response) {
                // Assume success if there is a URL in the response
                return response && response.url;
            },
            getMsg: function (response) {
                // Return a success message or default message
                return response && response.url
                    ? "File uploaded successfully"
                    : "File upload failed";
            },
            process: function (response) {
                console.log(response.url); // Log the response for debugging
                return {
                    files: [
                        {
                            path: response.url,
                            type: "image",
                        },
                    ],
                    baseurl: "", // {string} Base url for filebrowser
                };
            },
            defaultHandlerSuccess: function (data) {
                var i,
                    field = "files";
                if (data[field] && data[field].length) {
                    for (i = 0; i < data[field].length; i += 1) {
                        this.selection.insertImage(data[field][i].path);
                    }
                }
            },
        },
    };
    const dispatch = useDispatch();
    const { sessions, loader, total, number_of_pages } = useSelector(
        (state) => state.sessions
    );
    const { agendaDays } = useSelector((state) => state.agenda);
    const [searchText, setSearchText] = useState("");
    const { selectedCompany, selectedEvent } = useSelector(
        (state) => state.events
    );
    const editor = useRef(null);

    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [error, setError] = useState(false);
    const [content, setContent] = useState();

    const [newSponsor, setNewSponsor] = useState(initalSponsor);
    const params = useParams();
    useEffect(() => {
        if (params.id) {
            dispatch(
                getSessions({ event_id: params.id, pagenumber: pageNumber })
            );
            dispatch(getAgendaDays({ event_id: params.id }));
        }
    }, [pageNumber, params]);

    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#00D7E1",
            },
            secondary: {
                // This is green.A700 as hex.
                main: "#222222",
            },
        },
    });
    const changeReachTextValue = useMemo(
        () => (
            <JoditEditor
                config={defaultConfig}
                ref={editor}
                style={
                    error && !newSponsor.description
                        ? { border: "1px solid red", height: "7rem" }
                        : { height: "7rem" }
                }
                type="text"
                className="form-control form-control-lg form-control-solid"
                name="description"
                placeholder=""
                value={newSponsor.description}
                onChange={(newContent) => {
                    {
                        setNewSponsor((prev) => {
                            return {
                                ...prev,
                                description: newContent,
                            };
                            // customconfirmemail: newContent.includes("@code")
                            //   ? newContent
                            //   : newContent.concat("", "@code"),
                        });
                    }
                }}
            />
        ),
        [editor, content]
    );
    const addind = () => {
        let start = agendaDays.findIndex(
            (e) => e.id == newSponsor.agendaday_id
        );

        if (
            newSponsor.title == "" ||
            !newSponsor.agendaday_id ||
            newSponsor.description == " " ||
            newSponsor.cover_image == " " ||
            newSponsor.end_time == " " ||
            newSponsor.start_time == " " ||
            newSponsor.logo == ""
        ) {
            setError(true);
            return;
        }
        if (
            newSponsor.is_zoom == 1 &&
            (newSponsor.webinar_agenda == "" || newSponsor.webinar_topic == "")
        ) {
            setError(true);
            return;
        }
        if (updateFlag != -1) {
            let form = { ...newSponsor };
            form.id = parseInt(updateFlag);
            form.startDate = `${agendaDays[start].date} ${newSponsor.start_time}`;

            form.endDate = `${agendaDays[start].date} ${newSponsor.end_time}`;

            const body = new FormData();

            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(EditSession({ body, selectedEvent: params.id }));
            setEventModal(false);
        } else {
            let form = { ...newSponsor };
            form.event_id = parseInt(params.id);
            form.startDate = `${agendaDays[start].date} ${newSponsor.start_time}`;

            form.endDate = `${agendaDays[start].date} ${newSponsor.end_time}`;
            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(CreateSessions({ body, selectedEvent: params.id }));
            setEventModal(false);
        }
        setNewSponsor(initalSponsor);
        setError(false);
        setUpdateFlag(-1);
    };
    const handleToggle1 = (event) => {
        if (newSponsor.paid == 0) {
            setNewSponsor({ ...newSponsor, paid: 1 });
        } else {
            setNewSponsor({ ...newSponsor, paid: 0, credit: 0 });
        }
    };
    const handleToggle2 = (event) => {
        if (newSponsor.type == "online") {
            setNewSponsor({ ...newSponsor, type: "offline" });
        } else {
            setNewSponsor({ ...newSponsor, type: "online" });
        }
    };
    const handleToggle3 = (event) => {
        if (newSponsor.is_zoom == 1) {
            setNewSponsor({
                ...newSponsor,
                is_zoom: 0,
                webinar_agenda: null,
                webinar_agenda: null,
            });
        } else {
            setNewSponsor({ ...newSponsor, is_zoom: 1 });
        }
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };
    return (
        <>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewSponsor(initalSponsor);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create New Session
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Session
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row">
                            <div
                                className="col-12 fv-row mb-5 fv-plugins-icon-container toggle-div"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{ color: "black" }}
                                    className="d-flex  fs-7 mb-2"
                                >
                                    Is the session paid or free ?
                                </div>
                                <div>
                                    <ThemeProvider theme={theme}>
                                        <Switch
                                            checked={
                                                newSponsor.paid == 1
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                handleToggle1(e);
                                            }}
                                            //color={event.published ? "primary" : "secondary"}
                                            value="selectUploadBool"
                                        />
                                    </ThemeProvider>
                                    {newSponsor.paid == 0 ? "Free" : "Paid"}
                                </div>
                            </div>
                            <div
                                className="col-12 fv-row mb-5 fv-plugins-icon-container toggle-div"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{ color: "black" }}
                                    className="d-flex  fs-7 mb-2"
                                >
                                    Is the session Offline or Online ?
                                </div>
                                <div>
                                    <ThemeProvider theme={theme}>
                                        <Switch
                                            checked={
                                                newSponsor.type == "online"
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                handleToggle2(e);
                                            }}
                                            //color={event.published ? "primary" : "secondary"}
                                            value="selectUploadBool"
                                        />
                                    </ThemeProvider>
                                    {newSponsor.type == "online"
                                        ? "Online"
                                        : "Offline"}
                                </div>
                            </div>
                            {newSponsor.type == "online" && (
                                <div
                                    className="col-12 fv-row mb-5 fv-plugins-icon-container toggle-div"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{ color: "black" }}
                                        className="d-flex  fs-7 mb-2"
                                    >
                                        Is the session on zoom or whereby?
                                    </div>
                                    <div>
                                        <ThemeProvider theme={theme}>
                                            <Switch
                                                checked={
                                                    newSponsor.is_zoom == 1
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    handleToggle3(e);
                                                }}
                                                //color={event.published ? "primary" : "secondary"}
                                                value="selectUploadBool"
                                            />
                                        </ThemeProvider>
                                        {newSponsor.is_zoom == 1
                                            ? "Zoom"
                                            : "Whereby"}
                                    </div>
                                </div>
                            )}

                            <div
                                className="col-6 fv-row mb-5 fv-plugins-icon-container"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    Session logo
                                </label>
                                {newSponsor.logo ? (
                                    <>
                                        <img
                                            style={{
                                                maxWidth: "35%",
                                                height: "7rem",
                                            }}
                                            src={
                                                newSponsor.logo
                                                    ? newSponsor.logo?.name
                                                        ? URL.createObjectURL(
                                                              newSponsor.logo
                                                          )
                                                        : `${route}/${newSponsor.logo}`
                                                    : ""
                                            }
                                        ></img>
                                        <div
                                            className="d-flex"
                                            style={{ margin: "1rem 0" }}
                                        >
                                            <label className="circles">
                                                <img
                                                    src={edit}
                                                    style={{ width: "1rem" }}
                                                ></img>
                                                <input
                                                    name="logo"
                                                    value={""}
                                                    onChange={(e) =>
                                                        setNewSponsor({
                                                            ...newSponsor,
                                                            [e.target.name]:
                                                                e.target
                                                                    .files[0],
                                                        })
                                                    }
                                                    type="file"
                                                    style={{
                                                        display: "none",
                                                        cursor: "pointer",
                                                    }}
                                                    accept=".png, .jpg, .jpeg, .svg"
                                                />
                                            </label>
                                            <div className="separtor"></div>
                                            <div className="circles">
                                                <img
                                                    src={del}
                                                    style={{
                                                        width: "0.8rem",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        setNewSponsor({
                                                            ...newSponsor,
                                                            logo: null,
                                                        });
                                                    }}
                                                ></img>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <label className="upload-btn">
                                        <input
                                            style={
                                                error && !newSponsor.logo
                                                    ? {
                                                          border: "1px solid red",
                                                      }
                                                    : {}
                                            }
                                            type="file"
                                            style={{ display: "none" }}
                                            name="logo"
                                            value={""}
                                            accept=".png, .jpg, .jpeg, .svg"
                                            onChange={(e) => {
                                                setNewSponsor((prevState) => ({
                                                    ...prevState,
                                                    [e.target.name]:
                                                        e.target.files[0],
                                                }));
                                            }}
                                        />
                                        Upload image
                                    </label>
                                )}
                                <div style={{ marginTop: "0.5rem" }}>
                                    <span>Logo Dimension:160x160 px</span>
                                </div>
                                <div>
                                    {!newSponsor.logo && error && (
                                        <span style={{ color: "red" }}>
                                            Logo is required
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div
                                className="col-6 fv-row mb-5 fv-plugins-icon-container"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    Session Cover
                                </label>
                                {newSponsor.cover_image ? (
                                    <>
                                        <img
                                            style={{
                                                maxWidth: "35%",
                                                height: "7rem",
                                            }}
                                            src={
                                                newSponsor.cover_image
                                                    ? newSponsor.cover_image
                                                          ?.name
                                                        ? URL.createObjectURL(
                                                              newSponsor.cover_image
                                                          )
                                                        : `${route}/${newSponsor.cover_image}`
                                                    : ""
                                            }
                                        ></img>
                                        <div
                                            className="d-flex"
                                            style={{ margin: "1rem 0" }}
                                        >
                                            <label className="circles">
                                                <img
                                                    src={edit}
                                                    style={{ width: "1rem" }}
                                                ></img>
                                                <input
                                                    name="cover_image"
                                                    value={""}
                                                    onChange={(e) =>
                                                        setNewSponsor({
                                                            ...newSponsor,
                                                            [e.target.name]:
                                                                e.target
                                                                    .files[0],
                                                        })
                                                    }
                                                    type="file"
                                                    style={{
                                                        display: "none",
                                                        cursor: "pointer",
                                                    }}
                                                    accept=".png, .jpg, .jpeg, .svg"
                                                />
                                            </label>
                                            <div className="separtor"></div>
                                            <div className="circles">
                                                <img
                                                    src={del}
                                                    style={{
                                                        width: "0.8rem",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        setNewSponsor({
                                                            ...newSponsor,
                                                            cover_image: null,
                                                        });
                                                    }}
                                                ></img>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <label className="upload-btn">
                                        <input
                                            style={
                                                error && !newSponsor.cover_image
                                                    ? {
                                                          border: "1px solid red",
                                                      }
                                                    : {}
                                            }
                                            type="file"
                                            style={{ display: "none" }}
                                            name="cover_image"
                                            value={""}
                                            accept=".png, .jpg, .jpeg, .svg"
                                            onChange={(e) =>
                                                setNewSponsor((prevState) => ({
                                                    ...prevState,
                                                    [e.target.name]:
                                                        e.target.files[0],
                                                }))
                                            }
                                        />
                                        Upload image
                                    </label>
                                )}
                                <div style={{ marginTop: "0.5rem" }}>
                                    <span>Banner Dimension:1349x600 px</span>
                                </div>
                                <div>
                                    {!newSponsor.cover_image && error && (
                                        <span style={{ color: "red" }}>
                                            Banner is required
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-12 fv-row mb-5 fv-plugins-icon-container"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <label
                                style={{ color: "black" }}
                                className="d-flex align-items-center fs-7 mb-2"
                            >
                                Session Details Image
                            </label>
                            {newSponsor.details_image ? (
                                <>
                                    <img
                                        style={{
                                            maxWidth: "35%",
                                            height: "7rem",
                                        }}
                                        src={
                                            newSponsor.details_image
                                                ? newSponsor.details_image?.name
                                                    ? URL.createObjectURL(
                                                          newSponsor.details_image
                                                      )
                                                    : `${route}/${newSponsor.details_image}`
                                                : ""
                                        }
                                    ></img>
                                    <div
                                        className="d-flex"
                                        style={{ margin: "1rem 0" }}
                                    >
                                        <label className="circles">
                                            <img
                                                src={edit}
                                                style={{ width: "1rem" }}
                                            ></img>
                                            <input
                                                name="details_image"
                                                value={""}
                                                onChange={(e) =>
                                                    setNewSponsor({
                                                        ...newSponsor,
                                                        [e.target.name]:
                                                            e.target.files[0],
                                                    })
                                                }
                                                type="file"
                                                style={{
                                                    display: "none",
                                                    cursor: "pointer",
                                                }}
                                                accept=".png, .jpg, .jpeg, .svg"
                                            />
                                        </label>
                                        <div className="separtor"></div>
                                        <div className="circles">
                                            <img
                                                src={del}
                                                style={{
                                                    width: "0.8rem",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setNewSponsor({
                                                        ...newSponsor,
                                                        details_image: null,
                                                    });
                                                }}
                                            ></img>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <label className="upload-btn">
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        name="details_image"
                                        value={""}
                                        accept=".png, .jpg, .jpeg, .svg"
                                        onChange={(e) => {
                                            setNewSponsor((prevState) => ({
                                                ...prevState,
                                                [e.target.name]:
                                                    e.target.files[0],
                                            }));
                                        }}
                                    />
                                    Upload image
                                </label>
                            )}
                            <div style={{ marginTop: "0.5rem" }}>
                                <span>Details Dimension:160x160 px</span>
                            </div>
                            <div></div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Session title
                                </span>
                                <input
                                    style={
                                        error && !newSponsor.title
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="title"
                                    placeholder=""
                                    value={newSponsor?.title}
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        {newSponsor.paid == 1 && (
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Credits
                                    </span>
                                    <input
                                        style={
                                            error && !newSponsor.credit
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="credit"
                                        placeholder=""
                                        value={newSponsor?.credit}
                                        onChange={(e) =>
                                            setNewSponsor({
                                                ...newSponsor,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {newSponsor.type == "online" && (
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Limit
                                    </span>
                                    <input
                                        style={
                                            error && !newSponsor.limit
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="limit"
                                        placeholder=""
                                        value={newSponsor?.limit}
                                        onChange={(e) =>
                                            setNewSponsor({
                                                ...newSponsor,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {newSponsor.is_zoom == 1 && (
                            <>
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <span
                                            className="d-flex align-items-center py-2 "
                                            style={{ color: "black" }}
                                        >
                                            Online session topic
                                        </span>
                                        <input
                                            style={
                                                error &&
                                                !newSponsor.webinar_topic &&
                                                newSponsor.is_zoom == 1
                                                    ? {
                                                          border: "1px solid red",
                                                      }
                                                    : {}
                                            }
                                            type="text"
                                            className="form-control form-control-lg form-control-solid"
                                            name="webinar_topic"
                                            placeholder=""
                                            value={newSponsor?.webinar_topic}
                                            onChange={(e) =>
                                                setNewSponsor({
                                                    ...newSponsor,
                                                    [e.target.name]:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <span
                                            className="d-flex align-items-center py-2 "
                                            style={{ color: "black" }}
                                        >
                                            Online session agenda
                                        </span>
                                        <input
                                            style={
                                                error &&
                                                !newSponsor.webinar_agenda &&
                                                newSponsor.is_zoom == 1
                                                    ? {
                                                          border: "1px solid red",
                                                      }
                                                    : {}
                                            }
                                            type="text"
                                            className="form-control form-control-lg form-control-solid"
                                            name="webinar_agenda"
                                            placeholder=""
                                            value={newSponsor?.webinar_agenda}
                                            onChange={(e) =>
                                                setNewSponsor({
                                                    ...newSponsor,
                                                    [e.target.name]:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="row mb-5 ">
                            <div className="col-12 fv-row mb-5 fv-plugins-icon-container">
                                <span
                                    className="d-flex align-items-center "
                                    style={{ color: "black" }}
                                >
                                    Agenda day
                                </span>
                                <select
                                    style={
                                        error && !newSponsor.agendaday_id
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="agendaday_id"
                                    placeholder=""
                                    value={newSponsor?.agendaday_id}
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                >
                                    <option></option>
                                    {agendaDays?.map((company, index) => (
                                        <option key={index} value={company.id}>
                                            {company.date}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 fv-row mb-5 fv-plugins-icon-container">
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    <span>Start time</span>
                                </label>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <TextField
                                        id="time"
                                        type="time"
                                        sx={
                                            error && !newSponsor?.start_time
                                                ? {
                                                      border: "1px solid red",
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                                : {
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="start_time"
                                        value={newSponsor?.start_time}
                                        onChange={(e) =>
                                            setNewSponsor({
                                                ...newSponsor,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-6 fv-row mb-5 fv-plugins-icon-container">
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    <span>End time</span>
                                </label>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <TextField
                                        id="time"
                                        type="time"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        sx={
                                            error && !newSponsor?.end_time
                                                ? {
                                                      border: "1px solid red",
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                                : {
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                        }
                                        name="end_time"
                                        value={newSponsor?.end_time}
                                        onChange={(e) =>
                                            setNewSponsor({
                                                ...newSponsor,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Session location
                                </span>
                                <input
                                    style={
                                        error && !newSponsor.location
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="location"
                                    placeholder=""
                                    value={newSponsor?.location}
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Session video
                                </span>
                                <JoditEditor
                                    config={defaultConfig}
                                    ref={editor}
                                    style={
                                        error && !newSponsor.video_link
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="video_link"
                                    placeholder=""
                                    value={newSponsor.video_link}
                                    onChange={(newContent) => {
                                        setNewSponsor((prev) => {
                                            return {
                                                ...prev,
                                                video_link: newContent,
                                            };
                                        });
                                    }}
                                />
                                {/* <input
                  style={
                    error && !newSponsor.video_link
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="video_link"
                  placeholder=""
                  value={newSponsor?.video_link}
                  onChange={(e) =>
                    setNewSponsor({
                      ...newSponsor,
                      [e.target.name]: e.target.value,
                    })
                  }
                /> */}
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Session Description
                                </span>
                                {changeReachTextValue}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                        <button
                            type="button"
                            id="kt_password_reset_submit"
                            className="save-btn fw-bolder"
                            onClick={addind}
                        >
                            <span>Save Changes</span>
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="aside-div">
                <AsideMenu selected={"Sessions"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Sessions"}
                        setSearchText={setSearchText}
                        EventName={params.id}
                        showUrl={true}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {total} results found
                                    </div>
                                    <Pagintation
                                        setPageNumber={setPageNumber}
                                        pageCount={number_of_pages}
                                    ></Pagintation>
                                </div>
                                <div className="row" style={{ margin: "0" }}>
                                    <table className="table table-striped gy-2 gs-7">
                                        <thead>
                                            <tr
                                                className="fw-bolder fs-6  border-bottom border-gray-200"
                                                style={{
                                                    backgroundColor: "#2B6EC8",
                                                    borderRadius: "16px",
                                                }}
                                            >
                                                <th
                                                    className="tfirst"
                                                    style={{
                                                        width: "25%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Thumbnail
                                                </th>
                                                <th
                                                    style={{
                                                        width: "25%",
                                                        color: "white",
                                                    }}
                                                >
                                                    name
                                                </th>
                                                <th
                                                    style={{
                                                        width: "25%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Event Day
                                                </th>
                                                <th
                                                    style={{
                                                        width: "25%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Time
                                                </th>
                                                <th
                                                    style={{
                                                        width: "25%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Date
                                                </th>
                                                <th
                                                    className="tlast"
                                                    style={{
                                                        width: "7%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Actions
                                                </th>
                                            </tr>

                                            {sessions.map((code, index) => (
                                                <React.Fragment key={index}>
                                                    <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.id}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.title}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.day}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {" "}
                                                            {new Date(
                                                                "1970-01-01T" +
                                                                    code?.start_time +
                                                                    "Z"
                                                            ).toLocaleTimeString(
                                                                "en-US",
                                                                {
                                                                    timeZone:
                                                                        "UTC",
                                                                    hour12: true,
                                                                    hour: "numeric",
                                                                    minute: "numeric",
                                                                }
                                                            )}
                                                            -
                                                            {new Date(
                                                                "1970-01-01T" +
                                                                    code?.end_time +
                                                                    "Z"
                                                            ).toLocaleTimeString(
                                                                "en-US",
                                                                {
                                                                    timeZone:
                                                                        "UTC",
                                                                    hour12: true,
                                                                    hour: "numeric",
                                                                    minute: "numeric",
                                                                }
                                                            )}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.day}
                                                        </th>

                                                        <th>
                                                            <span
                                                                onClick={() => {
                                                                    setUpdateFlag(
                                                                        code.id
                                                                    );
                                                                    setContent(
                                                                        code.description
                                                                    );
                                                                    setNewSponsor(
                                                                        {
                                                                            title: code.title,
                                                                            description:
                                                                                code.description,
                                                                            location:
                                                                                code.location,
                                                                            logo: code.logo,
                                                                            video_link:
                                                                                code.video_link,
                                                                            start_time:
                                                                                code.start_time,
                                                                            limit: code.limit,
                                                                            end_time:
                                                                                code.end_time,
                                                                            agendaday_id:
                                                                                code.agendaday_id,
                                                                            cover_image:
                                                                                code.cover_image,
                                                                            credit: code.credit,
                                                                            paid: code.paid,
                                                                            is_zoom:
                                                                                code.is_zoom,
                                                                            webinar_topic:
                                                                                code
                                                                                    .webinar
                                                                                    ?.topic,
                                                                            webinar_agenda:
                                                                                code
                                                                                    .webinar
                                                                                    ?.agenda,
                                                                            type: code.type,
                                                                            details_image:
                                                                                code.details_image,
                                                                            startDate:
                                                                                code.startDate,
                                                                            endDate:
                                                                                code.endDate,
                                                                        }
                                                                    );
                                                                    setEventModal(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={edit}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        marginRight:
                                                                            "1rem",
                                                                    }}
                                                                ></img>
                                                            </span>
                                                            <span
                                                                onClick={() => {
                                                                    dispatch(
                                                                        DeleteSession(
                                                                            {
                                                                                updateFlag:
                                                                                    code.id,
                                                                                selectedEvent:
                                                                                    params.id,
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={del}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                ></img>
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                        </thead>
                                    </table>
                                    <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                                        <button
                                            type="button"
                                            id="kt_password_reset_submit"
                                            className="save-btn fw-bolder"
                                            onClick={() => {
                                                setEventModal(true);
                                            }}
                                        >
                                            <span>Add Session</span>
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sessions;
