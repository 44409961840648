import React, { useEffect, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../../assets/images/addsponsor.svg";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import Pagintation from "../../Components/Pagintation";

import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getAgendaDays } from "../../redux/Agenda/actions";
import { useParams } from "react-router";
import {
    CreateAnnouncement,
    DeleteAnnouncement,
    EditAnnouncement,
    getAnnouncement,
} from "redux/Announcement/actions";

const route = process.env.REACT_APP_IMAGE_URI;

const initalSponsor = {
    content: "",
    image: "",
};

function Announcement() {
    const dispatch = useDispatch();
    const { announcements, loader, total, number_of_pages } = useSelector(
        (state) => state.announcement
    );
    const { agendaDays } = useSelector((state) => state.agenda);
    const [searchText, setSearchText] = useState("");
    const { selectedCompany, selectedEvent } = useSelector(
        (state) => state.events
    );
    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [error, setError] = useState(false);
    const [newSponsor, setNewSponsor] = useState(initalSponsor);
    const params = useParams();
    useEffect(() => {
        if (params.id) {
            dispatch(
                getAnnouncement({ event_id: params.id, pagenumber: pageNumber })
            );
        }
    }, [pageNumber, params]);

    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#00D7E1",
            },
            secondary: {
                // This is green.A700 as hex.
                main: "#222222",
            },
        },
    });

    const addind = () => {
        // if(newSponsor.title==''||newSponsor.agendaday_id||newSponsor.cover_image||newSponsor.description==''||
        // newSponsor.end_time||newSponsor.start_time||newSponsor.logo)
        // {
        //     setError(true)
        //     return ;
        // }
        if (updateFlag != -1) {
            let form = { ...newSponsor };

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            body.append("_method", "put");
            body.append("event_id", params.id);
            if (typeof body.image !== "obj") {
                body.append("image", "");
            }
            dispatch(
                EditAnnouncement({ body, updateFlag, selectedEvent: params.id })
            );
            setEventModal(false);
        } else {
            let form = { ...newSponsor };
            form.event_id = parseInt(params.id);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(CreateAnnouncement({ body, selectedEvent: params.id }));
            setEventModal(false);
        }
        setNewSponsor(initalSponsor);
        setError(false);
        setUpdateFlag(-1);
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };
    return (
        <>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewSponsor(initalSponsor);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create New Announcement
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Announcement
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row">
                            <div
                                className=" fv-row mb-5 fv-plugins-icon-container"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    Announcement logo
                                </label>
                                {newSponsor.image ? (
                                    <>
                                        <img
                                            style={{
                                                maxWidth: "35%",
                                                height: "7rem",
                                            }}
                                            src={
                                                newSponsor.image
                                                    ? newSponsor.image?.name
                                                        ? URL.createObjectURL(
                                                              newSponsor.image
                                                          )
                                                        : `${route}${newSponsor.image}`
                                                    : ""
                                            }
                                        ></img>
                                        <div
                                            className="d-flex"
                                            style={{ margin: "1rem 0" }}
                                        >
                                            <label className="circles">
                                                <img
                                                    src={edit}
                                                    style={{ width: "1rem" }}
                                                ></img>
                                                <input
                                                    name="image"
                                                    value={""}
                                                    onChange={(e) =>
                                                        setNewSponsor({
                                                            ...newSponsor,
                                                            [e.target.name]:
                                                                e.target
                                                                    .files[0],
                                                        })
                                                    }
                                                    type="file"
                                                    style={{
                                                        display: "none",
                                                        cursor: "pointer",
                                                    }}
                                                    accept=".png, .jpg, .jpeg, .svg"
                                                />
                                            </label>
                                            <div className="separtor"></div>
                                            <div className="circles">
                                                <img
                                                    src={del}
                                                    style={{
                                                        width: "0.8rem",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        setNewSponsor({
                                                            ...newSponsor,
                                                            image: null,
                                                        });
                                                    }}
                                                ></img>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <label className="upload-btn">
                                        <input
                                            style={
                                                error && !newSponsor.image
                                                    ? {
                                                          border: "1px solid red",
                                                      }
                                                    : {}
                                            }
                                            type="file"
                                            style={{ display: "none" }}
                                            name="image"
                                            value={""}
                                            accept=".png, .jpg, .jpeg, .svg"
                                            onChange={(e) => {
                                                setNewSponsor((prevState) => ({
                                                    ...prevState,
                                                    [e.target.name]:
                                                        e.target.files[0],
                                                }));
                                            }}
                                        />
                                        Upload image
                                    </label>
                                )}
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Announcement Content
                                </span>
                                <textarea
                                    style={
                                        error && !newSponsor.content
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    cols="40"
                                    rows="5"
                                    className="form-control form-control-lg form-control-solid"
                                    name="content"
                                    placeholder=""
                                    value={newSponsor?.content}
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                        <button
                            type="button"
                            id="kt_password_reset_submit"
                            className="save-btn fw-bolder"
                            onClick={addind}
                        >
                            <span>Save Changes</span>
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="aside-div">
                <AsideMenu selected={"Announcement"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Announcemets"}
                        setSearchText={setSearchText}
                        EventName={params.id}
                        showUrl={true}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {total} results found
                                    </div>
                                    <Pagintation
                                        setPageNumber={setPageNumber}
                                        pageCount={number_of_pages}
                                    ></Pagintation>
                                </div>
                                <div className="row" style={{ margin: "0" }}>
                                    <table className="table table-striped gy-2 gs-7">
                                        <thead>
                                            <tr
                                                className="fw-bolder fs-6  border-bottom border-gray-200"
                                                style={{
                                                    backgroundColor: "#2B6EC8",
                                                    borderRadius: "16px",
                                                }}
                                            >
                                                <th
                                                    className="tfirst"
                                                    style={{
                                                        width: "25%",
                                                        color: "white",
                                                    }}
                                                >
                                                    #
                                                </th>
                                                <th
                                                    style={{
                                                        width: "25%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Content
                                                </th>

                                                <th
                                                    className="tlast"
                                                    style={{
                                                        width: "7%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Actions
                                                </th>
                                            </tr>

                                            {announcements?.map(
                                                (code, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                                                            <th
                                                                style={{
                                                                    color: "black",
                                                                }}
                                                            >
                                                                {code.id}
                                                            </th>

                                                            <th
                                                                style={{
                                                                    color: "black",
                                                                }}
                                                            >
                                                                {code.content}
                                                            </th>
                                                            <th>
                                                                <span
                                                                    onClick={() => {
                                                                        setUpdateFlag(
                                                                            code.id
                                                                        );
                                                                        setNewSponsor(
                                                                            {
                                                                                content:
                                                                                    code.content,
                                                                                image: code.image,
                                                                            }
                                                                        );
                                                                        setEventModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            edit
                                                                        }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            marginRight:
                                                                                "1rem",
                                                                        }}
                                                                    ></img>
                                                                </span>
                                                                <span
                                                                    onClick={() => {
                                                                        dispatch(
                                                                            DeleteAnnouncement(
                                                                                {
                                                                                    updateFlag:
                                                                                        code.id,
                                                                                    selectedEvent:
                                                                                        params.id,
                                                                                }
                                                                            )
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            del
                                                                        }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                    ></img>
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            )}
                                        </thead>
                                    </table>
                                    <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                                        <button
                                            type="button"
                                            id="kt_password_reset_submit"
                                            className="save-btn fw-bolder"
                                            onClick={() => {
                                                setEventModal(true);
                                            }}
                                        >
                                            <span>Add Announcement</span>
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Announcement;
