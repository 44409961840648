import React, { useEffect, useState } from "react";

import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import rightArrow from "../../assets/images/right-arrow.svg";
import qr from "../../assets/images/qr-code.svg";
import sendQr from "../../assets/images/send-message.png";
import file from "../../assets/images/file.svg";
import exp from "../../assets/images/export.svg";
import expblack from "../../assets/images/black-export.svg";
import Loader from "../../Components/Loader";
import inc from "../../assets/images/increase.svg";
import "react-datepicker/dist/react-datepicker.css";
import { CreateCompany, EditCompany } from "redux/Companies/actions";
import Pagintation from "../../Components/Pagintation";
import {
    CreateAttende,
    DeleteAttende,
    ExportAttendee,
    exportFile,
    getApproval,
    getAttendee,
    PrintTag,
    uploadAttendee,
} from "redux/Attendee/actions";
import { getTicketTypesConst } from "redux/TicketTypes/actions";
import { useParams } from "react-router";
import Form from "./Form";
import { useFormik } from "formik";
import { doSendConfirmation } from "redux/Events/thunks";
import { useLoadingSelector } from "redux/selectors";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

const initalCompany = {
    name: "",
    lname: "",
    email: "",
    mobileno: "",
};
const initalinfo = {
    tickets: [],
};
function AttendeeList() {
    const dispatch = useDispatch();
    const {
        attendee,
        loader,
        importAttendee,
        number_of_pages,
        attendees_len,
        exportAttendee,
    } = useSelector((state) => state.attendee);
    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [ticktype, setTickType] = useState(-1);
    const [detailsModal, setDetailsModal] = useState(false);
    const [delModal, setDelModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [error, setError] = useState(false);
    const [newCompany, setNewCompany] = useState(initalCompany);
    const [info, setInfo] = useState(initalinfo);
    const [ticketId, setTicketId] = useState(0);
    const { ticketTypesConst } = useSelector((state) => state.ticketTypes);
    const [dynamicQuestions, setDynamicQuestions] = useState([]);
    const [filter, setFilter] = useState("");
    const user = useSelector((state) => state);
    const type = user?.user?.user?.user.type;
    const [optionIds, setOptionIds] = useState([]);
    /////////////edit
    const [isInEditMode, setIsInEditMode] = useState();
    /////////////////
    console.log(optionIds, "#########");
    const params = useParams();
    useEffect(() => {
        setDynamicQuestions(
            ticketTypesConst
                ?.filter((item) => item.id == filter)[0]
                ?.questions.filter(
                    (item) =>
                        item.type == "radiobutton" || item.type == "checkbox"
                ) || []
        );
        setOptionIds([]);
    }, [filter]);
    useEffect(() => {
        if (params?.id) {
            dispatch(
                getAttendee({
                    event_id: params.id,
                    pagenumber: pageNumber,
                    search: searchText,
                    tickettype_id: filter,
                    ...(optionIds.length && {
                        option_id: optionIds.map((item) => item.option_id),
                    }),
                })
            );
            dispatch(getTicketTypesConst({ event_id: params.id }));
        }
    }, [params, pageNumber, filter, optionIds, isInEditMode]);
    const downloadFile = () => {
        dispatch(exportFile());
    };
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            if (params?.id) {
                dispatch(
                    getAttendee({
                        event_id: params.id,
                        pagenumber: pageNumber,
                        search: searchText,
                        tickettype_id: filter,
                        ...(optionIds.length && {
                            option_id: optionIds.map((item) => item.option_id),
                        }),
                    })
                );
                dispatch(getTicketTypesConst({ event_id: params.id }));
            }
        }
    };
    const uploadFile = (file) => {
        const body = new FormData();
        body.append("file", file);
        body.append("event_id", params.id);
        body.append("ticketid", ticktype);
        setTickType(-1);
        setUploadModal(false);
        dispatch(uploadAttendee({ body, selectedEvent: params.id }));
    };
    const exportAttende = () => {
        if (!filter) {
            toast.error("Please select ticket type");
            return;
        }
        dispatch(
            ExportAttendee({
                event_id: params.id,
                tickettype_id: filter,
                ...(optionIds.length && {
                    option_id: optionIds.map((item) => item.option_id),
                }),
            })
        ).then((res) => {});
    };

    const addind = () => {
        if (
            newCompany.name == "" ||
            newCompany.lname == "" ||
            newCompany.email == "" ||
            newCompany.mobileno == ""
        ) {
            setError(true);
            return;
        }
        if (updateFlag != -1) {
            let form = { ...newCompany };
            form.id = parseInt(updateFlag);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(EditCompany({ body }));
            setEventModal(false);
        } else {
            let form = { ...newCompany };

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            body.append("event_id", params?.id);

            dispatch(CreateAttende({ body, event_id: params.id }));
            setEventModal(false);
        }
        setNewCompany(initalCompany);
        setError(false);
        setUpdateFlag(-1);
    };
    const addingPrice = (array) => {
        let total = 0;
        for (let i = 0; i < array.length; i++) {
            total = total + array[i].tickete_type?.price;
        }
        return total;
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };
    const addModalStyles2 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "50vh",
        },
    };
    const addModalStyles3 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "30vh",
        },
    };
    const onButtonClick = (file) => {
        fetch(`https://frontiers.o-projects.org/storage/${file}`, {
            method: "get",
            mode: "no-cors",
            referrerPolicy: "no-referrer",
        })
            .then((res) => res.blob())
            .then((res) => {
                const aElement = document.createElement("a");
                aElement.setAttribute("download", `${file}`);
                const href = URL.createObjectURL(res);
                aElement.href = href;
                aElement.setAttribute("target", "_blank");
                aElement.click();
                URL.revokeObjectURL(href);
            });
    };
    return (
        <>
            <Modal
                isOpen={uploadModal}
                onRequestClose={() => {
                    setUploadModal(false);
                    setTickType(-1);
                }}
                style={addModalStyles2}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                        Upload Attendee
                    </h1>
                    <div className="d-flex flex-center align-items-center">
                        <div className="attendee-btn">
                            <select
                                style={
                                    error && !newCompany.type
                                        ? {
                                              border: "1px solid red",
                                              width: "24rem",
                                          }
                                        : {
                                              width: "24rem",
                                          }
                                }
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                name="type"
                                placeholder=""
                                value={ticktype}
                                onChange={(e) => setTickType(e.target.value)}
                            >
                                <option value="" selected>
                                    Select Ticket Type
                                </option>
                                {ticketTypesConst?.map((ticket, index) => (
                                    <option key={index} value={ticket.id}>
                                        {ticket.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="d-flex flex-center align-items-center mt-5">
                        <label
                            className="login-btn"
                            style={
                                ticktype != -1
                                    ? {
                                          background: "#2B6EC8",
                                          fontWeight: "normal",
                                          fontSize: "1rem",
                                      }
                                    : {
                                          fontWeight: "normal",
                                          fontSize: "1rem",
                                          background: "#F9F9F9",
                                          color: "#D4D4D4",
                                      }
                            }
                        >
                            <input
                                type="file"
                                name="logo"
                                value={""}
                                style={{
                                    display: "none",
                                }}
                                accept="."
                                onChange={(e) => {
                                    if (ticktype != -1) {
                                        uploadFile(e.target.files[0]);
                                    }
                                }}
                                disabled={ticktype == -1 ? true : false}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <span
                                    style={{
                                        position: "relative",
                                        top: "15px",
                                    }}
                                >
                                    Upload Attendee
                                </span>
                            </div>
                        </label>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={detailsModal}
                onRequestClose={() => {
                    setDetailsModal(false);
                    setInfo(initalinfo);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                        Payment Details
                    </h1>
                    <h1 className="d-flex  card-title fw-bolder fs-1 px-5">
                        Payment Order #{info?.tickets[0]?.paymentorder_id}
                    </h1>
                    <div className="flex-center">
                        <div className="row">
                            <div className="row mb-5">
                                <div
                                    className="col-12"
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span
                                        className="d-flex align-items-center py-2 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Ticket Type:{" "}
                                        {info?.tickets[0]?.type?.name}
                                    </span>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-1 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Name: {info?.tickets[0]?.name}
                                    </span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-1 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Mobile Number:{" "}
                                        {info?.tickets[0]?.mobileno}
                                    </span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-1 px-5"
                                        style={{ color: "black" }}
                                    >
                                        Email: {info?.tickets[0]?.email}
                                    </span>
                                </div>
                            </div>
                            <div>
                                {info?.questions?.map((ques, index) => (
                                    <div className="row mb-2" key={index}>
                                        <div className="col-12">
                                            <span
                                                className="d-flex align-items-center py-1 px-5"
                                                style={{ color: "black" }}
                                            >
                                                {ques.name}:{" "}
                                                {ques.type == "text" ? (
                                                    ques.answers[0]
                                                        ?.answeredvalue
                                                ) : ques.type == "image" ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                margin: "0 1rem",
                                                            }}
                                                        ></div>

                                                        <button
                                                            type="button"
                                                            id="kt_password_reset_submit"
                                                            className="save-btn fw-bolder"
                                                            onClick={() =>
                                                                onButtonClick(
                                                                    `${ques.answers[0]?.answeredvalue}`
                                                                )
                                                            }
                                                        >
                                                            <span>
                                                                Download
                                                            </span>
                                                        </button>
                                                        <div
                                                            style={{
                                                                margin: "0 1rem",
                                                            }}
                                                        ></div>
                                                        <button
                                                            type="button"
                                                            id="kt_password_reset_submit"
                                                            className="save-btn fw-bolder"
                                                            onClick={() =>
                                                                window.open(
                                                                    `https://frontiers.o-projects.org/storage/${ques.answers[0]?.answeredvalue}`,
                                                                    "_blank"
                                                                )
                                                            }
                                                        >
                                                            <span>Preview</span>
                                                        </button>
                                                    </div>
                                                ) : // <img
                                                //   src={`}`}
                                                // ></img>
                                                ques.type == "radiobutton" ? (
                                                    ques.answers[0]?.option
                                                        ?.name
                                                ) : ques.type == "session" ? (
                                                    ques.answers.map(
                                                        (ans, i) => (
                                                            <span key={i}>
                                                                {
                                                                    ans.session
                                                                        .title
                                                                }
                                                                {i !=
                                                                    ques.answers
                                                                        .length -
                                                                        1 && (
                                                                    <span>
                                                                        ,
                                                                    </span>
                                                                )}
                                                            </span>
                                                        )
                                                    )
                                                ) : (
                                                    ques.answers.map(
                                                        (ans, i) => (
                                                            <span key={i}>
                                                                {
                                                                    ans.option
                                                                        .name
                                                                }
                                                                {i !=
                                                                    ques.answers
                                                                        .length -
                                                                        1 && (
                                                                    <span>
                                                                        ,
                                                                    </span>
                                                                )}
                                                            </span>
                                                        )
                                                    )
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <hr></hr>
                            <div className="table-responsive">
                                <table className="table table-striped gy-2 gs-7">
                                    <thead>
                                        <tr
                                            className="fw-bolder fs-6  border-bottom border-gray-200"
                                            style={{
                                                backgroundColor: "#2B6EC8",
                                                borderRadius: "16px",
                                            }}
                                        >
                                            <th
                                                className="tfirst"
                                                style={{
                                                    width: "15%",
                                                    color: "white",
                                                }}
                                            >
                                                Name
                                            </th>
                                            <th
                                                style={{
                                                    width: "15%",
                                                    color: "white",
                                                }}
                                            >
                                                Ticket Name
                                            </th>
                                            <th
                                                style={{
                                                    width: "15%",
                                                    color: "white",
                                                }}
                                            >
                                                Price
                                            </th>
                                            <th
                                                style={{
                                                    width: "15%",
                                                    color: "white",
                                                }}
                                            >
                                                Phone Number
                                            </th>
                                            <th
                                                style={{
                                                    width: "15%",
                                                    color: "white",
                                                }}
                                            >
                                                Email
                                            </th>
                                            <th
                                                style={{
                                                    width: "7%",
                                                    color: "white",
                                                }}
                                            >
                                                QR Code
                                            </th>
                                            <th
                                                className="tlast"
                                                style={{
                                                    width: "7%",
                                                    color: "white",
                                                }}
                                            >
                                                Actions
                                            </th>
                                        </tr>
                                        {info?.tickets?.map((code, index) => (
                                            <React.Fragment key={index}>
                                                {isInEditMode !== code?.id ? (
                                                    <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.name}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {
                                                                code
                                                                    .tickete_type
                                                                    ?.name
                                                            }
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {
                                                                code
                                                                    .tickete_type
                                                                    ?.price
                                                            }{" "}
                                                            EGP
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.mobileno}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.email}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code?.qrcode?.code}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            <img
                                                                src={qr}
                                                                width={15}
                                                                height={15}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    marginRight:
                                                                        "0.7rem",
                                                                }}
                                                                onClick={() => {
                                                                    dispatch(
                                                                        PrintTag(
                                                                            {
                                                                                id: code.id,
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                            ></img>
                                                            {type != 5 && (
                                                                <img
                                                                    onClick={() =>
                                                                        setIsInEditMode(
                                                                            code.id
                                                                        )
                                                                    }
                                                                    src={edit}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        marginRight:
                                                                            "0.7rem",
                                                                    }}
                                                                />
                                                            )}
                                                            <img
                                                                onClick={() =>
                                                                    dispatch(
                                                                        doSendConfirmation(
                                                                            {
                                                                                ticket_id:
                                                                                    code.id,
                                                                            }
                                                                        )
                                                                    )
                                                                }
                                                                src={sendQr}
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                width={15}
                                                                height={15}
                                                            ></img>
                                                        </th>
                                                    </tr>
                                                ) : (
                                                    <Form
                                                        code={code}
                                                        eventid={params.id}
                                                        setIsInEditMode={
                                                            setIsInEditMode
                                                        }
                                                        dispatch={dispatch}
                                                        tickets={
                                                            ticketTypesConst
                                                        }
                                                        setDetailsModal={
                                                            setDetailsModal
                                                        }
                                                        ticketId={ticketId}
                                                    />
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewCompany(initalCompany);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create User
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Organization
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Select Ticket Type
                                    </span>
                                    <select
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="ticketid"
                                        placeholder=""
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    >
                                        <option value="" selected>
                                            Select Ticket Type
                                        </option>
                                        {ticketTypesConst?.map(
                                            (ticket, index) => (
                                                <option
                                                    key={index}
                                                    value={ticket.id}
                                                >
                                                    {ticket.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Attendee name
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.name
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="name"
                                        placeholder=""
                                        value={newCompany?.name}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Attendee last name
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.lname
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="lname"
                                        placeholder=""
                                        value={newCompany?.lname}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Email
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.email
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="email"
                                        placeholder=""
                                        value={newCompany?.email}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Mobile Number
                                    </span>
                                    <input
                                        style={
                                            error && !newCompany.mobileno
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="mobileno"
                                        placeholder=""
                                        value={newCompany?.mobileno}
                                        onChange={(e) =>
                                            setNewCompany({
                                                ...newCompany,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                            <button
                                type="button"
                                id="kt_password_reset_submit"
                                className="save-btn fw-bolder"
                                onClick={addind}
                            >
                                <span>Save Changes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={delModal}
                onRequestClose={() => {
                    setDelModal(false);
                    updateFlag(-1);
                }}
                style={addModalStyles3}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Are you sure you want delete this attendee
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                            <button
                                type="button"
                                id="kt_password_reset_submit"
                                className="save-btn fw-bolder"
                                style={{ background: "red" }}
                                onClick={() => {
                                    dispatch(
                                        DeleteAttende({
                                            body: updateFlag,
                                            event_id: params.id,
                                            tickettype_id: filter,
                                            pagenumber: pageNumber,
                                            search: searchText,
                                            ...(optionIds.length && {
                                                option_id: optionIds.map(
                                                    (item) => item.option_id
                                                ),
                                            }),
                                        })
                                    );
                                    setDelModal(false);

                                    updateFlag(-1);
                                }}
                            >
                                <span>Delete Attendee</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="aside-div">
                <AsideMenu selected={"Attendee"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Attendee List"}
                        setSearchText={setSearchText}
                        EventName={params.id}
                        showUrl={true}
                        handleKeyDown={handleKeyDown}
                    ></PageHeader>
                    <div>
                        <Pagintation
                            setPageNumber={setPageNumber}
                            pageNumber={pageNumber}
                            pageCount={number_of_pages}
                        ></Pagintation>
                    </div>
                    <div
                        className="event-page"
                        style={{ marginInlineEnd: "2rem" }}
                    >
                        {!loader ? (
                            <>
                                <div className="row" style={{ margin: "0" }}>
                                    {attendees_len == 0 ? (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    marginTop: "5rem",
                                                }}
                                            >
                                                <div>
                                                    <img
                                                        src={file}
                                                        style={{
                                                            width: "4rem",
                                                        }}
                                                    ></img>
                                                </div>
                                                <div className="attendee-text">
                                                    Export The File
                                                </div>
                                                <div className="attendee-subtext">
                                                    To Start Entering Your
                                                    Attendees
                                                </div>
                                                <div
                                                    style={{
                                                        marginTop: "42px",
                                                    }}
                                                >
                                                    <button
                                                        className="login-btn"
                                                        style={{
                                                            background:
                                                                "#2B6EC8",
                                                            fontWeight:
                                                                "normal",
                                                            fontSize: "1rem",
                                                        }}
                                                        onClick={() => {
                                                            downloadFile();
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            <img
                                                                src={exp}
                                                                style={{
                                                                    width: "1rem",
                                                                }}
                                                            ></img>
                                                            <span
                                                                style={{
                                                                    margin: "0 0.5rem",
                                                                }}
                                                            >
                                                                EXPORT FILE
                                                            </span>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div
                                                    style={{
                                                        marginTop: "20px",
                                                    }}
                                                >
                                                    <label
                                                        className="login-btn"
                                                        style={
                                                            importAttendee
                                                                ? {
                                                                      background:
                                                                          "#2B6EC8",
                                                                      fontWeight:
                                                                          "normal",
                                                                      fontSize:
                                                                          "1rem",
                                                                  }
                                                                : {
                                                                      fontWeight:
                                                                          "normal",
                                                                      fontSize:
                                                                          "1rem",
                                                                      background:
                                                                          "#F9F9F9",
                                                                      color: "#D4D4D4",
                                                                  }
                                                        }
                                                    >
                                                        <input
                                                            type="file"
                                                            name="logo"
                                                            value={""}
                                                            style={{
                                                                display: "none",
                                                            }}
                                                            accept="."
                                                            onChange={(e) => {
                                                                if (
                                                                    importAttendee
                                                                ) {
                                                                    uploadFile(
                                                                        e.target
                                                                            .files[0]
                                                                    );
                                                                } else {
                                                                }
                                                            }}
                                                            disabled={
                                                                importAttendee
                                                                    ? false
                                                                    : true
                                                            }
                                                        />
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    position:
                                                                        "relative",
                                                                    top: "15px",
                                                                }}
                                                            >
                                                                IMPORT FILE
                                                            </span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="row">
                                                <div className="col-12 col-sm-5 col-lg-3 attendee-btn">
                                                    <select
                                                        style={
                                                            error &&
                                                            !newCompany.type
                                                                ? {
                                                                      border: "1px solid red",
                                                                  }
                                                                : {}
                                                        }
                                                        type="text"
                                                        className="form-control form-control-lg form-control-solid"
                                                        name="type"
                                                        placeholder=""
                                                        value={filter}
                                                        onChange={(e) =>
                                                            setFilter(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option
                                                            value=""
                                                            disabled
                                                            selected
                                                        >
                                                            Select Ticket Type
                                                        </option>
                                                        {ticketTypesConst?.map(
                                                            (ticket, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={
                                                                        ticket.id
                                                                    }
                                                                >
                                                                    {
                                                                        ticket.name
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                                {dynamicQuestions.map(
                                                    (question, index) => (
                                                        <>
                                                            <div className="col-12 col-sm-5 col-lg-3 attendee-btn">
                                                                <select
                                                                    style={
                                                                        error &&
                                                                        !newCompany.type
                                                                            ? {
                                                                                  border: "1px solid red",
                                                                              }
                                                                            : {}
                                                                    }
                                                                    type="text"
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    name={
                                                                        question.id
                                                                    }
                                                                    placeholder=""
                                                                    value={
                                                                        optionIds?.find(
                                                                            (
                                                                                item
                                                                            ) =>
                                                                                item.id ==
                                                                                question.id
                                                                        )
                                                                            ?.option_id
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setOptionIds(
                                                                            (
                                                                                prev
                                                                            ) => {
                                                                                if (
                                                                                    optionIds.find(
                                                                                        (
                                                                                            item
                                                                                        ) =>
                                                                                            item.id ==
                                                                                            question.id
                                                                                    )
                                                                                ) {
                                                                                    return prev.map(
                                                                                        (
                                                                                            item
                                                                                        ) =>
                                                                                            item.id ==
                                                                                            question.id
                                                                                                ? {
                                                                                                      id: question.id,
                                                                                                      option_id:
                                                                                                          e
                                                                                                              .target
                                                                                                              .value,
                                                                                                  }
                                                                                                : item
                                                                                    );
                                                                                } else {
                                                                                    return [
                                                                                        ...prev,
                                                                                        {
                                                                                            id: question.id,
                                                                                            option_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                        },
                                                                                    ];
                                                                                }
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    <option
                                                                        value=""
                                                                        disabled
                                                                        selected
                                                                    >
                                                                        Select{" "}
                                                                        {
                                                                            question.name
                                                                        }
                                                                    </option>
                                                                    {question.options?.map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => (
                                                                            <option
                                                                                key={
                                                                                    index
                                                                                }
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </>
                                                    )
                                                )}
                                                <div className="col-12 col-sm-5 col-lg-3 attendee-btn">
                                                    <div
                                                        className="attendee-export"
                                                        style={{
                                                            alignItems:
                                                                "center",
                                                            display: "flex",
                                                            backgroundColor:
                                                                "#2B6EC8",
                                                            color: "white",

                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            if (
                                                                exportAttendee
                                                            ) {
                                                                exportAttende();
                                                            } else {
                                                            }
                                                        }}
                                                    >
                                                        {!exportAttendee ? (
                                                            <CircularProgress
                                                                size={20}
                                                                color="inherit"
                                                            />
                                                        ) : (
                                                            <>
                                                                <img
                                                                    src={exp}
                                                                    style={{
                                                                        width: "0.9rem",
                                                                    }}
                                                                ></img>
                                                                <div className="separtor"></div>
                                                                <div
                                                                    style={{
                                                                        fontFamily:
                                                                            "lato-bold",
                                                                    }}
                                                                >
                                                                    EXPORT
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-5 col-lg-3 attendee-btn">
                                                    <div
                                                        className="attendee-export"
                                                        style={{
                                                            alignItems:
                                                                "center",
                                                            display: "flex",
                                                            backgroundColor:
                                                                "#2B6EC8",
                                                            color: "white",

                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            setEventModal(true)
                                                        }
                                                    >
                                                        <img
                                                            src={inc}
                                                            style={{
                                                                width: "0.9rem",
                                                            }}
                                                        ></img>
                                                        <div className="separtor"></div>
                                                        <div>ADD NEW USER</div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-5 col-lg-3 attendee-btn">
                                                    <label
                                                        className="login-btn"
                                                        style={{
                                                            background:
                                                                "#2B6EC8",
                                                            fontWeight:
                                                                "normal",
                                                            fontSize: "1rem",
                                                            height: "3rem",
                                                        }}
                                                        onClick={(e) => {
                                                            setUploadModal(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    position:
                                                                        "relative",
                                                                    top: "7px",
                                                                }}
                                                            >
                                                                Upload Attendee
                                                            </span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-striped gy-2 gs-7">
                                                    <thead>
                                                        <tr
                                                            className="fw-bolder fs-6  border-bottom border-gray-200"
                                                            style={{
                                                                backgroundColor:
                                                                    "#2B6EC8",
                                                                borderRadius:
                                                                    "16px",
                                                            }}
                                                        >
                                                            <th
                                                                className="tfirst"
                                                                style={{
                                                                    width: "20%",
                                                                    color: "white",
                                                                }}
                                                            >
                                                                Name
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: "10%",
                                                                    color: "white",
                                                                }}
                                                            >
                                                                Number of
                                                                tickets
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: "10%",
                                                                    color: "white",
                                                                }}
                                                            >
                                                                Ticket Name
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: "20%",
                                                                    color: "white",
                                                                }}
                                                            >
                                                                Total Amount
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: "20%",
                                                                    color: "white",
                                                                }}
                                                            >
                                                                Phone Number
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: "20%",
                                                                    color: "white",
                                                                }}
                                                            >
                                                                Email
                                                            </th>
                                                            <th
                                                                className="tlast"
                                                                style={{
                                                                    width: "8%",
                                                                    color: "white",
                                                                }}
                                                            >
                                                                Actions
                                                            </th>
                                                        </tr>

                                                        {attendee.map(
                                                            (code, index) => (
                                                                <React.Fragment
                                                                    key={index}
                                                                >
                                                                    <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                                                                        <th
                                                                            style={{
                                                                                color: "black",
                                                                            }}
                                                                        >
                                                                            {
                                                                                code.name
                                                                            }{" "}
                                                                            {
                                                                                code.lname
                                                                            }
                                                                        </th>
                                                                        <th
                                                                            style={{
                                                                                color: "black",
                                                                            }}
                                                                        >
                                                                            {
                                                                                code
                                                                                    ?.tickets
                                                                                    .length
                                                                            }
                                                                        </th>
                                                                        <th
                                                                            style={{
                                                                                color: "black",
                                                                            }}
                                                                        >
                                                                            {
                                                                                code
                                                                                    ?.tickets[0]
                                                                                    ?.tickete_type
                                                                                    ?.name
                                                                            }
                                                                        </th>
                                                                        <th
                                                                            style={{
                                                                                color: "black",
                                                                            }}
                                                                        >
                                                                            {/* {addingPrice(
                                                                                code?.tickets
                                                                            )} */}
                                                                            {
                                                                                code.amount
                                                                            }{" "}
                                                                            EGP
                                                                        </th>
                                                                        <th
                                                                            style={{
                                                                                color: "black",
                                                                            }}
                                                                        >
                                                                            {
                                                                                code.mobileno
                                                                            }
                                                                        </th>
                                                                        <th
                                                                            style={{
                                                                                color: "black",
                                                                            }}
                                                                        >
                                                                            {
                                                                                code.email
                                                                            }
                                                                        </th>
                                                                        <th>
                                                                            <span
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        qr
                                                                                    }
                                                                                    width={
                                                                                        15
                                                                                    }
                                                                                    height={
                                                                                        15
                                                                                    }
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        marginRight:
                                                                                            "0.7rem",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        dispatch(
                                                                                            PrintTag(
                                                                                                {
                                                                                                    id: code
                                                                                                        .tickets[0]
                                                                                                        .id,
                                                                                                }
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></img>
                                                                                <img
                                                                                    src={
                                                                                        rightArrow
                                                                                    }
                                                                                    width={
                                                                                        20
                                                                                    }
                                                                                    height={
                                                                                        20
                                                                                    }
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        marginRight:
                                                                                            "1rem",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setInfo(
                                                                                            code
                                                                                        );
                                                                                        setTicketId(
                                                                                            code
                                                                                                .tickets[0]
                                                                                                .id
                                                                                        );
                                                                                        setDetailsModal(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                ></img>
                                                                                <img
                                                                                    src={
                                                                                        del
                                                                                    }
                                                                                    width={
                                                                                        20
                                                                                    }
                                                                                    height={
                                                                                        20
                                                                                    }
                                                                                    onClick={() => {
                                                                                        setDelModal(
                                                                                            true
                                                                                        );
                                                                                        setUpdateFlag(
                                                                                            code.id
                                                                                        );
                                                                                    }}
                                                                                ></img>
                                                                            </span>
                                                                            <span
                                                                                onClick={() => {
                                                                                    setUpdateFlag(
                                                                                        code.id
                                                                                    );
                                                                                    setNewCompany(
                                                                                        {
                                                                                            name: code.name,
                                                                                            type: code.type,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            ></span>
                                                                        </th>
                                                                    </tr>
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                    </thead>
                                                </table>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AttendeeList;
