import { CircularProgress } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { Field, Form, Formik, FormikProvider } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { editTicket } from "redux/Events/thunks";
import { useLoadingSelector } from "redux/selectors";
import { useAppDispatch } from "redux/store";

const EditForm = ({ code, setIsInEditMode, setDetailsModal }: any) => {
    const dispatch = useAppDispatch();
    const isLoading = useLoadingSelector(editTicket);
    return (
        <Formik
            initialValues={{ email: code.email, mobileno: code.mobileno }}
            onSubmit={(values) => {
                dispatch(editTicket({ ...values, id: code.id }))
                    .then(unwrapResult)
                    .then((res) => {
                        if (!res) {
                            toast.error(
                                "Something Went Wrong, Please try again!"
                            );
                            return;
                        }
                        setIsInEditMode("");
                        setDetailsModal(false);
                    });
            }}
        >
            {(props) => (
                <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                    <th style={{ color: "black" }}>{code.name}</th>
                    <th style={{ color: "black" }}>
                        <Field
                            {...props}
                            name="mobileno"
                            className="user-inputs"
                            style={{ width: "100%" }}
                        />
                    </th>
                    <th style={{ color: "black" }}>
                        <Field
                            {...props}
                            name="email"
                            className="user-inputs"
                            style={{ width: "100%" }}
                        />
                    </th>
                    <th style={{ color: "black" }}>{code.qrcode.code}</th>
                    <th>
                        <div
                            className="attendee-export"
                            style={{
                                alignItems: "center",
                                display: "flex",
                                backgroundColor: "#2B6EC8",
                                color: "white",
                                width: "4rem",
                                cursor: "pointer",
                            }}
                            onClick={props.submitForm}
                        >
                            {isLoading ? (
                                <CircularProgress color="inherit" size={14} />
                            ) : (
                                <span>Save</span>
                            )}
                        </div>
                    </th>
                </tr>
            )}
        </Formik>
    );
};

export default EditForm;
