import React, { useEffect, useRef, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../../assets/images/addsponsor.svg";
import edit from "../../assets/images/edit.svg";
// import JoditEditor from "jodit-react";
import JoditEditor from "jodit-pro-react";
import del from "../../assets/images/delete.svg";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import Pagintation from "../../Components/Pagintation";

import {
    CreateBooths,
    DeleteBooths,
    EditBooths,
    getBooths,
} from "redux/Booths/actions";
import { useNavigate, useParams, useRoutes } from "react-router";
import {
    CreateSlideShow,
    EditSlideShow,
    getSlideShow,
} from "redux/SlideShow/actions";
const route = process.env.REACT_APP_IMAGE_URI;

const initalSponsor = {
    name: "",
    image: null,
    shortdesc: "",
    customorder: 1,
    event_id: null,
};

function SlideShow() {
    const defaultConfig = {
        license: "C464Q-1GIK1-BF6OK-K290Y",
        uploader: {
            url: "https://frontiers.o-projects.org/api/media/upload",
            format: "json",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            isSuccess: function (response) {
                // Assume success if there is a URL in the response
                return response && response.url;
            },
            getMsg: function (response) {
                // Return a success message or default message
                return response && response.url
                    ? "File uploaded successfully"
                    : "File upload failed";
            },
            process: function (response) {
                console.log(response.url); // Log the response for debugging
                return {
                    files: [
                        {
                            path: response.url,
                            type: "image",
                        },
                    ],
                    baseurl: "", // {string} Base url for filebrowser
                };
            },
            defaultHandlerSuccess: function (data) {
                var i,
                    field = "files";
                if (data[field] && data[field].length) {
                    for (i = 0; i < data[field].length; i += 1) {
                        this.selection.insertImage(data[field][i].path);
                    }
                }
            },
        },
    };
    const dispatch = useDispatch();
    const { slideShow, loader, total, number_of_pages } = useSelector(
        (state) => state.slides
    );

    const [searchText, setSearchText] = useState("");
    const { selectedCompany, selectedEvent } = useSelector(
        (state) => state.events
    );
    const params = useParams();
    const nav = useNavigate();
    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [error, setError] = useState(false);
    const editor = useRef(null);
    const [newSponsor, setNewSponsor] = useState(initalSponsor);

    useEffect(() => {
        if (params.id) {
            dispatch(
                getSlideShow({ event_id: params.id, pagenumber: pageNumber })
            );
        }
    }, [pageNumber, params.id]);

    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#00D7E1",
            },
            secondary: {
                // This is green.A700 as hex.
                main: "#222222",
            },
        },
    });
    console.log(newSponsor, "slideshow");
    const addind = () => {
        if (
            newSponsor.name == "" ||
            newSponsor.shortdesc == "" ||
            !newSponsor.customorder ||
            !newSponsor.image
        ) {
            setError(true);
            return;
        }
        if (updateFlag != -1) {
            let form = { ...newSponsor };
            form.id = parseInt(updateFlag);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(EditSlideShow({ body, selectedEvent: params.id }));
            setEventModal(false);
        } else {
            let form = { ...newSponsor };
            form.event_id = parseInt(params.id);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(CreateSlideShow({ body, selectedEvent: params.id }));
            setEventModal(false);
        }
        setNewSponsor(initalSponsor);
        setError(false);
        setUpdateFlag(-1);
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };
    return (
        <>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewSponsor(initalSponsor);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create New Slide
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Slide
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row">
                            <div
                                className="col-6 fv-row mb-5 fv-plugins-icon-container"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    Slide image
                                </label>

                                {newSponsor.image ? (
                                    <>
                                        <img
                                            style={{
                                                maxWidth: "35%",
                                                height: "7rem",
                                            }}
                                            src={
                                                newSponsor.image
                                                    ? newSponsor.image?.name
                                                        ? URL.createObjectURL(
                                                              newSponsor.image
                                                          )
                                                        : `${route}/${newSponsor.image}`
                                                    : ""
                                            }
                                        ></img>
                                        <div
                                            className="d-flex"
                                            style={{ margin: "1rem 0" }}
                                        >
                                            <label className="circles">
                                                <img
                                                    src={edit}
                                                    style={{ width: "1rem" }}
                                                ></img>
                                                <input
                                                    name="image"
                                                    value={""}
                                                    onChange={(e) =>
                                                        setNewSponsor({
                                                            ...newSponsor,
                                                            [e.target.name]:
                                                                e.target
                                                                    .files[0],
                                                        })
                                                    }
                                                    type="file"
                                                    style={{
                                                        display: "none",
                                                        cursor: "pointer",
                                                    }}
                                                    accept=".png, .jpg, .jpeg, .svg"
                                                />
                                            </label>
                                            <div className="separtor"></div>
                                            <div className="circles">
                                                <img
                                                    src={del}
                                                    style={{
                                                        width: "0.8rem",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        setNewSponsor({
                                                            ...newSponsor,
                                                            image: null,
                                                        });
                                                    }}
                                                ></img>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <label className="upload-btn">
                                        <input
                                            style={
                                                error && !newSponsor.image
                                                    ? {
                                                          border: "1px solid red",
                                                      }
                                                    : {}
                                            }
                                            type="file"
                                            style={{ display: "none" }}
                                            name="image"
                                            value={""}
                                            accept=".png, .jpg, .jpeg, .svg"
                                            onChange={(e) =>
                                                setNewSponsor((prevState) => ({
                                                    ...prevState,
                                                    [e.target.name]:
                                                        e.target.files[0],
                                                }))
                                            }
                                        />
                                        Upload image
                                    </label>
                                )}
                                <div style={{ marginTop: "0.5rem" }}>
                                    <span>*Image Dimension :2280*1920</span>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Slide name
                                </span>
                                <input
                                    style={
                                        error && !newSponsor.name
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="name"
                                    placeholder=""
                                    value={newSponsor?.name}
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Slide Description
                                </span>
                                <JoditEditor
                                    config={defaultConfig}
                                    ref={editor}
                                    style={
                                        error && !newSponsor.shortdesc
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="shortdesc"
                                    placeholder=""
                                    tex
                                    value={newSponsor?.shortdesc}
                                    onChange={(newContent) => {
                                        setNewSponsor((prev) => {
                                            return {
                                                ...prev,
                                                shortdesc: newContent,
                                            };
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  justify-content-center pb-lg-0 my-10">
                        <button
                            type="button"
                            id="kt_password_reset_submit"
                            className="save-btn fw-bolder"
                            onClick={addind}
                        >
                            <span>Save Changes</span>
                        </button>
                    </div>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"SlideShow"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"SlideShow"}
                        setSearchText={setSearchText}
                        EventName={params.id}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {total} results found
                                    </div>
                                    <Pagintation
                                        setPageNumber={setPageNumber}
                                        pageCount={number_of_pages}
                                    ></Pagintation>
                                </div>
                                <div
                                    className="row main-row"
                                    style={{ margin: "0" }}
                                >
                                    <div
                                        className="col-3 event-box-final"
                                        onClick={() => {
                                            setEventModal(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <img
                                            style={{
                                                width: "2.25rem",
                                                margin: "1rem",
                                            }}
                                            src={`${create}`}
                                        ></img>
                                        <div className="create-text">
                                            Add Slide
                                        </div>
                                    </div>
                                    {slideShow.map((event, index) => (
                                        <React.Fragment key={index}>
                                            <div
                                                className="sponsor-box"
                                                //style={{ display: "flex" }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setUpdateFlag(event.id);
                                                    setNewSponsor({
                                                        name: event.name,
                                                        shortdesc: event.desc,
                                                        image: event.image,
                                                        customorder:
                                                            event.customorder,
                                                    });
                                                    setEventModal(true);
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        margin: "auto",
                                                        padding: "2rem",
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        <img
                                                            style={{
                                                                maxWidth:
                                                                    "9rem",
                                                                maxHeight:
                                                                    "4rem",
                                                            }}
                                                            src={`https://frontiers.o-projects.org/storage/${event.image}`}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SlideShow;
