import React, { useEffect, useState } from "react";
import {
    createEvent,
    editEvent,
    getAllEvent,
    getEventsDetails,
} from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../../assets/images/ticket.svg";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Loader from "../../Components/Loader";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/material/Switch";
import { getCompanies } from "redux/Companies/actions";
import send from "../../assets/images/send.svg";

import Pagintation from "../../Components/Pagintation";
import dayjs from "dayjs";
import {
    CreateSponsors,
    DeleteSponsors,
    EditSponsors,
    getSponsors,
} from "redux/Sponsors/actions";
import {
    CreateTicketType,
    DeleteTicketType,
    EditTicketType,
    getTicketTypes,
} from "redux/TicketTypes/actions";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

const initalTicket = {
    name: "",
    name_ar: "",
    description: "",
    description_ar: "",
    type: 2,
    quantity: null,
    isprivate: 0,
    min: 1,
    max: 1,
    event_id: null,
    infocollect_type: 1,
    startduration: null,
    endduration: null,
    price: 0,
    needapproval: 0,
    pre_register: 0,
    customconfirmemail: "",
    customconfirmsubject: "",
    customemailsender: "",
    customreminderemail: "",
    customremindersubject: "",
    pending_mail: "",
    pending_subject: "",
    approval_mail: "",
    approval_subject: "",
    rejection_mail: "",
    rejection_subject: "",
    NewTicketAssignmentSubject: "",
    NewTicketAssignmentMail: "",
};

function TicketTypes() {
    const dispatch = useDispatch();
    const { ticketTypes, loader, total } = useSelector(
        (state) => state.ticketTypes
    );
    const { event } = useSelector((state) => state.events);
    const { selectedCompany, selectedEvent } = useSelector(
        (state) => state.events
    );
    const nav = useNavigate();

    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [error, setError] = useState(false);
    const params = useParams();

    const [newTicket, setNewTicket] = useState(initalTicket);
    useEffect(() => {
        if (params.id) {
            dispatch(
                getTicketTypes({ event_id: params.id, pagenumber: pageNumber })
            );
            dispatch(getEventsDetails(params.id));
        }
    }, [pageNumber, params]);
    const todayAtNoon = dayjs().set("hour", 12).startOf("hour");
    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#00D7E1",
            },
            secondary: {
                // This is green.A700 as hex.
                main: "#222222",
            },
        },
    });

    const addind = () => {
        // if(newSponsor.name==""||
        // newSponsor.about==""||
        //  newSponsor.address==" "||
        //  !newSponsor.category||newSponsor.facebook==''
        //  ||!newSponsor.image||
        //  !newSponsor.logo||newSponsor.instagram==''
        //  ||newSponsor.video==''||
        //  newSponsor.website==''||newSponsor.youtube=='')
        // {
        //     setError(true)
        //     return ;
        // }
        for (const key in newTicket) {
            if (
                key == "description_ar" &&
                newTicket[key] == "" &&
                event.supports_arabic == 1
            ) {
                setError(true);

                return;
            } else if (
                key == "name_ar" &&
                newTicket[key] == "" &&
                event.supports_arabic == 1
            ) {
                setError(true);

                return;
            } else if (
                (typeof newTicket[key] == "string" &&
                    newTicket[key] == "" &&
                    key != "ar_location" &&
                    key !== "name_ar" &&
                    key != "description_ar" &&
                    key != "customconfirmemail" &&
                    key != "customconfirmsubject" &&
                    key != "customemailsender" &&
                    key != "customreminderemail" &&
                    key != "customremindersubject" &&
                    key != "pending_mail" &&
                    key != "pending_subject" &&
                    key != "approval_mail" &&
                    key != "approval_subject" &&
                    key != "rejection_mail" &&
                    key != "NewTicketAssignmentSubject" &&
                    key != "NewTicketAssignmentMail" &&
                    key != "rejection_subject") ||
                (typeof newTicket[key] == "number" && isNaN(newTicket[key]))
            ) {
                console.log(key);
                setError(true);
                return;
            }
        }

        if (updateFlag != -1) {
            let form = { ...newTicket };
            form.id = parseInt(updateFlag);
            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(EditTicketType({ body, selectedEvent }));
        } else {
            let date = new Date(newTicket.startduration);
            let isCurrentYear = (date) =>
                date.getUTCFullYear() < new Date().getUTCFullYear();
            let date2 = new Date(newTicket.endduration);

            if (isCurrentYear(date) || isCurrentYear(date2)) {
                toast.error("Please pick a date from this year");
                return;
            }

            let form = { ...newTicket };
            form.event_id = parseInt(selectedEvent);
            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(CreateTicketType({ body, selectedEvent }));
        }
        setNewTicket(initalTicket);
        setError(false);
        setUpdateFlag(-1);
        setEventModal(false);
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };
    const handleToggle1 = (event) => {
        if (newTicket.type == 1) {
            setNewTicket({ ...newTicket, type: 2, price: 0 });
        } else {
            setNewTicket({ ...newTicket, type: 1 });
        }
    };
    const handleToggle2 = (event) => {
        if (newTicket.isprivate == 1) {
            setNewTicket({ ...newTicket, isprivate: 0 });
        } else {
            setNewTicket({ ...newTicket, isprivate: 1 });
        }
    };
    return (
        <>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewTicket(initalTicket);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create New TicketTypes
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Ticket Types
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row">
                            <div
                                className="col-12 fv-row mb-5 fv-plugins-icon-container toggle-div"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{ color: "black" }}
                                    className="d-flex  fs-7 mb-2"
                                >
                                    Is the ticket paid or free ?
                                </div>
                                <div>
                                    <ThemeProvider theme={theme}>
                                        <Switch
                                            checked={
                                                newTicket.type == 1
                                                    ? false
                                                    : true
                                            }
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                handleToggle1(e);
                                            }}
                                            //color={event.published ? "primary" : "secondary"}
                                            value="selectUploadBool"
                                        />
                                    </ThemeProvider>
                                    {newTicket.type == 1 ? "Paid" : "Free"}
                                </div>
                            </div>
                            <div
                                className="col-12 fv-row mb-5 fv-plugins-icon-container toggle-div"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{ color: "black" }}
                                    className="d-flex  fs-7 mb-2"
                                >
                                    Is the ticket require code ?
                                </div>
                                <div>
                                    <ThemeProvider theme={theme}>
                                        <Switch
                                            checked={
                                                newTicket.isprivate == 0
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                handleToggle2(e);
                                            }}
                                            //color={event.published ? "primary" : "secondary"}
                                            value="selectUploadBool"
                                        />
                                        {newTicket.isprivate == 0
                                            ? "No"
                                            : "Yes"}
                                    </ThemeProvider>
                                </div>
                            </div>
                            <div
                                className="col-12 fv-row mb-5 fv-plugins-icon-container toggle-div"
                                style={{
                                    display: "flex",
                                    padding: "0 2rem",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{ color: "black" }}
                                    className="d-flex  fs-7 mb-2"
                                >
                                    Tickets need admin permission ?
                                </div>
                                <div>
                                    <ThemeProvider theme={theme}>
                                        <Switch
                                            checked={
                                                newTicket.pre_register == 0
                                                    ? false
                                                    : true
                                            }
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    newTicket.pre_register == 0
                                                ) {
                                                    setNewTicket({
                                                        ...newTicket,
                                                        pre_register: 1,
                                                    });
                                                } else {
                                                    setNewTicket({
                                                        ...newTicket,
                                                        pre_register: 0,
                                                    });
                                                }
                                            }}
                                            //color={event.published ? "primary" : "secondary"}
                                            value="selectUploadBool"
                                        />
                                    </ThemeProvider>
                                    {newTicket.pre_register == 0
                                        ? "No Approval"
                                        : "Need Approval"}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Ticket name
                                </span>
                                <input
                                    style={
                                        error && !newTicket.name
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="name"
                                    placeholder=""
                                    value={newTicket?.name}
                                    onChange={(e) =>
                                        setNewTicket({
                                            ...newTicket,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            {event.supports_arabic == 1 && (
                                <div className="col">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Arabic ticket name
                                    </span>
                                    <input
                                        style={
                                            error && !newTicket.name
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="name_ar"
                                        placeholder=""
                                        value={newTicket?.name_ar}
                                        onChange={(e) =>
                                            setNewTicket({
                                                ...newTicket,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        {newTicket.type == 1 && (
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Price
                                    </span>
                                    <input
                                        style={
                                            error && !newTicket.price
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="price"
                                        placeholder=""
                                        value={newTicket?.price}
                                        onChange={(e) =>
                                            setNewTicket({
                                                ...newTicket,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Total quantity of tickets
                                </span>
                                <input
                                    style={
                                        error && !newTicket.quantity
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="quantity"
                                    placeholder=""
                                    value={newTicket?.quantity}
                                    onChange={(e) =>
                                        setNewTicket({
                                            ...newTicket,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-6">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Minimum number of tickets
                                </span>
                                <input
                                    style={
                                        error && !newTicket.min
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="min"
                                    placeholder=""
                                    value={newTicket?.min}
                                    onChange={(e) =>
                                        setNewTicket({
                                            ...newTicket,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-6">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Maximum number of tickets
                                </span>
                                <input
                                    style={
                                        error && !newTicket.max
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="max"
                                    placeholder=""
                                    value={newTicket.max}
                                    onChange={(e) =>
                                        setNewTicket({
                                            ...newTicket,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 fv-row mb-5 fv-plugins-icon-container">
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    <span>Start Date</span>
                                </label>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <TextField
                                        id="date"
                                        type="datetime-local"
                                        sx={
                                            error && !newTicket?.startduration
                                                ? {
                                                      border: "1px solid red",
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                                : {
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="startduration"
                                        value={newTicket?.startduration}
                                        onChange={(e) => {
                                            let date = new Date(e.target.value);
                                            let isCurrentYear = (date) =>
                                                date.getUTCFullYear() <
                                                new Date().getUTCFullYear();
                                            if (isCurrentYear(date)) {
                                                toast.error(
                                                    "Please pick a date from this year"
                                                );
                                            } else {
                                                setNewTicket({
                                                    ...newTicket,
                                                    [e.target.name]:
                                                        e.target.value,
                                                });
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-6 fv-row mb-5 fv-plugins-icon-container">
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    <span>End Date</span>
                                </label>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <TextField
                                        id="date"
                                        type="datetime-local"
                                        sx={
                                            error && !newTicket?.endduration
                                                ? {
                                                      border: "1px solid red",
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                                : {
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="endduration"
                                        value={newTicket?.endduration}
                                        onChange={(e) => {
                                            let date = new Date(e.target.value);
                                            let isCurrentYear = (date) =>
                                                date.getUTCFullYear() <
                                                new Date().getUTCFullYear();
                                            if (isCurrentYear(date)) {
                                                toast.error(
                                                    "Please pick a date from this year"
                                                );
                                            } else {
                                                setNewTicket({
                                                    ...newTicket,
                                                    [e.target.name]:
                                                        e.target.value,
                                                });
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Ticket Description
                                </span>
                                <input
                                    style={
                                        error && !newTicket.description
                                            ? {
                                                  border: "1px solid red",
                                                  height: "7rem",
                                              }
                                            : { height: "7rem" }
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="description"
                                    placeholder=""
                                    value={newTicket?.description}
                                    onChange={(e) =>
                                        setNewTicket({
                                            ...newTicket,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        {event.supports_arabic == 1 && (
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Arabic ticket Description
                                    </span>
                                    <input
                                        style={
                                            error && !newTicket.description_ar
                                                ? {
                                                      border: "1px solid red",
                                                      height: "7rem",
                                                  }
                                                : { height: "7rem" }
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="description_ar"
                                        placeholder=""
                                        value={newTicket?.description_ar}
                                        onChange={(e) =>
                                            setNewTicket({
                                                ...newTicket,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-center px-5 pb-lg-0 my-10">
                        <button
                            type="button"
                            id="kt_password_reset_submit"
                            className="save-btn fw-bolder"
                            onClick={addind}
                        >
                            <span>Save Changes</span>
                        </button>

                        {updateFlag != -1 && (
                            <>
                                <div className="separtor"></div>
                                <button
                                    className="delete-btn"
                                    onClick={() => {
                                        dispatch(
                                            DeleteTicketType({
                                                updateFlag,
                                                selectedEvent,
                                            })
                                        );
                                        setUpdateFlag(-1);
                                        setEventModal(false);
                                        setNewTicket(initalTicket);
                                    }}
                                >
                                    Delete
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"TicketTypes"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Ticket Types"}
                        showUrl={true}
                        EventName={params.id}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {total} results found
                                    </div>
                                    <Pagintation
                                        setPageNumber={setPageNumber}
                                    ></Pagintation>
                                </div>
                                <div
                                    className="row main-row"
                                    style={{ margin: "0" }}
                                >
                                    <div
                                        className="col-3 event-box-final"
                                        onClick={() => {
                                            setEventModal(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <img
                                            style={{
                                                width: "2.25rem",
                                                margin: "1rem",
                                            }}
                                            src={`${create}`}
                                        ></img>
                                        <div className="create-text">
                                            Add Ticket Types
                                        </div>
                                    </div>
                                    {ticketTypes.map((event, index) => (
                                        <>
                                            <div
                                                className="event-box-hidden"
                                                style={{ display: "flex" }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setUpdateFlag(event.id);
                                                    setNewTicket({
                                                        name: event.name,
                                                        name_ar: event.name_ar,
                                                        description:
                                                            event.description,
                                                        description_ar:
                                                            event.description_ar,
                                                        type: event.type,
                                                        quantity:
                                                            event.quantity,
                                                        isprivate:
                                                            event.isprivate,
                                                        min: event.min,
                                                        max: event.max,
                                                        infocollect_type: 1,
                                                        startduration:
                                                            event.startduration,
                                                        endduration:
                                                            event.endduration,
                                                        price: event.price,
                                                        needapproval: 0,
                                                        pre_register:
                                                            event.pre_register,
                                                        customconfirmemail:
                                                            event.customconfirmemail,
                                                        customconfirmsubject:
                                                            event.customconfirmsubject,
                                                        customemailsender:
                                                            event.customemailsender,
                                                        customreminderemail:
                                                            event.customreminderemail,
                                                        customremindersubject:
                                                            event.customremindersubject,
                                                        pending_mail:
                                                            event.pending_mail,
                                                        pending_subject:
                                                            event.pending_subject,
                                                        approval_mail:
                                                            event.approval_mail,
                                                        approval_subject:
                                                            event.approval_subject,
                                                        rejection_mail:
                                                            event.rejection_mail,
                                                        rejection_subject:
                                                            event.rejection_subject,
                                                        NewTicketAssignmentSubject:
                                                            event.NewTicketAssignmentSubject,
                                                        NewTicketAssignmentMail:
                                                            event.NewTicketAssignmentMail,
                                                    });
                                                    setEventModal(true);
                                                }}
                                            >
                                                <div style={{ width: "100%" }}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <div className="ticket-status">
                                                            {event.type == 1
                                                                ? `${event.price} EGP`
                                                                : "Free Ticket"}
                                                        </div>
                                                        <div
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                nav(
                                                                    `emails/${event.id}`
                                                                );
                                                            }}
                                                        >
                                                            {" "}
                                                            <img
                                                                style={{
                                                                    width: "1.5rem",
                                                                }}
                                                                src={`${send}`}
                                                            ></img>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="ticket-title">
                                                            {event.name}
                                                        </div>
                                                    </div>
                                                    <div className="ticket-about">
                                                        {event.description}
                                                    </div>
                                                    <div className="ticket-date">
                                                        {new Date(
                                                            event?.startduration
                                                        ).toLocaleString(
                                                            "en-GB",
                                                            {
                                                                day: "numeric",
                                                                month: "long",
                                                                year: "numeric",
                                                            }
                                                        )}
                                                        -
                                                        {new Date(
                                                            event?.endduration
                                                        ).toLocaleString(
                                                            "en-GB",
                                                            {
                                                                day: "numeric",
                                                                month: "long",
                                                                year: "numeric",
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default TicketTypes;
