import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    createEvent,
    DeleteEvent,
    editEvent,
    getAllEvent,
    getSelectedEvent,
    archiveEvents,
    SelectEvent,
} from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../../assets/images/createfolder.svg";
import TextField from "@mui/material/TextField";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";

import archive from "../../assets/images/archive.svg";
import Stack from "@mui/material/Stack";
import Loader from "../../Components/Loader";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/material/Switch";
import { getCompanies } from "redux/Companies/actions";
import Pagintation from "../../Components/Pagintation";
// import JoditEditor from "jodit-react";
import JoditEditor from "jodit-pro-react";
import { useNavigate } from "react-router";
import { template } from "lodash";
import { setIntial } from "redux/Agenda/actions";
const initalEvent = {
    name: "",
    ar_name: "",
    description: "",
    require_questions: 0,
    ar_description: "",
    supports_arabic: 1,
    location: "",
    ar_location: "",
    company_id: "",
    location: "",
    gpslocation: "",
    startdate: "",
    enddate: "",
    description: "",
    logo: "",
    banner: "",
    from: "",
    to: "",
    facebook_link: "",
    whatsup_link: "",
    template: 1,
    fbpixel: 123456,
    googleanalytics: 12345,
    customemailsender: "customemail",
    customconfirmemail: "customconfirmemail",
    custompreregistration: "<html></html>",
    pre_register: 0,
    customsmssenderid: "LaCasaExpo",
    smstemplate: "Thank you for purchasing your ticket",
    smsusername: "hdwxNtai",
    smspassword: "BfgELGANQZ",
    customreminderemail: "<html></html>",
    customremindersubject: "Reminder for your tickets",
    customconfirmsubject: "Tickets Confirmed",
};

function Home() {
    const defaultConfig = {
        license: "C464Q-1GIK1-BF6OK-K290Y",
        uploader: {
            url: "https://frontiers.o-projects.org/api/media/upload",
            format: "json",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            isSuccess: function (response) {
                // Assume success if there is a URL in the response
                return response && response.url;
            },
            getMsg: function (response) {
                // Return a success message or default message
                return response && response.url
                    ? "File uploaded successfully"
                    : "File upload failed";
            },
            process: function (response) {
                console.log(response.url); // Log the response for debugging

                // Determine the file type based on the response or other logic
                const fileType = response.url.match(/\.(jpg|jpeg|png|gif)$/i)
                    ? "image"
                    : "file";

                return {
                    files: [
                        {
                            path: response.url,
                            type: fileType,
                        },
                    ],
                    baseurl: "", // Base url for filebrowser
                };
            },
            defaultHandlerSuccess: function (data) {
                const field = "files";
                if (data[field] && data[field].length) {
                    for (let i = 0; i < data[field].length; i++) {
                        const file = data[field][i];

                        // Insert image if it's an image
                        if (file.type === "image") {
                            this.selection.insertImage(file.path);
                        }
                        // Insert link if it's a file (non-image)
                        else if (file.type === "file") {
                            const fileName = file.path.split("/").pop(); // Get file name from path
                            this.selection.insertHTML(
                                `<a href="${file.path}" target="_blank">${fileName}</a>`
                            );
                        }
                    }
                }
            },
        },
    };
    const route = process.env.REACT_APP_IMAGE_URI;
    const editor = useRef(null);
    const dispatch = useDispatch();
    const { events, total, loader, number_of_pages } = useSelector(
        (state) => state.events
    );
    const nav = useNavigate();
    const { companies } = useSelector((state) => state.companies);
    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [archivedEvent, setArchivedEvent] = useState({ id: null, name: "" });
    const [newEvent, setNewEvent] = useState(initalEvent);
    const [oldName, setOldName] = useState("");
    const [error, setError] = useState(false);
    const [desc, setDesc] = useState("");
    useEffect(() => {
        dispatch(
            getAllEvent({
                pagenumber: pageNumber,
                name: searchText,
                archive: 0,
            })
        );
        dispatch(getCompanies({ name: "" }));
        dispatch(setIntial());
    }, [searchText, pageNumber]);

    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#00D7E1",
            },
            secondary: {
                // This is green.A700 as hex.
                main: "#222222",
            },
        },
    });
    const handleToggle = (event) => {
        console.log(event);
        let body = {};
        if (event.published == 0) {
            body = {
                name: event.name,
                require_questions: event.require_questions,
                ar_name: event.ar_name,
                company_id: event.company_id,
                location: event.location,
                ar_location: event.ar_location,
                gpslocation: event.gpslocation,
                logo: event.logo,
                description: event.description,
                ar_description: event.ar_description,
                banner: event.banner,
                published: 1,
                supports_arabic: event.supports_arabic,
                facebook_link: event.facebook_link,
                whatsup_link: event.whatsup_link,
                template: event.template,
                id: event.id,
                fbpixel: 123456,
                googleanalytics: 12345,
                customemailsender: "customemail",
                customconfirmemail: event.customconfirmemail,
                custompreregistration: "<html></html>",
                customsmssenderid: "LaCasaExpo",
                smstemplate: "Thank you for purchasing your ticket",
                smsusername: "hdwxNtai",
                smspassword: "BfgELGANQZ",
                // pre_register: event.pre_register,
                customreminderemail: event.customreminderemail,
                customremindersubject: event.customremindersubject,
                customconfirmsubject: event.customconfirmsubject,
            };
        } else {
            body = {
                name: event.name,
                ar_name: event.ar_name,
                require_questions: event.require_questions,
                company_id: event.company_id,
                location: event.location,
                ar_location: event.ar_location,
                gpslocation: event.gpslocation,
                supports_arabic: event.supports_arabic,
                facebook_link: event.facebook_link,
                whatsup_link: event.whatsup_link,
                template: event.template,
                logo: event.logo,
                description: event.description,
                ar_description: event.ar_description,
                banner: event.banner,
                // pre_register: event.pre_register,
                pre_register: "",
                published: 0,
                id: event.id,
                fbpixel: 123456,
                googleanalytics: 12345,
                customemailsender: "customemail",
                customconfirmemail: event.customconfirmemail,
                custompreregistration: "<html></html>",
                customsmssenderid: "LaCasaExpo",
                smstemplate: "Thank you for purchasing your ticket",
                smsusername: "hdwxNtai",
                smspassword: "BfgELGANQZ",
                customreminderemail: event.customreminderemail,
                customremindersubject: event.customremindersubject,
                customconfirmsubject: event.customconfirmsubject,
            };
        }
        let arr = {
            startdate: event.startdate.split(" ")[0],
            from: event.startdate.split(" ")[1],
            enddate: event.enddate.split(" ")[0],
            to: event.enddate.split(" ")[1],
        };
        body.startdate = `${arr.startdate} ${arr.from}`;
        body.enddate = `${arr.enddate} ${arr.to}`;
        if (body.name == oldName) {
            //delete body.name;
        }
        const form = new FormData();
        for (var key in body) {
            form.append(key, body[key]);
        }
        // for (const value of form.values()) {
        //   console.log(value,'body');
        // }
        dispatch(editEvent({ form, pagenumber: pageNumber, archive: 0 }));
    };

    const addind = () => {
        for (const key in newEvent) {
            if (
                key == "ar_description" &&
                newEvent[key] == "" &&
                newEvent.supports_arabic == 1
            ) {
                setError(true);

                return;
            } else if (
                key == "ar_name" &&
                newEvent[key] == "" &&
                newEvent.supports_arabic == 1
            ) {
                setError(true);

                return;
            } else if (
                key == "ar_location" &&
                newEvent[key] == "" &&
                newEvent.supports_arabic == 1
            ) {
                setError(true);

                return;
            } else if (
                (typeof newEvent[key] == "string" &&
                    newEvent[key] == "" &&
                    key != "ar_location" &&
                    key !== "ar_name" &&
                    key != "ar_description" &&
                    key != "customreminderemail") ||
                (typeof newEvent[key] == "number" && isNaN(newEvent[key]))
            ) {
                setError(true);
                return;
            }
        }
        if (updateFlag != -1) {
            let body = {
                name: newEvent.name,
                ar_name: newEvent.ar_name,
                company_id: newEvent.company_id,
                require_questions: newEvent.require_questions,
                location: newEvent.location,
                ar_location: newEvent.ar_location,
                gpslocation: newEvent.gpslocation,
                logo: newEvent.logo,
                pre_register: "",
                description: newEvent.description,
                ar_description: newEvent.ar_description,
                banner: newEvent.banner,
                published: newEvent.published,
                id: updateFlag,
                supports_arabic: newEvent.supports_arabic,
                fbpixel: 123456,
                facebook_link: newEvent.facebook_link,
                whatsup_link: newEvent.whatsup_link,
                template: newEvent.template,
                googleanalytics: 12345,
                customemailsender: "customemail",
                customconfirmemail: newEvent.customconfirmemail,
                custompreregistration: "<html></html>",
                customsmssenderid: "LaCasaExpo",
                smstemplate: "Thank you for purchasing your ticket",
                smsusername: "hdwxNtai",
                smspassword: "BfgELGANQZ",
                customreminderemail: newEvent.customreminderemail,
                customremindersubject: newEvent.customremindersubject,
                customconfirmsubject: newEvent.customconfirmsubject,
                approval_mail: newEvent.approval_mail,
                approval_subject: newEvent.approval_subject,
                rejection_mail: newEvent.rejection_mail,
                rejection_subject: newEvent.rejection_subject,
            };

            body.startdate = `${newEvent.startdate} ${newEvent.from}`;
            body.enddate = `${newEvent.enddate} ${newEvent.to}`;
            if (body.name == oldName) {
                //delete body.name;
            }
            const form = new FormData();
            for (var key in body) {
                form.append(key, body[key]);
            }
            dispatch(editEvent({ form, pagenumber: pageNumber, archive: 0 }));
            setEventModal(false);
            setError(false);
        } else {
            let start = `${newEvent.startdate} ${newEvent.from}`;
            let end = `${newEvent.enddate} ${newEvent.to}`;
            const form = new FormData();
            form.append("name", newEvent.name);
            form.append("ar_name", newEvent.ar_name);
            form.append("company_id", newEvent.company_id);
            form.append("location", newEvent.location);
            form.append("ar_location", newEvent.ar_location);
            form.append("gpslocation", newEvent.gpslocation);
            form.append("supports_arabic", newEvent.supports_arabic);
            form.append("facebook_link", newEvent.facebook_link);
            form.append("whatsup_link", newEvent.whatsup_link);
            form.append("template", newEvent.template);
            form.append("startdate", start);
            form.append("enddate", end);
            form.append("require_questions", newEvent.require_questions);
            form.append("pre_register", "");
            form.append("description", newEvent.description);
            form.append("ar_description", newEvent.ar_description);
            form.append("logo", newEvent.logo);
            form.append("banner", newEvent.banner);
            form.append("approval_mail", newEvent.approval_mail);
            form.append("approval_subject", newEvent.approval_subject);
            form.append("rejection_mail", newEvent.rejection_mail);
            form.append("rejection_subject", newEvent.rejection_subject);
            // for (const value of form.values()) {
            //   console.log(value,'body');
            // }
            dispatch(createEvent({ form, pagenumber: pageNumber }));
            setEventModal(false);
            setError(false);
        }
        setUpdateFlag(-1);
        setNewEvent(initalEvent);
        setOldName("");
    };

    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };
    const archiveEvent = () => {
        dispatch(
            archiveEvents({
                pagenumber: pageNumber,
                archivedEvent: archivedEvent.id,
            })
        );
        setArchivedEvent({ ...archivedEvent, id: null, name: "" });
    };

    const changeReachTextValue = useMemo(
        () => (
            <JoditEditor
                config={defaultConfig}
                ref={editor}
                type="text"
                className="form-control form-control-lg form-control-solid"
                name="description"
                placeholder=""
                value={newEvent?.description}
                onChange={(newContent) => {
                    setNewEvent((prev) => {
                        return {
                            ...prev,
                            description: newContent,
                        };
                    });
                }}
            />
        ),
        [editor, desc]
    );

    const changeReachTextValu_AR = useMemo(
        () => (
            <JoditEditor
                config={defaultConfig}
                ref={editor}
                type="text"
                className="form-control form-control-lg form-control-solid"
                name="description"
                placeholder=""
                value={newEvent?.ar_description}
                onChange={(newContent) => {
                    setNewEvent((prev) => {
                        return {
                            ...prev,
                            ar_description: newContent,
                        };
                    });
                }}
            />
        ),
        [editor, desc]
    );

    return (
        <>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewEvent(initalEvent);
                    setUpdateFlag(-1);
                    setOldName("");

                    setError(false);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create New Event
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <div className="d-block flex-center align-items-center  py-5 px-5">
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 ">
                                Edit {newEvent.name}
                            </h1>
                            <a
                                style={{
                                    color: "#00D7E1",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                target="_blank"
                                href={`${newEvent.shorten_url}`}
                            >
                                {newEvent.shorten_url}
                            </a>
                        </div>
                    )}
                    <div
                        className="flex-center"
                        style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                    >
                        <div
                            className="col-12 fv-row mb-5 fv-plugins-icon-container toggle-div"
                            style={{
                                display: "flex",
                                padding: "0 2rem",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{ color: "black" }}
                                className="d-flex  fs-7 mb-2"
                            >
                                Is it required for the guests to answer
                                question?
                            </div>
                            <div>
                                <ThemeProvider theme={theme}>
                                    <Switch
                                        checked={
                                            newEvent.require_questions == 0
                                                ? false
                                                : true
                                        }
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            if (
                                                newEvent.require_questions == 0
                                            ) {
                                                setNewEvent({
                                                    ...newEvent,
                                                    require_questions: 1,
                                                });
                                            } else {
                                                setNewEvent({
                                                    ...newEvent,
                                                    require_questions: 0,
                                                });
                                            }
                                        }}
                                        //color={event.published ? "primary" : "secondary"}
                                        value="selectUploadBool"
                                    />
                                </ThemeProvider>
                                {newEvent.require_questions == 0
                                    ? "Not Required "
                                    : " Required"}
                            </div>
                        </div>
                        <div
                            className="col-12 fv-row mb-5 fv-plugins-icon-container toggle-div"
                            style={{
                                display: "flex",
                                padding: "0 2rem",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{ color: "black" }}
                                className="d-flex  fs-7 mb-2"
                            >
                                Is the Event support arabic ?
                            </div>
                            <div>
                                <ThemeProvider theme={theme}>
                                    <Switch
                                        checked={
                                            newEvent.supports_arabic == 0
                                                ? false
                                                : true
                                        }
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            if (newEvent.supports_arabic == 0) {
                                                setNewEvent({
                                                    ...newEvent,
                                                    supports_arabic: 1,
                                                });
                                            } else {
                                                setNewEvent({
                                                    ...newEvent,
                                                    supports_arabic: 0,
                                                });
                                            }
                                        }}
                                        //color={event.published ? "primary" : "secondary"}
                                        value="selectUploadBool"
                                    />
                                </ThemeProvider>
                                {newEvent.supports_arabic == 1
                                    ? "Supported"
                                    : "Not Supported"}
                            </div>
                        </div>
                        <div
                            className="col-12 fv-row mb-5 fv-plugins-icon-container toggle-div"
                            style={{
                                display: "flex",
                                padding: "0 2rem",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{ color: "black" }}
                                className="d-flex  fs-7 mb-2"
                            >
                                Tickets need admin permission ?
                            </div>
                            <div>
                                <ThemeProvider theme={theme}>
                                    <Switch
                                        checked={
                                            newEvent.pre_register == 0
                                                ? false
                                                : true
                                        }
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            if (newEvent.pre_register == 0) {
                                                setNewEvent({
                                                    ...newEvent,
                                                    pre_register: 1,
                                                });
                                            } else {
                                                setNewEvent({
                                                    ...newEvent,
                                                    pre_register: 0,
                                                });
                                            }
                                        }}
                                        //color={event.published ? "primary" : "secondary"}
                                        value="selectUploadBool"
                                    />
                                </ThemeProvider>
                                {newEvent.pre_register == 0
                                    ? "No Approval"
                                    : "Need Approval"}
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Name in English
                                </span>
                                <input
                                    style={
                                        error && !newEvent?.name
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="name"
                                    placeholder=""
                                    value={newEvent?.name}
                                    onChange={(e) =>
                                        setNewEvent({
                                            ...newEvent,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            {newEvent.supports_arabic == 1 && (
                                <div className="col-6">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Name in Arabic
                                    </span>
                                    <input
                                        style={
                                            error && !newEvent?.ar_name
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        // style={
                                        //   error && !newanswer.name ? { border: "1px solid red" } : {}
                                        // }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="ar_name"
                                        placeholder=""
                                        value={newEvent?.ar_name}
                                        onChange={(e) =>
                                            setNewEvent({
                                                ...newEvent,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div className="row mb-5">
                            <div className="col">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Location in English
                                </span>
                                <input
                                    // style={
                                    //   error && !newanswer.name ? { border: "1px solid red" } : {}
                                    // }
                                    style={
                                        error && !newEvent?.location
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="location"
                                    placeholder=""
                                    value={newEvent?.location}
                                    onChange={(e) =>
                                        setNewEvent({
                                            ...newEvent,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            {newEvent.supports_arabic == 1 && (
                                <div className="col-6">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Location in Arabic
                                    </span>
                                    <input
                                        // style={
                                        //   error && !newanswer.name ? { border: "1px solid red" } : {}
                                        // }
                                        style={
                                            error && !newEvent?.ar_location
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="ar_location"
                                        placeholder=""
                                        value={newEvent?.ar_location}
                                        onChange={(e) =>
                                            setNewEvent({
                                                ...newEvent,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div className="row px-2 mb-5">
                            <span
                                className="d-flex align-items-center "
                                style={{ color: "black" }}
                            >
                                Google Maps URL
                            </span>
                            <input
                                // style={
                                //   error && !newanswer.name ? { border: "1px solid red" } : {}
                                // }
                                style={
                                    error && !newEvent?.gpslocation
                                        ? { border: "1px solid red" }
                                        : {}
                                }
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                name="gpslocation"
                                placeholder=""
                                value={newEvent?.gpslocation}
                                onChange={(e) =>
                                    setNewEvent({
                                        ...newEvent,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="row px-2 mb-5">
                            <div className="col-6">
                                <span
                                    className="d-flex align-items-center "
                                    style={{ color: "black" }}
                                >
                                    Facebook Link
                                </span>
                                <input
                                    // style={
                                    //   error && !newanswer.name ? { border: "1px solid red" } : {}
                                    // }
                                    style={
                                        error && !newEvent?.facebook_link
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="facebook_link"
                                    placeholder=""
                                    value={newEvent?.facebook_link}
                                    onChange={(e) =>
                                        setNewEvent({
                                            ...newEvent,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-6">
                                <span
                                    className="d-flex align-items-center "
                                    style={{ color: "black" }}
                                >
                                    WhatsApp Link
                                </span>
                                <input
                                    // style={
                                    //   error && !newanswer.name ? { border: "1px solid red" } : {}
                                    // }
                                    style={
                                        error && !newEvent?.whatsup_link
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="whatsup_link"
                                    placeholder=""
                                    value={newEvent?.whatsup_link}
                                    onChange={(e) =>
                                        setNewEvent({
                                            ...newEvent,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row mb-5 ">
                            <div className="col-12 fv-row mb-5 fv-plugins-icon-container">
                                <span
                                    className="d-flex align-items-center "
                                    style={{ color: "black" }}
                                >
                                    Company
                                </span>
                                <select
                                    // style={
                                    //   error && !newanswer.name ? { border: "1px solid red" } : {}
                                    // }
                                    style={
                                        error && !newEvent?.company_id
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="company_id"
                                    placeholder=""
                                    value={newEvent?.company_id}
                                    onChange={(e) =>
                                        setNewEvent({
                                            ...newEvent,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                >
                                    <option></option>
                                    {companies.map((company, index) => (
                                        <option value={company.id}>
                                            {company.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 fv-row mb-5 fv-plugins-icon-container">
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    <span>Start Date</span>
                                </label>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <TextField
                                        id="date"
                                        type="date"
                                        defaultValue="2017-05-24"
                                        sx={
                                            error && !newEvent?.startdate
                                                ? {
                                                      border: "1px solid red",
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                                : {
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                            disableUnderline: true,
                                        }}
                                        name="startdate"
                                        value={newEvent?.startdate}
                                        onChange={(e) =>
                                            setNewEvent({
                                                ...newEvent,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-6 fv-row mb-5 fv-plugins-icon-container">
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    <span>From</span>
                                </label>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <TextField
                                        id="time"
                                        type="time"
                                        defaultValue="07:30"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        sx={
                                            error && !newEvent?.from
                                                ? {
                                                      border: "1px solid red",
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                                : {
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                        }
                                        name="from"
                                        value={newEvent?.from}
                                        onChange={(e) =>
                                            setNewEvent({
                                                ...newEvent,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 fv-row mb-5 fv-plugins-icon-container">
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    <span>End Date</span>
                                </label>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <TextField
                                        id="date"
                                        type="date"
                                        defaultValue="2017-05-24"
                                        sx={
                                            error && !newEvent?.enddate
                                                ? {
                                                      border: "1px solid red",
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                                : {
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="enddate"
                                        value={newEvent?.enddate}
                                        onChange={(e) =>
                                            setNewEvent({
                                                ...newEvent,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-6 fv-row mb-5 fv-plugins-icon-container">
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    <span>to</span>
                                </label>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <TextField
                                        id="time"
                                        type="time"
                                        defaultValue="07:30"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        sx={
                                            error && !newEvent?.to
                                                ? {
                                                      border: "1px solid red",
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                                : {
                                                      width: "100%",
                                                      backgroundColor:
                                                          "#eaf4fa",
                                                      borderRadius: "16px",
                                                  }
                                        }
                                        name="to"
                                        value={newEvent?.to}
                                        onChange={(e) =>
                                            setNewEvent({
                                                ...newEvent,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="mb-5 ">
                                <div className="col-12 fv-row mb-5 fv-plugins-icon-container">
                                    <span
                                        className="d-flex align-items-center "
                                        style={{ color: "black" }}
                                    >
                                        Description in English
                                    </span>
                                    {changeReachTextValue}
                                </div>
                                {error && !newEvent.description && (
                                    <span style={{ color: "red" }}>
                                        Event description is required
                                    </span>
                                )}
                            </div>
                            {newEvent.supports_arabic == 1 && (
                                <div className="mb-5 ">
                                    <div className="col-12 fv-row mb-5 fv-plugins-icon-container">
                                        <span
                                            className="d-flex align-items-center "
                                            style={{ color: "black" }}
                                        >
                                            Description in Arabic
                                        </span>
                                        {changeReachTextValu_AR}
                                    </div>
                                    {error &&
                                        !newEvent.ar_description &&
                                        newEvent.supports_arabic == 1 && (
                                            <span style={{ color: "red" }}>
                                                Event description is required
                                            </span>
                                        )}
                                </div>
                            )}

                            <div className="row">
                                {newEvent.logo ? (
                                    <div
                                        className="col-6 fv-row mb-5 fv-plugins-icon-container"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            //alignItems: "center",
                                        }}
                                    >
                                        <label
                                            style={{ color: "black" }}
                                            className="d-flex align-items-center fs-7 mb-2"
                                        >
                                            Logo Image
                                        </label>
                                        <label>
                                            <img
                                                style={{
                                                    maxWidth: "35%",
                                                    height: "7rem",
                                                }}
                                                src={
                                                    newEvent.logo
                                                        ? newEvent.logo?.name
                                                            ? URL.createObjectURL(
                                                                  newEvent.logo
                                                              )
                                                            : `https://frontiers.o-projects.org/storage/${newEvent.logo}`
                                                        : ""
                                                }
                                            ></img>
                                        </label>
                                        <div
                                            className="d-flex"
                                            style={{ margin: "1rem 0" }}
                                        >
                                            <label className="circles">
                                                <img
                                                    src={edit}
                                                    style={{
                                                        width: "1rem",
                                                        cursor: "pointer",
                                                    }}
                                                ></img>
                                                <input
                                                    type="file"
                                                    //name="myImage"
                                                    style={{ display: "none" }}
                                                    name="logo"
                                                    value={""}
                                                    onChange={(e) =>
                                                        setNewEvent({
                                                            ...newEvent,
                                                            [e.target.name]:
                                                                e.target
                                                                    .files[0],
                                                        })
                                                    }
                                                    accept=".png, .jpg, .jpeg, .svg"
                                                />
                                            </label>
                                            <div className="separtor"></div>
                                            <div className="circles">
                                                <img
                                                    src={del}
                                                    style={{ width: "0.8rem" }}
                                                    onClick={() => {
                                                        setNewEvent({
                                                            ...newEvent,
                                                            logo: null,
                                                        });
                                                    }}
                                                ></img>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="col-6 fv-row mb-5 fv-plugins-icon-container"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            //alignItems: "center",
                                        }}
                                    >
                                        <label
                                            style={{ color: "black" }}
                                            className="d-flex align-items-center fs-7 mb-2"
                                        >
                                            Logo Image
                                        </label>
                                        <label
                                            className="upload-btn"
                                            // style={
                                            //   error && !newEvent.logo && { border: "1px solid red" }
                                            // }
                                        >
                                            <input
                                                type="file"
                                                //name="myImage"
                                                style={{ display: "none" }}
                                                name="logo"
                                                value={""}
                                                onChange={(e) =>
                                                    setNewEvent({
                                                        ...newEvent,
                                                        [e.target.name]:
                                                            e.target.files[0],
                                                    })
                                                }
                                                accept=".png, .jpg, .jpeg, .svg"
                                            />
                                            Upload image
                                        </label>
                                        <div style={{ marginTop: "0.5rem" }}>
                                            <span>
                                                Logo Dimension:128x100 px
                                            </span>
                                        </div>
                                        <div>
                                            {!newEvent.logo && error && (
                                                <span style={{ color: "red" }}>
                                                    Logo is required
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div
                                    className="col-6 fv-row mb-5 fv-plugins-icon-container"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        //alignItems: "center",
                                    }}
                                >
                                    <label
                                        style={{ color: "black" }}
                                        className="d-flex align-items-center fs-7 mb-2"
                                    >
                                        Cover Image
                                    </label>
                                    {!newEvent.banner ? (
                                        <label
                                            className="upload-btn"
                                            // style={
                                            //   error && !newEvent.banner && { border: "1px solid red" }
                                            // }
                                        >
                                            <input
                                                name="banner"
                                                value={""}
                                                onChange={(e) =>
                                                    setNewEvent({
                                                        ...newEvent,
                                                        [e.target.name]:
                                                            e.target.files[0],
                                                    })
                                                }
                                                type="file"
                                                style={{ display: "none" }}
                                                accept=".png, .jpg, .jpeg, .svg"
                                            />
                                            Upload image
                                        </label>
                                    ) : (
                                        <>
                                            <label>
                                                <img
                                                    style={{
                                                        maxWidth: "35%",
                                                        height: "7rem",
                                                    }}
                                                    src={
                                                        newEvent.banner
                                                            ? newEvent.banner
                                                                  ?.name
                                                                ? URL.createObjectURL(
                                                                      newEvent.banner
                                                                  )
                                                                : `https://frontiers.o-projects.org/storage/${newEvent.banner}`
                                                            : ""
                                                    }
                                                ></img>
                                            </label>
                                            <div
                                                className="d-flex"
                                                style={{ margin: "1rem 0" }}
                                            >
                                                <label className="circles">
                                                    <img
                                                        src={edit}
                                                        style={{
                                                            width: "1rem",
                                                        }}
                                                    ></img>
                                                    <input
                                                        name="banner"
                                                        value={""}
                                                        onChange={(e) =>
                                                            setNewEvent({
                                                                ...newEvent,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .files[0],
                                                            })
                                                        }
                                                        type="file"
                                                        style={{
                                                            display: "none",
                                                            cursor: "pointer",
                                                        }}
                                                        accept=".png, .jpg, .jpeg, .svg"
                                                    />
                                                </label>
                                                <div className="separtor"></div>
                                                <div className="circles">
                                                    <img
                                                        src={del}
                                                        style={{
                                                            width: "0.8rem",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            setNewEvent({
                                                                ...newEvent,
                                                                banner: null,
                                                            });
                                                        }}
                                                    ></img>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div style={{ marginTop: "0.5rem" }}>
                                        <span>
                                            Banner Dimension:1349x243 px
                                        </span>
                                    </div>
                                    <div>
                                        {!newEvent.banner && error && (
                                            <span style={{ color: "red" }}>
                                                Banner is required
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                        <button
                            type="button"
                            id="kt_password_reset_submit"
                            className="save-btn fw-bolder"
                            onClick={addind}
                        >
                            <span>Save Changes</span>
                        </button>
                        {updateFlag != -1 && (
                            <>
                                <div className="separtor"></div>
                                <button
                                    className="delete-btn"
                                    onClick={() => {
                                        dispatch(
                                            DeleteEvent({
                                                updateFlag,
                                                pagenumber: pageNumber,
                                                archive: 0,
                                            })
                                        );
                                        setUpdateFlag(-1);
                                        setEventModal(false);
                                        setNewEvent(initalEvent);
                                        setOldName("");
                                    }}
                                >
                                    Delete
                                </button>
                                <div className="separtor"></div>
                            </>
                        )}
                    </div>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"Home"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Your Event"}
                        setSearchText={setSearchText}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {total} results found
                                    </div>
                                    <Pagintation
                                        setPageNumber={setPageNumber}
                                        pageNumber={pageNumber}
                                        pageCount={number_of_pages}
                                    ></Pagintation>
                                </div>
                                <div
                                    className="row main-row"
                                    style={{
                                        margin: "0",
                                    }}
                                >
                                    <div
                                        className="col-3 event-box-final"
                                        onClick={() => {
                                            setEventModal(true);
                                        }}
                                        style={{
                                            cursor: "pointer",
                                            width: "23.875rem",
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "2.25rem",
                                                margin: "1rem",
                                            }}
                                            src={create}
                                        ></img>
                                        <div className="create-text">
                                            Create New Event
                                        </div>
                                    </div>
                                    {events.map((event, index) => (
                                        <>
                                            {index != events.length - 1 ? (
                                                <div
                                                    className={
                                                        event.published == 1
                                                            ? "col-3 event-box"
                                                            : "col-3 event-box-hidden"
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();

                                                            dispatch(
                                                                SelectEvent({
                                                                    selectedCompany:
                                                                        event.company_id,
                                                                    selectedEvent:
                                                                        event.id,
                                                                    nav,
                                                                })
                                                            );
                                                            //nav("/organiztion/events");
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-between",
                                                            }}
                                                        >
                                                            <div
                                                                className={
                                                                    event.published ==
                                                                    1
                                                                        ? "event-box-title"
                                                                        : "event-box-title-hidden"
                                                                }
                                                            >
                                                                {event.name}
                                                            </div>
                                                            <div
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    setUpdateFlag(
                                                                        event.id
                                                                    );
                                                                    setDesc(
                                                                        event.description
                                                                    );
                                                                    setOldName(
                                                                        event.name
                                                                    );

                                                                    setNewEvent(
                                                                        {
                                                                            ...newEvent,
                                                                            customconfirmemail:
                                                                                event.customconfirmemail,
                                                                            custompreregistration:
                                                                                "<html></html>",
                                                                            customsmssenderid:
                                                                                "LaCasaExpo",
                                                                            smstemplate:
                                                                                "Thank you for purchasing your ticket",
                                                                            smsusername:
                                                                                "hdwxNtai",
                                                                            smspassword:
                                                                                "BfgELGANQZ",
                                                                            customreminderemail:
                                                                                event.customreminderemail,
                                                                            approval_mail:
                                                                                event.approval_mail,
                                                                            approval_subject:
                                                                                event.approval_subject,
                                                                            rejection_mail:
                                                                                event.rejection_mail,
                                                                            rejection_subject:
                                                                                event.rejection_subject,
                                                                            customremindersubject:
                                                                                event.customremindersubject,
                                                                            customconfirmsubject:
                                                                                event.customconfirmsubject,
                                                                            name: event.name,
                                                                            require_questions:
                                                                                event.require_questions,
                                                                            ar_name:
                                                                                event.ar_name,
                                                                            company_id:
                                                                                event.company_id,
                                                                            supports_arabic:
                                                                                event.supports_arabic,
                                                                            facebook_link:
                                                                                event.facebook_link,
                                                                            whatsup_link:
                                                                                event.whatsup_link,
                                                                            template:
                                                                                event.template,
                                                                            location:
                                                                                event.location,
                                                                            pre_register:
                                                                                event.pre_register,
                                                                            ar_location:
                                                                                event.ar_location,
                                                                            gpslocation:
                                                                                event.gpslocation,
                                                                            startdate:
                                                                                event.startdate.split(
                                                                                    " "
                                                                                )[0],
                                                                            from: event.startdate.split(
                                                                                " "
                                                                            )[1],
                                                                            enddate:
                                                                                event.enddate.split(
                                                                                    " "
                                                                                )[0],
                                                                            to: event.enddate.split(
                                                                                " "
                                                                            )[1],
                                                                            logo: event.logo,
                                                                            description:
                                                                                event.description,
                                                                            approval_mail:
                                                                                event.approval_mail,
                                                                            approval_subject:
                                                                                event.approval_subject,
                                                                            rejection_mail:
                                                                                event.rejection_mail,
                                                                            rejection_subject:
                                                                                event.rejection_subject,
                                                                            ar_description:
                                                                                event.ar_description,
                                                                            banner: event.banner,
                                                                            published:
                                                                                event.published,
                                                                        }
                                                                    );
                                                                    setEventModal(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={edit}
                                                                ></img>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                event.published ==
                                                                1
                                                                    ? "event-box-subtitle"
                                                                    : "event-box-subtitle-hidden"
                                                            }
                                                        >
                                                            {event.alias}
                                                        </div>
                                                        <div
                                                            className={
                                                                event.published ==
                                                                1
                                                                    ? "event-box-date"
                                                                    : "event-box-date-hidden"
                                                            }
                                                        >
                                                            {new Date(
                                                                event?.startdate
                                                            ).toLocaleString(
                                                                "en-GB",
                                                                {
                                                                    day: "numeric",
                                                                    month: "long",
                                                                    year: "numeric",
                                                                }
                                                            )}
                                                            -
                                                            {new Date(
                                                                event?.enddate
                                                            ).toLocaleString(
                                                                "en-GB",
                                                                {
                                                                    day: "numeric",
                                                                    month: "long",
                                                                    year: "numeric",
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="card-text"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position:
                                                                    "relative",
                                                                left: "-1rem",
                                                            }}
                                                        >
                                                            <ThemeProvider
                                                                theme={theme}
                                                            >
                                                                <Switch
                                                                    checked={
                                                                        event.published ==
                                                                        1
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        setOldName(
                                                                            event.name
                                                                        );

                                                                        handleToggle(
                                                                            event
                                                                        );
                                                                    }}
                                                                    color={
                                                                        event.published
                                                                            ? "primary"
                                                                            : "secondary"
                                                                    }
                                                                    value="selectUploadBool"
                                                                />
                                                            </ThemeProvider>
                                                            <span
                                                                style={
                                                                    event.published
                                                                        ? {
                                                                              color: "white",
                                                                              position:
                                                                                  "relative",
                                                                              top: "3px",
                                                                          }
                                                                        : {
                                                                              position:
                                                                                  "relative",
                                                                              top: "3px",
                                                                          }
                                                                }
                                                            >
                                                                {event.published
                                                                    ? "Published"
                                                                    : "Hidden"}
                                                            </span>
                                                        </div>
                                                        <div
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setArchivedEvent(
                                                                    {
                                                                        ...archivedEvent,
                                                                        id: event.id,
                                                                        name: event.name,
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="1.2rem"
                                                                height="43.06"
                                                                style={{
                                                                    margin: "0 0.3rem",
                                                                }}
                                                                fill={
                                                                    event.published
                                                                        ? "white"
                                                                        : ""
                                                                }
                                                                viewBox="0 0 49.211 43.06"
                                                            >
                                                                <g
                                                                    id="archive"
                                                                    transform="translate(-3.6 -5.4)"
                                                                >
                                                                    <path
                                                                        id="Path_4963"
                                                                        data-name="Path 4963"
                                                                        d="M9.751,5.4a6.151,6.151,0,0,0,0,12.3H46.66a6.151,6.151,0,0,0,0-12.3H9.751Z"
                                                                        transform="translate(0 0)"
                                                                    />
                                                                    <path
                                                                        id="Path_4964"
                                                                        data-name="Path 4964"
                                                                        d="M5.4,14.4H48.46V35.93a6.151,6.151,0,0,1-6.151,6.151H11.551A6.151,6.151,0,0,1,5.4,35.93Zm15.378,9.227a3.076,3.076,0,0,1,3.076-3.076h6.151a3.076,3.076,0,1,1,0,6.151H23.854a3.076,3.076,0,0,1-3.076-3.076Z"
                                                                        transform="translate(1.276 6.378)"
                                                                        fill-rule="evenodd"
                                                                    />
                                                                </g>
                                                            </svg>
                                                            <span
                                                                style={
                                                                    event.published
                                                                        ? {
                                                                              color: "white",
                                                                              display:
                                                                                  "felx",
                                                                              position:
                                                                                  "relative",
                                                                              top: "1px",
                                                                          }
                                                                        : {
                                                                              display:
                                                                                  "felx",
                                                                              position:
                                                                                  "relative",
                                                                              top: "1px",
                                                                          }
                                                                }
                                                            >
                                                                Archive
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <div
                                                        className={
                                                            event.published == 1
                                                                ? "col-3 event-box"
                                                                : "col-3 event-box-hidden"
                                                        }
                                                    >
                                                        <div
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                dispatch(
                                                                    SelectEvent(
                                                                        {
                                                                            selectedCompany:
                                                                                event.company_id,
                                                                            selectedEvent:
                                                                                event.id,
                                                                            nav,
                                                                        }
                                                                    )
                                                                );
                                                            }}
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "space-between",
                                                                }}
                                                            >
                                                                <div
                                                                    className={
                                                                        event.published ==
                                                                        1
                                                                            ? "event-box-title"
                                                                            : "event-box-title-hidden"
                                                                    }
                                                                >
                                                                    {event.name}
                                                                </div>
                                                                <div
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        setUpdateFlag(
                                                                            event.id
                                                                        );
                                                                        setDesc(
                                                                            event.description
                                                                        );
                                                                        setOldName(
                                                                            event.name
                                                                        );

                                                                        setNewEvent(
                                                                            {
                                                                                ...newEvent,
                                                                                customconfirmemail:
                                                                                    event.customconfirmemail,
                                                                                custompreregistration:
                                                                                    "<html></html>",
                                                                                customsmssenderid:
                                                                                    "LaCasaExpo",
                                                                                smstemplate:
                                                                                    "Thank you for purchasing your ticket",
                                                                                smsusername:
                                                                                    "hdwxNtai",
                                                                                smspassword:
                                                                                    "BfgELGANQZ",
                                                                                customreminderemail:
                                                                                    event.customreminderemail,
                                                                                customremindersubject:
                                                                                    event.customremindersubject,
                                                                                customconfirmsubject:
                                                                                    event.customconfirmsubject,
                                                                                name: event.name,
                                                                                require_questions:
                                                                                    event.require_questions,
                                                                                ar_name:
                                                                                    event.ar_name,
                                                                                shorten_url:
                                                                                    event?.shorten_url,
                                                                                company_id:
                                                                                    event.company_id,
                                                                                location:
                                                                                    event.location,
                                                                                approval_mail:
                                                                                    event.approval_mail,
                                                                                approval_subject:
                                                                                    event.approval_subject,
                                                                                rejection_mail:
                                                                                    event.rejection_mail,
                                                                                rejection_subject:
                                                                                    event.rejection_subject,
                                                                                supports_arabic:
                                                                                    event.supports_arabic,
                                                                                facebook_link:
                                                                                    event.facebook_link,
                                                                                whatsup_link:
                                                                                    event.whatsup_link,
                                                                                template:
                                                                                    event.template,
                                                                                ar_location:
                                                                                    event.ar_location,
                                                                                pre_register:
                                                                                    event.pre_register,
                                                                                gpslocation:
                                                                                    event.gpslocation,
                                                                                startdate:
                                                                                    event.startdate.split(
                                                                                        " "
                                                                                    )[0],
                                                                                from: event.startdate.split(
                                                                                    " "
                                                                                )[1],
                                                                                enddate:
                                                                                    event.enddate.split(
                                                                                        " "
                                                                                    )[0],
                                                                                to: event.enddate.split(
                                                                                    " "
                                                                                )[1],
                                                                                logo: event.logo,
                                                                                description:
                                                                                    event.description,
                                                                                ar_description:
                                                                                    event.ar_description,
                                                                                banner: event.banner,
                                                                                published:
                                                                                    event.published,
                                                                            }
                                                                        );
                                                                        setEventModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            edit
                                                                        }
                                                                    ></img>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={
                                                                    event.published ==
                                                                    1
                                                                        ? "event-box-subtitle"
                                                                        : "event-box-subtitle-hidden"
                                                                }
                                                            >
                                                                {event.alias}
                                                            </div>
                                                            <div
                                                                className={
                                                                    event.published ==
                                                                    1
                                                                        ? "event-box-date"
                                                                        : "event-box-date-hidden"
                                                                }
                                                            >
                                                                {new Date(
                                                                    event?.startdate
                                                                ).toLocaleString(
                                                                    "en-GB",
                                                                    {
                                                                        day: "numeric",
                                                                        month: "long",
                                                                        year: "numeric",
                                                                    }
                                                                )}
                                                                -
                                                                {new Date(
                                                                    event?.enddate
                                                                ).toLocaleString(
                                                                    "en-GB",
                                                                    {
                                                                        day: "numeric",
                                                                        month: "long",
                                                                        year: "numeric",
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="card-text"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-between",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position:
                                                                        "relative",
                                                                    left: "-1rem",
                                                                }}
                                                            >
                                                                <Switch
                                                                    checked={
                                                                        event.published ==
                                                                        1
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        handleToggle(
                                                                            event
                                                                        );
                                                                    }}
                                                                    color={
                                                                        event.published
                                                                            ? "primary"
                                                                            : "secondary"
                                                                    }
                                                                    value="selectUploadBool"
                                                                />
                                                                <span
                                                                    style={
                                                                        event.published
                                                                            ? {
                                                                                  color: "white",
                                                                                  position:
                                                                                      "relative",
                                                                                  top: "3px",
                                                                              }
                                                                            : {
                                                                                  position:
                                                                                      "relative",
                                                                                  top: "3px",
                                                                              }
                                                                    }
                                                                >
                                                                    {event.published
                                                                        ? "Published"
                                                                        : "Hidden"}
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    setArchivedEvent(
                                                                        {
                                                                            ...archivedEvent,
                                                                            id: event.id,
                                                                            name: event.name,
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="1.5rem"
                                                                    height="43.06"
                                                                    fill={
                                                                        event.published
                                                                            ? "white"
                                                                            : ""
                                                                    }
                                                                    viewBox="0 0 49.211 43.06"
                                                                >
                                                                    <g
                                                                        id="archive"
                                                                        transform="translate(-3.6 -5.4)"
                                                                    >
                                                                        <path
                                                                            id="Path_4963"
                                                                            data-name="Path 4963"
                                                                            d="M9.751,5.4a6.151,6.151,0,0,0,0,12.3H46.66a6.151,6.151,0,0,0,0-12.3H9.751Z"
                                                                            transform="translate(0 0)"
                                                                        />
                                                                        <path
                                                                            id="Path_4964"
                                                                            data-name="Path 4964"
                                                                            d="M5.4,14.4H48.46V35.93a6.151,6.151,0,0,1-6.151,6.151H11.551A6.151,6.151,0,0,1,5.4,35.93Zm15.378,9.227a3.076,3.076,0,0,1,3.076-3.076h6.151a3.076,3.076,0,1,1,0,6.151H23.854a3.076,3.076,0,0,1-3.076-3.076Z"
                                                                            transform="translate(1.276 6.378)"
                                                                            fill-rule="evenodd"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                                <span
                                                                    style={
                                                                        event.published
                                                                            ? {
                                                                                  color: "white",
                                                                              }
                                                                            : {
                                                                                  position:
                                                                                      "relative",
                                                                                  top: "1px",
                                                                              }
                                                                    }
                                                                >
                                                                    Archive
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
            {archivedEvent.id && (
                <div className="delete-tab" style={{ display: "flex" }}>
                    Are you sure you want to archive {archivedEvent.name}?
                    <button
                        style={{
                            margin: "0 8px",
                            color: "white",
                            borderColor: "transparent",
                            padding: "3px 11px",
                            borderRadius: "3px",
                            marginLeft: "5px",
                            border: "1px solid",
                            background:
                                "transparent linear-gradient(116deg, #2B6EC8 0%, #2B6EC8 44%, #00D7E1 100%) 0% 0% no-repeat padding-box",
                        }}
                        className="delete-tab-btn"
                        onClick={archiveEvent}
                    >
                        Archive
                    </button>
                    <button
                        style={{
                            margin: "0 8px",
                            color: "black",

                            padding: "3px 11px",
                            borderRadius: "3px",
                            marginLeft: "5px",

                            border: "1px solid #2b6ec8",
                            background: "transparent  padding-box",
                        }}
                        className="delete-tab-btn"
                        onClick={() => {
                            setArchivedEvent({
                                ...archivedEvent,
                                id: null,
                                name: "",
                            });
                        }}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </>
    );
}

export default Home;
