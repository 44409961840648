import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import LogIn from "./pages/auth/LogIn";
import { Provider } from "react-redux";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { store } from "redux/store";
import Home from "./pages/home/home";
import { getUserInfo } from "redux/Auth/actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Events from "./Components/Events";
import AgendaEvents from "./pages/Agenda/AgendaEvents";
import SelectEvent from "./Components/SelectEvent";
import { getSelectedEvent } from "redux/Events/actions";
import Agenda from "./pages/Agenda/agenda";
import Sponsors from "./pages/Sponsors/Sponsors";
import Partners from "./pages/Partners/Partners";
import Booths from "./pages/Booths/Booths";
import Sessions from "./pages/Sessions/Sessions";
import TicketTypes from "./pages/TicketTypes/TicketTypes";
import Codes from "./pages/Codes/Codes";
import PageBuilder from "./pages/PageBuilder/PageBuilder";
import Organization from "./pages/Organization/Organization";
import UserRoles from "./pages/UserRoles/UserRoles";
import SuperAdmin from "./pages/UserRoles/SuperAdmin";
import Admin from "./pages/UserRoles/Admin";
import Ushers from "./pages/EventUserRoles/Ushers";
import Moderator from "./pages/EventUserRoles/Moderator";
import { useLocation } from "react-router-dom";
import ArchivedEvent from "./pages/ArchivedEvents/ArchivedEvent";
import AttendeeList from "./pages/AttendeeList/AttendeeList";
import Emails from "./pages/Emails/Emails";
import Speakers from "./pages/Speakers/Speaker";
import Questions from "./pages/Questions/Questions";
import Attendance from "./pages/Reports/attendance";
import Gender from "./pages/Reports/gender";
import Types from "./pages/Reports/types";
import AgeGroup from "./pages/Reports/age";
import CheckIn from "./pages/CheckIn/CheckIn";
import Register from "./pages/auth/Register";
import ForgetPassword from "pages/auth/ForgetPassword";
import CheckOtp from "pages/auth/CheckOtp";
import ChangePassword from "pages/auth/ChangePasssword";
import QuestionsReport from "pages/Reports/Questions";
import Unpaid from "pages/UnpaidAttendee/Unpaid";
import Tempalte from "pages/Templates/Templates";
import ApproveList from "pages/ApproveList/ApproveList";
import axios from "axios";
import Announcement from "pages/Announcement/Announcement";
import Team from "pages/Booths/Team";
import SponsorTeam from "./pages/Sponsors/Team";
import Admins from "pages/EventUserRoles/Admins";
import Type from "pages/EventUserRoles/Sales/Type";
import Boothsales from "pages/EventUserRoles/Sales/Booths";
import Sponsorsales from "pages/EventUserRoles/Sales/Sponsors";
import BoothSalesTeam from "pages/EventUserRoles/Sales/BoothTeam";
import SponsorSalesTeam from "pages/EventUserRoles/Sales/SponsorTeam";
import SlideShow from "pages/SlideShow/SlideShow";
import SocialEngagment from "pages/SocialEngagment";
import Packges from "pages/Packages/Packages";
import Timeline from "pages/TimelineList";
import TicketEmails from "pages/TicketTypes/Emails/Emails";
import Profile from "pages/Profile/Profile";
import TicketTypesQuestion from "pages/Ticket-Question";
import EmailConfig from "pages/EmailConfig/EmailConfig";
import EmailTemplates from "pages/EmailTemplates/EmailTemplates";
import RejectList from "pages/RejectList/RejectList";
import Faqs from "pages/Faqs/Faqs";
function App() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { user } = useSelector((state) => state.user);
    useEffect(() => {
        //axios.defaults.baseURL = "https://api.frontiers.events/api/";
        //axios.defaults.baseURL = "https://stg.frontiers.events/api";
        axios.defaults.baseURL = "https://frontiers.o-projects.org/api/";

        const loggedInUser = localStorage.getItem("token");
        const selectedCompany = localStorage.getItem("selectedCompany");
        const selectedEvent = localStorage.getItem("selectedEvent");
        console.log(loggedInUser, user, "token");
        if (!loggedInUser) {
            navigate("/login");
        } else {
            dispatch(getUserInfo(user));
            dispatch(
                getSelectedEvent({
                    selectedCompany: selectedCompany,
                    selectedEvent: selectedEvent,
                    nav: navigate,
                })
            );
        }
    }, []);
    return (
        // <Provider store={store}>
        <>
            <Routes>
                <Route path="/" element={<Home></Home>}></Route>
                <Route path="/login" element={<LogIn></LogIn>}></Route>
                <Route path="/register" element={<Register></Register>}></Route>
                <Route
                    path="/forget-password"
                    element={<ForgetPassword />}
                ></Route>
                <Route path="/check-otp" element={<CheckOtp />}></Route>
                <Route
                    path="/reset-password"
                    element={<ChangePassword />}
                ></Route>
                <Route
                    path="/socialengagment"
                    element={<SocialEngagment></SocialEngagment>}
                ></Route>
                <Route path=":id/agenda" element={<Agenda></Agenda>}></Route>
                <Route path=":id/faqs" element={<Faqs></Faqs>}></Route>
                <Route
                    path="/archived-event"
                    element={<ArchivedEvent></ArchivedEvent>}
                ></Route>
                <Route
                    path=":id/organiztion/events"
                    element={<SelectEvent></SelectEvent>}
                ></Route>
                <Route
                    path=":id/sponsors"
                    element={<Sponsors></Sponsors>}
                ></Route>
                <Route
                    path=":id/sponsors/:uuid/team"
                    element={<SponsorTeam></SponsorTeam>}
                ></Route>
                <Route
                    path=":id/partners"
                    element={<Partners></Partners>}
                ></Route>
                <Route
                    path=":id/email-config/:uid"
                    element={<EmailConfig></EmailConfig>}
                ></Route>
                <Route
                    path=":id/event-user-roles/admins"
                    element={<Admins></Admins>}
                ></Route>
                <Route path=":id/booths" element={<Booths></Booths>}></Route>
                <Route
                    path=":id/slide-show"
                    element={<SlideShow></SlideShow>}
                ></Route>
                <Route
                    path=":id/booths/:uuid/team"
                    element={<Team></Team>}
                ></Route>
                <Route
                    path=":id/attendee-list"
                    element={<AttendeeList></AttendeeList>}
                ></Route>
                <Route
                    path=":id/approve-list"
                    element={<ApproveList></ApproveList>}
                ></Route>
                <Route
                    path=":id/rejected-list"
                    element={<RejectList></RejectList>}
                ></Route>
                <Route
                    path=":id/check-in"
                    element={<CheckIn></CheckIn>}
                ></Route>
                <Route
                    path=":id/time-line"
                    element={<Timeline></Timeline>}
                ></Route>
                <Route
                    path=":id/unpaid-attendee"
                    element={<Unpaid></Unpaid>}
                ></Route>
                <Route
                    path=":id/sessions"
                    element={<Sessions></Sessions>}
                ></Route>
                <Route path=":id/emails" element={<Emails></Emails>}></Route>
                <Route
                    path=":id/templates"
                    element={<Tempalte></Tempalte>}
                ></Route>
                <Route
                    path=":id/ticket_types/emails/:uuid"
                    element={<TicketEmails></TicketEmails>}
                ></Route>
                <Route
                    path=":id/ticket_types"
                    element={<TicketTypes></TicketTypes>}
                ></Route>
                <Route
                    path=":id/ticket_list"
                    element={<EmailTemplates />}
                ></Route>
                <Route
                    path=":id/packages"
                    element={<Packges></Packges>}
                ></Route>
                <Route path=":id/code" element={<Codes></Codes>}></Route>
                <Route
                    path=":id/page_builder"
                    element={<PageBuilder></PageBuilder>}
                ></Route>
                <Route
                    path="/organization"
                    element={<Organization></Organization>}
                ></Route>
                <Route path="/profile" element={<Profile></Profile>}></Route>
                <Route
                    path="/user-roles"
                    element={<UserRoles></UserRoles>}
                ></Route>
                <Route
                    path="/user-roles/super-admin"
                    element={<SuperAdmin></SuperAdmin>}
                ></Route>
                <Route
                    path="/user-roles/admin"
                    element={<Admin></Admin>}
                ></Route>
                <Route
                    path=":id/event-user-roles/ushers"
                    element={<Ushers></Ushers>}
                ></Route>
                <Route
                    path=":id/event-user-roles/moderator"
                    element={<Moderator></Moderator>}
                ></Route>
                <Route
                    path=":id/event-user-roles/moderator"
                    element={<Ushers></Ushers>}
                ></Route>
                <Route
                    path=":id/speakers"
                    element={<Speakers></Speakers>}
                ></Route>
                <Route
                    path=":id/ticketsquestions/questions/:uid"
                    element={<Questions></Questions>}
                ></Route>
                <Route
                    path=":id/ticketsquestions"
                    element={<TicketTypesQuestion></TicketTypesQuestion>}
                ></Route>
                <Route
                    path=":id/announcement"
                    element={<Announcement></Announcement>}
                ></Route>
                <Route
                    path=":id/event-user-roles/sales"
                    element={<Type></Type>}
                ></Route>
                <Route
                    path=":id/event-user-roles/sales/booths"
                    element={<Boothsales></Boothsales>}
                ></Route>
                <Route
                    path=":id/event-user-roles/sales/booths/:uuid"
                    element={<BoothSalesTeam></BoothSalesTeam>}
                ></Route>
                <Route
                    path=":id/event-user-roles/sales/sponsors"
                    element={<Sponsorsales></Sponsorsales>}
                ></Route>
                <Route
                    path=":id/event-user-roles/sales/sponsors/:uuid"
                    element={<SponsorSalesTeam></SponsorSalesTeam>}
                ></Route>
                <Route
                    path=":id/reports/attendance"
                    element={<Attendance></Attendance>}
                ></Route>
                <Route
                    path=":id/reports/gender"
                    element={<Gender></Gender>}
                ></Route>
                <Route
                    path=":id/reports/types"
                    element={<Types></Types>}
                ></Route>
                <Route
                    path=":id/reports/age-group"
                    element={<AgeGroup></AgeGroup>}
                ></Route>
                <Route
                    path=":id/reports/questions"
                    element={<QuestionsReport></QuestionsReport>}
                ></Route>
            </Routes>

            <ToastContainer autoClose={5000} />
        </>
        // </Provider>
    );
}

export default App;
