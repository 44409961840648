import React, { useEffect, useRef, useState } from "react";
import { createEvent, editEvent, getAllEvent } from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import create from "../../assets/images/addsponsor.svg";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import Loader from "../../Components/Loader";
import Pagintation from "../../Components/Pagintation";
import {
    CreateSponsors,
    DeleteSponsors,
    EditSponsors,
    getSponsors,
} from "redux/Sponsors/actions";
import { useNavigate, useParams } from "react-router";
// import JoditEditor from "jodit-react";
import JoditEditor from "jodit-pro-react";
const route = process.env.REACT_APP_IMAGE_URI;
const initalSponsor = {
    name: "",
    about: "",
    address: "",
    logo: null,
    image: null,
    video: "",
    website: "",
    facebook: "",
    instagram: "",
    youtube: "",
    event_id: null,
    category: null,
};

function Sponsors() {
    const defaultConfig = {
        license: "C464Q-1GIK1-BF6OK-K290Y",
        uploader: {
            url: "https://frontiers.o-projects.org/api/media/upload",
            format: "json",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            isSuccess: function (response) {
                // Assume success if there is a URL in the response
                return response && response.url;
            },
            getMsg: function (response) {
                // Return a success message or default message
                return response && response.url
                    ? "File uploaded successfully"
                    : "File upload failed";
            },
            process: function (response) {
                console.log(response.url); // Log the response for debugging

                // Determine the file type based on the response or other logic
                const fileType = response.url.match(/\.(jpg|jpeg|png|gif)$/i)
                    ? "image"
                    : "file";

                return {
                    files: [
                        {
                            path: response.url,
                            type: fileType,
                        },
                    ],
                    baseurl: "", // Base url for filebrowser
                };
            },
            defaultHandlerSuccess: function (data) {
                const field = "files";
                if (data[field] && data[field].length) {
                    for (let i = 0; i < data[field].length; i++) {
                        const file = data[field][i];

                        // Insert image if it's an image
                        if (file.type === "image") {
                            this.selection.insertImage(file.path);
                        }
                        // Insert link if it's a file (non-image)
                        else if (file.type === "file") {
                            const fileName = file.path.split("/").pop(); // Get file name from path
                            this.selection.insertHTML(
                                `<a href="${file.path}" target="_blank">${fileName}</a>`
                            );
                        }
                    }
                }
            },
        },
    };
    const dispatch = useDispatch();
    const { sponsors, loader, total, number_of_pages } = useSelector(
        (state) => state.sponsors
    );
    const { selectedCompany, selectedEvent } = useSelector(
        (state) => state.events
    );
    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [error, setError] = useState(false);
    const editor = useRef(null);
    const [newSponsor, setNewSponsor] = useState(initalSponsor);
    const params = useParams();
    const nav = useNavigate();

    useEffect(() => {
        if (params.id) {
            dispatch(
                getSponsors({ event_id: params.id, pagenumber: pageNumber })
            );
        }
    }, [pageNumber, params]);

    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#00D7E1",
            },
            secondary: {
                // This is green.A700 as hex.
                main: "#222222",
            },
        },
    });
    const addind = () => {
        if (
            newSponsor.name == "" ||
            newSponsor.about == "" ||
            newSponsor.address == " " ||
            !newSponsor.category ||
            newSponsor.facebook == "" ||
            !newSponsor.image ||
            !newSponsor.logo ||
            newSponsor.instagram == "" ||
            newSponsor.website == "" ||
            newSponsor.youtube == ""
        ) {
            setError(true);
            return;
        }
        if (updateFlag != -1) {
            let form = { ...newSponsor };
            form.id = parseInt(updateFlag);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(EditSponsors({ body, selectedEvent }));
            setEventModal(false);
        } else {
            let form = { ...newSponsor };
            form.event_id = parseInt(params.id);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(CreateSponsors({ body, selectedEvent }));
            setEventModal(false);
        }
        setNewSponsor(initalSponsor);
        setError(false);
        setUpdateFlag(-1);
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };
    return (
        <>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewSponsor(initalSponsor);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create New Sponsor
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Sponsor
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row">
                            <div
                                className="col-6 fv-row mb-5 fv-plugins-icon-container"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    Sponsor logo
                                </label>

                                {newSponsor.logo ? (
                                    <>
                                        <img
                                            style={{
                                                maxWidth: "35%",
                                                height: "7rem",
                                            }}
                                            src={
                                                newSponsor.logo
                                                    ? newSponsor.logo?.name
                                                        ? URL.createObjectURL(
                                                              newSponsor.logo
                                                          )
                                                        : `${route}${newSponsor.logo}`
                                                    : ""
                                            }
                                        ></img>
                                        <div
                                            className="d-flex"
                                            style={{ margin: "1rem 0" }}
                                        >
                                            <label className="circles">
                                                <img
                                                    src={edit}
                                                    style={{ width: "1rem" }}
                                                ></img>
                                                <input
                                                    name="logo"
                                                    value={""}
                                                    onChange={(e) =>
                                                        setNewSponsor({
                                                            ...newSponsor,
                                                            [e.target.name]:
                                                                e.target
                                                                    .files[0],
                                                        })
                                                    }
                                                    type="file"
                                                    style={{
                                                        display: "none",
                                                        cursor: "pointer",
                                                    }}
                                                    accept=".png, .jpg, .jpeg, .svg"
                                                />
                                            </label>
                                            <div className="separtor"></div>
                                            <div className="circles">
                                                <img
                                                    src={del}
                                                    style={{
                                                        width: "0.8rem",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        setNewSponsor({
                                                            ...newSponsor,
                                                            logo: null,
                                                        });
                                                    }}
                                                ></img>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <label className="upload-btn">
                                        <input
                                            style={
                                                error && !newSponsor.logo
                                                    ? {
                                                          border: "1px solid red",
                                                      }
                                                    : {}
                                            }
                                            type="file"
                                            style={{ display: "none" }}
                                            name="logo"
                                            value={""}
                                            accept=".png, .jpg, .jpeg, .svg"
                                            onChange={(e) => {
                                                setNewSponsor((prevState) => ({
                                                    ...prevState,
                                                    [e.target.name]:
                                                        e.target.files[0],
                                                }));
                                            }}
                                        />
                                        Upload image
                                    </label>
                                )}
                                <div style={{ marginTop: "0.5rem" }}>
                                    <span>Logo Dimension:160x160 px</span>
                                </div>
                                <div>
                                    {!newSponsor.logo && error && (
                                        <span style={{ color: "red" }}>
                                            Logo is required
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div
                                className="col-6 fv-row mb-5 fv-plugins-icon-container"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <label
                                    style={{ color: "black" }}
                                    className="d-flex align-items-center fs-7 mb-2"
                                >
                                    Sponsor image
                                </label>

                                {newSponsor.image ? (
                                    <>
                                        <img
                                            style={{
                                                maxWidth: "35%",
                                                height: "7rem",
                                            }}
                                            src={
                                                newSponsor.image
                                                    ? newSponsor.image?.name
                                                        ? URL.createObjectURL(
                                                              newSponsor.image
                                                          )
                                                        : `${route}${newSponsor.image}`
                                                    : ""
                                            }
                                        ></img>
                                        <div
                                            className="d-flex"
                                            style={{ margin: "1rem 0" }}
                                        >
                                            <label className="circles">
                                                <img
                                                    src={edit}
                                                    style={{ width: "1rem" }}
                                                ></img>
                                                <input
                                                    name="image"
                                                    value={""}
                                                    onChange={(e) =>
                                                        setNewSponsor({
                                                            ...newSponsor,
                                                            [e.target.name]:
                                                                e.target
                                                                    .files[0],
                                                        })
                                                    }
                                                    type="file"
                                                    style={{
                                                        display: "none",
                                                        cursor: "pointer",
                                                    }}
                                                    accept=".png, .jpg, .jpeg, .svg"
                                                />
                                            </label>
                                            <div className="separtor"></div>
                                            <div className="circles">
                                                <img
                                                    src={del}
                                                    style={{
                                                        width: "0.8rem",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        setNewSponsor({
                                                            ...newSponsor,
                                                            image: null,
                                                        });
                                                    }}
                                                ></img>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <label className="upload-btn">
                                        <input
                                            style={
                                                error && !newSponsor.image
                                                    ? {
                                                          border: "1px solid red",
                                                      }
                                                    : {}
                                            }
                                            type="file"
                                            style={{ display: "none" }}
                                            name="image"
                                            value={""}
                                            accept=".png, .jpg, .jpeg, .svg"
                                            onChange={(e) =>
                                                setNewSponsor((prevState) => ({
                                                    ...prevState,
                                                    [e.target.name]:
                                                        e.target.files[0],
                                                }))
                                            }
                                        />
                                        Upload image
                                    </label>
                                )}
                                <div style={{ marginTop: "0.5rem" }}>
                                    <span>Cover Dimension:1349x600 px</span>
                                </div>
                                <div>
                                    {!newSponsor.image && error && (
                                        <span style={{ color: "red" }}>
                                            Banner is required
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Sponsor name
                                </span>
                                <input
                                    style={
                                        error && !newSponsor.name
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="name"
                                    placeholder=""
                                    value={newSponsor?.name}
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Sponsor Address
                                </span>
                                <input
                                    style={
                                        error && !newSponsor.address
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="address"
                                    placeholder=""
                                    value={newSponsor?.address}
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Sponsor video
                                </span>
                                <JoditEditor
                                    config={defaultConfig}
                                    ref={editor}
                                    style={
                                        error && !newSponsor.video
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="video"
                                    placeholder=""
                                    value={newSponsor.video}
                                    onChange={(newContent) => {
                                        setNewSponsor((prev) => {
                                            return {
                                                ...prev,
                                                video: newContent,
                                            };
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-6">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Website
                                </span>
                                <input
                                    style={
                                        error && !newSponsor.website
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="website"
                                    placeholder=""
                                    value={newSponsor?.website}
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-6">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    YouTube Link
                                </span>
                                <input
                                    style={
                                        error && !newSponsor.youtube
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="youtube"
                                    placeholder=""
                                    value={newSponsor?.youtube}
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-6">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Facebook Link
                                </span>
                                <input
                                    style={
                                        error && !newSponsor.facebook
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="facebook"
                                    placeholder=""
                                    value={newSponsor?.facebook}
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-6">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Instagram Link
                                </span>
                                <input
                                    style={
                                        error && !newSponsor.instagram
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="instagram"
                                    placeholder=""
                                    value={newSponsor?.instagram}
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Sponsor category
                                </span>
                                <select
                                    style={
                                        error && !newSponsor.category
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="category"
                                    placeholder=""
                                    value={newSponsor?.category}
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                >
                                    <option value={null}></option>
                                    <option value={1}>Agency</option>
                                    <option value={2}>Tech</option>
                                    <option value={3}>Telecom</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Sponsor Description
                                </span>
                                <input
                                    style={
                                        error && !newSponsor.about
                                            ? {
                                                  border: "1px solid red",
                                                  height: "7rem",
                                              }
                                            : { height: "7rem" }
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="about"
                                    placeholder=""
                                    value={newSponsor?.about}
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div style={{ marginTop: "0.5rem" }}>
                                <span>
                                    * Description Limit is 255 character
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center px-5 pb-lg-0 my-10">
                        <button
                            type="button"
                            id="kt_password_reset_submit"
                            className="save-btn fw-bolder"
                            onClick={addind}
                        >
                            <span>Save Changes</span>
                        </button>

                        {updateFlag != -1 && (
                            <>
                                <div className="separtor"></div>
                                <button
                                    className="delete-btn"
                                    onClick={() => {
                                        dispatch(
                                            DeleteSponsors({
                                                updateFlag,
                                                selectedEvent,
                                            })
                                        );
                                        setUpdateFlag(-1);
                                        setEventModal(false);
                                        setNewSponsor(initalSponsor);
                                    }}
                                >
                                    Delete
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"Sponsors"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Sponsor"}
                        setSearchText={setSearchText}
                        EventName={params.id}
                        showUrl={true}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {total} results found
                                    </div>
                                    <Pagintation
                                        setPageNumber={setPageNumber}
                                        pageCount={number_of_pages}
                                    ></Pagintation>
                                </div>
                                <div
                                    className="row main-row"
                                    style={{ margin: "0" }}
                                >
                                    <div
                                        className="col-3 event-box-final"
                                        onClick={() => {
                                            setEventModal(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <img
                                            style={{
                                                width: "2.25rem",
                                                margin: "1rem",
                                            }}
                                            src={`${create}`}
                                        ></img>
                                        <div className="create-text">
                                            Add Sponsor
                                        </div>
                                    </div>
                                    {sponsors.map((event, index) => (
                                        <>
                                            <div
                                                className="sponsor-box"
                                                style={{ display: "flex" }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setUpdateFlag(event.id);
                                                    setNewSponsor({
                                                        name: event.name,
                                                        about: event.about,
                                                        image: event.image,
                                                        address: event.address,
                                                        logo: event.logo,
                                                        category:
                                                            event.category,
                                                        event_id:
                                                            event.event_id,
                                                        facebook:
                                                            event.facebook,
                                                        instagram:
                                                            event.instagram,
                                                        video: event.video,
                                                        website: event.website,
                                                        youtube: event.youtube,
                                                    });
                                                    setEventModal(true);
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        margin: "auto",
                                                        //display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        <img
                                                            style={{
                                                                maxWidth:
                                                                    "7rem",
                                                                maxHeight:
                                                                    "5rem",
                                                            }}
                                                            src={`${route}${event.logo}`}
                                                        ></img>
                                                    </div>
                                                </div>

                                                <div
                                                    style={{
                                                        width: "2rem",
                                                        float: "right",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={(e) => {
                                                        //dispatch(getBoothTeam(event.id))
                                                        e.stopPropagation();
                                                        nav(`${event.id}/team`);
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 640 512"
                                                    >
                                                        <path d="M352 128c0 70.7-57.3 128-128 128s-128-57.3-128-128S153.3 0 224 0s128 57.3 128 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sponsors;
