import React, { useEffect, useLayoutEffect, useState } from "react";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { getCompanies } from "redux/Companies/actions";
import { CreateFaq, DeleteFaq, EditFaq, getFaqs } from "redux/Faqs/actions";
import Pagintation from "../../Components/Pagintation";
import { useParams } from "react-router";

const initalAdmin = {
    type: 7,
    question: "",
    answer: "",
};

function Faqs() {
    const dispatch = useDispatch();
    const { faqs, loader, total, number_of_pages } = useSelector((state) => {
        return state.faqs;
    });
    const [delModal, setDelModal] = useState(false);

    const { event } = useSelector((state) => state.events);
    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [error, setError] = useState(false);
    const [newAdmin, setNewAdmin] = useState(initalAdmin);
    const params = useParams();
    useEffect(() => {
        if (params.id) {
            // dispatch(getCompanies({ name: "" }));
            dispatch(getFaqs({ id: params.id, pagenumber: pageNumber }));
        }
    }, [pageNumber]);

    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#00D7E1",
            },
            secondary: {
                // This is green.A700 as hex.
                main: "#222222",
            },
        },
    });

    const addind = () => {
        if (newAdmin.question == "" || newAdmin.answer == "") {
            setError(true);
            return;
        }
        if (updateFlag != -1) {
            let form = { ...newAdmin };
            form.id = parseInt(updateFlag);
            if (!form.password) {
                delete form.password;
            }
            form.event_id = params.id;
            form.pageNumber = pageNumber;
            const body = new FormData();

            for (var key in form) {
                body.append(key, form[key]);
            }

            dispatch(EditFaq({ body }));
            setEventModal(false);
        } else {
            let form = { ...newAdmin };
            form.event_id = params.id;
            form.pageNumber = pageNumber;

            const body = new FormData();

            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(CreateFaq({ body }));
            setEventModal(false);
        }
        setNewAdmin(initalAdmin);
        setError(false);
        setUpdateFlag(-1);
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };
    const addModalStyles3 = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "30vh",
        },
    };
    return (
        <>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewAdmin(initalAdmin);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create Faq
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Faq
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Faq question
                                    </span>
                                    <input
                                        style={
                                            error && !newAdmin.question
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="question"
                                        placeholder=""
                                        value={newAdmin?.question}
                                        onChange={(e) =>
                                            setNewAdmin({
                                                ...newAdmin,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Faq Answer
                                    </span>
                                    <textarea
                                        style={
                                            error && !newAdmin.answer
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="answer"
                                        placeholder=""
                                        value={newAdmin?.answer}
                                        onChange={(e) =>
                                            setNewAdmin({
                                                ...newAdmin,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                            <button
                                type="button"
                                id="kt_password_reset_submit"
                                className="save-btn fw-bolder"
                                onClick={addind}
                            >
                                <span>Save Changes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={delModal}
                onRequestClose={() => {
                    setDelModal(false);
                    updateFlag(-1);
                }}
                style={addModalStyles3}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Are you sure you want delete this faq
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                            <button
                                type="button"
                                id="kt_password_reset_submit"
                                className="save-btn fw-bolder"
                                style={{ background: "red" }}
                                onClick={() => {
                                    dispatch(
                                        DeleteFaq({
                                            id: updateFlag,
                                            event_id: params.id,
                                            pageNumber,
                                        })
                                    );
                                    setDelModal(false);

                                    updateFlag(-1);
                                }}
                            >
                                <span>Delete Attendee</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"Faqs"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Faqs"}
                        setSearchText={setSearchText}
                        EventName={params.id}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {total} results found
                                    </div>
                                    <Pagintation
                                        setPageNumber={setPageNumber}
                                        pageNumber={pageNumber}
                                        pageCount={number_of_pages}
                                    ></Pagintation>
                                </div>
                                <div className="row" style={{ margin: "0" }}>
                                    <table
                                        className="table table-striped gy-2 gs-7"
                                        style={{
                                            maxWidth: "1600px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <thead style={{ width: "1600px" }}>
                                            <tr
                                                className="fw-bolder fs-6  border-bottom border-gray-200"
                                                style={{
                                                    backgroundColor: "#2B6EC8",
                                                    borderRadius: "16px",
                                                }}
                                            >
                                                <th
                                                    className="tfirst"
                                                    style={{
                                                        width: "30%",
                                                        color: "white",
                                                    }}
                                                >
                                                    #
                                                </th>
                                                <th
                                                    style={{
                                                        width: "30%",
                                                        color: "white",
                                                    }}
                                                >
                                                    question
                                                </th>
                                                <th
                                                    style={{
                                                        width: "30%",
                                                        color: "white",
                                                    }}
                                                >
                                                    answer
                                                </th>

                                                <th
                                                    className="tlast"
                                                    style={{
                                                        width: "25%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Actions
                                                </th>
                                            </tr>

                                            {faqs?.map((code, index) => (
                                                <React.Fragment key={index}>
                                                    <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.id}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                            }}
                                                        >
                                                            {code.question}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                                maxWidth:
                                                                    "15px",
                                                                whiteSpace:
                                                                    "nowrap",
                                                                overflow:
                                                                    "hidden",
                                                                textOverflow:
                                                                    "ellipsis",
                                                            }}
                                                        >
                                                            {code.answer}
                                                        </th>

                                                        <th>
                                                            <span
                                                                onClick={() => {
                                                                    setUpdateFlag(
                                                                        code.id
                                                                    );

                                                                    setNewAdmin(
                                                                        {
                                                                            question:
                                                                                code.question,
                                                                            answer: code.answer,
                                                                        }
                                                                    );
                                                                    setEventModal(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={edit}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        marginRight:
                                                                            "1rem",
                                                                    }}
                                                                ></img>
                                                            </span>
                                                            <span
                                                            // onClick={() => {
                                                            //   dispatch(
                                                            //     DeleteCode({
                                                            //       updateFlag: code.index,
                                                            //       selectedEvent,
                                                            //     })
                                                            //   );
                                                            // }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    src={del}
                                                                    width={20}
                                                                    height={20}
                                                                    onClick={() => {
                                                                        setDelModal(
                                                                            true
                                                                        );
                                                                        setUpdateFlag(
                                                                            code.id
                                                                        );
                                                                    }}
                                                                ></img>
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                        </thead>
                                    </table>
                                    <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                                        <button
                                            type="button"
                                            id="kt_password_reset_submit"
                                            className="save-btn fw-bolder"
                                            onClick={() => {
                                                setEventModal(true);
                                            }}
                                        >
                                            <span>Add Faq</span>
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Faqs;
