import React, { useEffect, useState } from "react";
import searchLogo from "../assets/images/search.svg";
import { useSelector, useDispatch } from "react-redux";
import { DuplicateEvent, getEventsDetails } from "redux/Events/actions";
import Modal from "react-modal";

const duplication = [
  {
    name: "Questions",
    checked: false,
  },
  {
    name: "Ticket types",
    checked: false,
  },
  {
    name: "Codes",
    checked: false,
  },
  {
    name: "Sections",
    checked: false,
  },
  {
    name: "Sponsors",
    checked: false,
  },
  {
    name: "Agenda",
    checked: false,
  },
];
function PageHeader({
  title,
  setSearchText,
  EventName,
  showUrl,
  handleKeyDown,
}) {
  const [search, setSearch] = useState(false);
  const dispatch = useDispatch();
  const { event, loader } = useSelector((state) => state.events);
  const [eventModal, setEventModal] = useState(false);
  const [parts, setParts] = useState(duplication);
  useEffect(() => {
    if (EventName) {
      dispatch(getEventsDetails(EventName));
    }
  }, [EventName]);
  const handleparts = (index) => {
    let duplicate = [...parts];

    duplicate[index].checked = !duplicate[index].checked;
    setParts(duplicate);
  };
  const duplicate = () => {
    let form = {};
    form.questions = parts[0].checked ? 1 : 0;
    form.tickettypes = parts[1].checked ? 1 : 0;
    form.codes = parts[2].checked ? 1 : 0;
    form.agenda = parts[5].checked ? 1 : 0;
    form.sections = parts[3].checked ? 1 : 0;
    form.sponsors = parts[4].checked ? 1 : 0;
    form.event_id = EventName;
    const body = new FormData();
    for (var key in form) {
      body.append(key, form[key]);
    }
    dispatch(DuplicateEvent({ body }));
    setParts(duplication);
    setEventModal(false);
  };
  const addModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 0,
      transform: "translate(-50%, -50%)",
      borderRaduis: "16px",
      overflowY: "auto",
      height: "90vh",
    },
  };
  return (
    <>
      <Modal
        isOpen={eventModal}
        onRequestClose={() => {
          setEventModal(false);
          setParts(duplication);
        }}
        style={addModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-input">
          <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
            Duplicate {event?.name}
          </h1>
          <div
            className="flex-center"
            style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
          >
            <div className="col-12 fv-row mb-5 fv-plugins-icon-container">
              {parts.map((part, index) => (
                <div
                  key={index}
                  style={{
                    fontSize: "1.2rem",
                    border: "1px solid transparent",
                    borderRadius: "12px",
                    backgroundColor: "#eaf4fa",
                    padding: "0.5rem",
                    display: "flex",
                    margin: "1rem 0 ",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{ margin: "1rem" }}
                    checked={part.checked}
                    onChange={() => handleparts(index)}
                  ></input>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {part.name}
                  </span>
                </div>
              ))}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="save-btn"
                onClick={() => {
                  duplicate();
                  setParts(duplication);
                  setEventModal(false);
                }}
              >
                Duplicate
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div style={{ width: "100%" }}>
        <div className="page-header">
          <div>
            <span className="page-header-title">{title}</span>
            {showUrl && (
              <div>
                <a
                  style={{
                    color: "#00D7E1",
                  }}
                  target="_blank"
                  href={`${event?.shorten_url}`}
                >
                  {event?.shorten_url}
                </a>
              </div>
            )}
          </div>
          {event?.name && EventName && (
            <>
              <div
                className="form-control form-control-lg form-control-solid"
                style={{
                  width: "14rem",
                  height: "fit-content",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {EventName}-{event.name}
              </div>
              <div
                className="page-header-search"
                style={{
                  cursor: "pointer",
                  width: "fit-content",
                  padding: "0.5rem",
                }}
                onClick={() => {
                  setEventModal(true);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  Duplicate Event
                </div>
              </div>
            </>
          )}

          <div>
            {setSearchText && (
              <div className="d-flex">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    className="form-control form-control-lg form-control-solid"
                    style={{ margin: "0 5px" }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                  ></input>
                </div>

                <div
                  className="page-header-search"
                  onClick={() => setSearch(!search)}
                >
                  <img
                    style={{ width: "1.5rem", cursor: "pointer" }}
                    src={searchLogo}
                  ></img>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-block">
          <hr style={{ borderTop: "1px solid grey" }}></hr>
        </div>
      </div>
    </>
  );
}

export default PageHeader;
