import React from 'react'
import ReactPaginate from "react-paginate";
function Pagintation({ setPageNumber, pageCount,pageNumber}) {
  return (
    <ReactPaginate
      //breakLabel="..."
      nextLabel="next >"
      onPageChange={(e) => setPageNumber(e.selected)}
      pageRangeDisplayed={2}
      pageCount={pageCount}
      previousLabel="< previous"
      forcePage={pageNumber}
      renderOnZeroPageCount={null}
      breakClassName={"page-item"}
      breakLinkClassName={"page-link"}
      containerClassName={"pagination"}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      previousClassName={"page-item"}
      previousLinkClassName={"page-link"}
      nextClassName={"page-item"}
      nextLinkClassName={"page-link"}
      activeClassName={"active"}
    />
  );
}

export default Pagintation