import { combineReducers } from "redux";
import agendaReducer from "./Agenda/reducers";
import attendeeReducer from "./Attendee/reducers";
import userReducer from "./Auth/reducers";
import boothReducer from "./Booths/reducers";
import checkInReducer from "./CheckIn/reducers";
import codeReducer from "./Codes/reducers";
import companyReducer from "./Companies/reducers";
import eventReducer from "./Events/reducers";
import pageBuilderReducer from "./PageBuilder/reducers";
import partnerReducer from "./Partners/reducers";
import questionReducer from "./Questions/reducers";
import reportReducer from "./Reports/reducers";
import sessionsReducer from "./Sessions/reducers";
import speakerReducer from "./Speakers/reducers";
import sponsorsReducer from "./Sponsors/reducers";
import ticketTypeReducer from "./TicketTypes/reducers";
import tokenReducer from "./tokens/reducer";
import userRolesReducer from "./UserRoles/reducers";
import announcementsReducer from "./Announcement/reducers";
import slideReducer from "./SlideShow/reducers";
import loadingSlice from "./_loading";
import socialReducer from "./socialengagment/reducers";
import PackageReducer from "./Packages/reducers";
import TimeLineReducer from "./Timeline/reducers";
export const combinedReducer = combineReducers({
  user: userReducer,
  events: eventReducer,
  companies: companyReducer,
  sponsors: sponsorsReducer,
  sessions: sessionsReducer,
  partners: partnerReducer,
  booths: boothReducer,
  agenda: agendaReducer,
  questions: questionReducer,
  ticketTypes: ticketTypeReducer,
  slides: slideReducer,
  code: codeReducer,
  sections: pageBuilderReducer,
  userRoles: userRolesReducer,
  attendee: attendeeReducer,
  speakers: speakerReducer,
  reports: reportReducer,
  checkIn: checkInReducer,
  timeline: TimeLineReducer,
  announcement: announcementsReducer,
  social: socialReducer,
  tokens: tokenReducer,
  packges: PackageReducer,
  _loading: loadingSlice.reducer,
});
