import React, { useEffect, useState } from "react";
import {
    createEvent,
    doScheduleEmails,
    doSendEmailToAll,
    editEvent,
    editEventDetails,
    getEventsDetails,
    sendTestEmail,
} from "redux/Events/actions";
import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import prev from "../../assets/images/presentation-svgrepo-com.svg";
import send from "../../assets/images/send.svg";
import edit from "../../assets/images/white-edit.svg";
import sendAll2 from "../../assets/images/send3.svg";
import del from "../../assets/images/delete.svg";
import reminder from "../../assets/images/alarm-clock-solid.svg";
import Loader from "../../Components/Loader";
import CircularProgress from "@mui/material/CircularProgress";
// import JoditEditor from "jodit-react";
import JoditEditor from "jodit-pro-react";
import { useRef } from "react";
import { useParams } from "react-router";
import { useLoadingSelector } from "redux/selectors";
import { initalEvent } from "./data";
import firstTemp from "../../assets/images/Event Previ2ew/Event Previ2ew.png";
import secTemp from "../../assets/images/Home/Home.png";
import thirdTemp from "../../assets/images/thirdtemp.png";
import fourthTemp from "../../assets/images/temp4.png";
import dayjs from "dayjs";
import { useAppDispatch } from "redux/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

function Tempalte() {
    const defaultConfig = {
        license: "C464Q-1GIK1-BF6OK-K290Y",
        uploader: {
            url: "https://frontiers.o-projects.org/api/media/upload",
            format: "json",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            isSuccess: function (response) {
                // Assume success if there is a URL in the response
                return response && response.url;
            },
            getMsg: function (response) {
                // Return a success message or default message
                return response && response.url
                    ? "File uploaded successfully"
                    : "File upload failed";
            },
            process: function (response) {
                console.log(response.url); // Log the response for debugging
                return {
                    files: [
                        {
                            path: response.url,
                            type: "image",
                        },
                    ],
                    baseurl: "", // {string} Base url for filebrowser
                };
            },
            defaultHandlerSuccess: function (data) {
                var i,
                    field = "files";
                if (data[field] && data[field].length) {
                    for (i = 0; i < data[field].length; i += 1) {
                        this.selection.insertImage(data[field][i].path);
                    }
                }
            },
        },
    };
    const dispatch = useDispatch();
    const dispatch2 = useAppDispatch();
    const { event, loader } = useSelector((state) => state.events);
    const { selectedEvent } = useSelector((state) => state.events);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [type, setType] = useState();
    const [email, setNewEmail] = useState({
        type: "2",
        testemail: "",
        event_id: "",
    });
    const [error, setError] = useState(false);
    const [tempType, settempType] = useState(null);
    const editor = useRef(null);
    const [newSponsor, setNewSponsor] = useState(initalEvent);
    const isSendToAllLoading = useLoadingSelector(doSendEmailToAll);
    const isSendTestEmailLoading = useLoadingSelector(sendTestEmail);
    const params = useParams();
    //schedule
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
    const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));
    const isScheduleLoading = useLoadingSelector(doScheduleEmails);
    const schedule = () => {
        var form = new FormData();
        form.append("event_id", params.id);
        form.append("date", value.format("YYYY-MM-DD HH:mm:ss"));
        dispatch2(doScheduleEmails(form))
            .then(unwrapResult)
            .then((res) => {
                if (!res) {
                    toast.error("Something went wrong, Please try again");
                }
                setIsScheduleModalOpen(false);
            });
    };
    ///////////
    useEffect(() => {
        if (event) {
            setNewSponsor({
                ...newSponsor,
                customconfirmemail: event.customconfirmemail,
                custompreregistration: "<html></html>",
                customsmssenderid: "LaCasaExpo",
                smstemplate: "Thank you for purchasing your ticket",
                smsusername: "hdwxNtai",
                smspassword: "BfgELGANQZ",
                customreminderemail: event.customreminderemail,
                customremindersubject: event.customremindersubject,
                customconfirmsubject: event.customconfirmsubject,
                name: event.name,
                require_questions: event.require_questions,
                ar_name: event.ar_name,
                shorten_url: event?.shorten_url,
                company_id: event.company_id,
                location: event.location,
                supports_arabic: event.supports_arabic,
                facebook_link: event.facebook_link,
                whatsup_link: event.whatsup_link,
                template: event.template,
                ar_location: event.ar_location,
                gpslocation: event.gpslocation,
                startdate: event.startdate.split(" ")[0],
                from: event.startdate.split(" ")[1],
                enddate: event.enddate.split(" ")[0],
                to: event.enddate.split(" ")[1],
                logo: event.logo,
                description: event.description,
                ar_description: event.ar_description,
                banner: event.banner,
                published: event.published,
            });
        }
    }, [event, loader]);
    useEffect(() => {
        if (params.id) {
            dispatch(getEventsDetails(params.id));
        }
    }, [params]);

    const sendEmailToAll = (type) => {
        setType(type);
        var form = new FormData();
        form.append("event_id", params.id);
        form.append("type", type);
        dispatch(doSendEmailToAll(form));
    };

    const sendEmail = () => {
        if (email.testemail == "" && email.type == "") {
            setError(true);
        }
        var form = new FormData();
        form.append("event_id", params.id);
        form.append("testemail", email.testemail);
        form.append("template_type", email.type);

        dispatch(sendTestEmail(form));
    };
    const addind = () => {
        let body = { ...newSponsor };
        const form = new FormData();
        body.id = parseInt(event.id);
        for (var key in body) {
            form.append(key, body[key]);
        }

        dispatch(editEventDetails({ form, pagenumber: 0, archive: 0 }));

        setNewSponsor(initalEvent);
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };

    return (
        <>
            <Modal
                isOpen={sendModal}
                onRequestClose={() => {
                    setSendModal(false);
                    setNewEmail({ type: "", testemail: "", event_id: "" });
                    setError(false);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div style={{ width: "40vw" }}>
                    <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                        Send Test Email
                    </h1>
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Template
                                </span>
                                <select
                                    className="form-control form-control-lg form-control-solid"
                                    onChange={(e) => {
                                        setNewEmail({
                                            ...email,
                                            type: e.target.value,
                                        });
                                    }}
                                >
                                    <option value={2}>Reminder</option>
                                    <option value={1}>Confirmation</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Email:
                                </span>
                                <input
                                    style={
                                        error && email.testemail
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="testemail"
                                    placeholder=""
                                    value={email.testemail}
                                    onChange={(e) =>
                                        setNewEmail({
                                            ...email,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center px-5 pb-lg-0 my-10">
                        <button
                            type="button"
                            id="kt_password_reset_submit"
                            className="save-btn fw-bolder"
                            onClick={sendEmail}
                            disabled={isSendTestEmailLoading}
                        >
                            {isSendTestEmailLoading ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <span>Send Email</span>
                            )}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewSponsor(initalEvent);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create New Sponsor
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Template
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Email Subject
                                </span>
                                <input
                                    style={
                                        error &&
                                        (tempType == 1
                                            ? !newSponsor.customconfirmsubject
                                            : !newSponsor.customremindersubject)
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name={
                                        tempType == 1
                                            ? "customconfirmsubject"
                                            : "customremindersubject"
                                    }
                                    placeholder=""
                                    value={
                                        tempType == 1
                                            ? newSponsor.customconfirmsubject
                                            : newSponsor.customremindersubject
                                    }
                                    onChange={(e) =>
                                        setNewSponsor({
                                            ...newSponsor,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Email Body
                                </span>
                                <JoditEditor
                                    config={defaultConfig}
                                    ref={editor}
                                    style={
                                        error &&
                                        (tempType == 1
                                            ? !newSponsor.customconfirmemail
                                            : !newSponsor.customreminderemail)
                                            ? {
                                                  border: "1px solid red",
                                                  height: "7rem",
                                              }
                                            : { height: "7rem" }
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name={
                                        tempType == 1
                                            ? "customconfirmemail"
                                            : "customreminderemail"
                                    }
                                    placeholder=""
                                    value={
                                        tempType == 1
                                            ? newSponsor.customconfirmemail
                                            : newSponsor.customreminderemail
                                    }
                                    onChange={(newContent) => {
                                        if (tempType == 1) {
                                            setNewSponsor({
                                                ...newSponsor,
                                                customconfirmemail: newContent,
                                                // customconfirmemail: newContent.includes("@code")
                                                //   ? newContent
                                                //   : newContent.concat("", "@code"),
                                            });
                                        } else {
                                            setNewSponsor({
                                                ...newSponsor,
                                                customreminderemail: newContent,
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center px-5 pb-lg-0 my-10">
                        <button
                            type="button"
                            id="kt_password_reset_submit"
                            className="save-btn fw-bolder"
                            onClick={addind}
                        >
                            <span>Save Changes</span>
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isScheduleModalOpen}
                style={{
                    content: {
                        ...addModalStyles.content,
                        height: "30vh",
                        padding: "3rem",
                    },
                }}
                ariaHideApp={false}
            >
                <div
                    className="d-flex justify-content-center"
                    style={{ flexDirection: "column" }}
                >
                    <button
                        type="button"
                        id="kt_password_reset_submit"
                        className="save-btn fw-bolder"
                        onClick={schedule}
                        style={{ marginTop: "3rem" }}
                    >
                        {isScheduleLoading ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            <span>Schedule Emails</span>
                        )}
                    </button>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"Templates"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Templates"}
                        EventName={params.id}
                        showUrl={true}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    className="row"
                                    style={{ marginTop: "1rem" }}
                                >
                                    <div>
                                        <div className="row">
                                            <div
                                                style={
                                                    newSponsor.template == "1"
                                                        ? {}
                                                        : {}
                                                }
                                                onClick={(e) =>
                                                    setNewSponsor({
                                                        ...newSponsor,
                                                        template: "1",
                                                    })
                                                }
                                                className="col-6"
                                            >
                                                <img
                                                    for="payment"
                                                    src={firstTemp}
                                                    style={{
                                                        margin: "auto",
                                                        display: "flex",
                                                    }}
                                                ></img>
                                                <div
                                                    className="d-flex"
                                                    style={{
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            checked={
                                                                newSponsor.template ==
                                                                1
                                                                    ? true
                                                                    : false
                                                            }
                                                        ></input>
                                                        <label
                                                            style={{
                                                                margin: "0.5rem 1rem",
                                                            }}
                                                        >
                                                            Reservation website
                                                        </label>
                                                    </div>
                                                    <div
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            window.open(
                                                                `https://web.frontiers.events/${event.alias}`,
                                                                "_blank"
                                                            );
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <img
                                                            src={prev}
                                                            style={{
                                                                margin: "0 0.2rem",
                                                            }}
                                                        ></img>
                                                        Preview
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={
                                                    newSponsor.template == "2"
                                                        ? {}
                                                        : {}
                                                }
                                                onClick={(e) =>
                                                    setNewSponsor({
                                                        ...newSponsor,
                                                        template: "2",
                                                    })
                                                }
                                                className="col-6"
                                            >
                                                <img
                                                    for="payment"
                                                    src={secTemp}
                                                    style={{
                                                        margin: "auto",
                                                        display: "flex",
                                                    }}
                                                ></img>
                                                <div
                                                    className="d-flex"
                                                    style={{
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            checked={
                                                                newSponsor.template ==
                                                                2
                                                                    ? true
                                                                    : false
                                                            }
                                                        ></input>
                                                        <label
                                                            style={{
                                                                margin: "0.5rem 1rem",
                                                            }}
                                                        >
                                                            Whole Website
                                                        </label>
                                                    </div>
                                                    <div
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            window.open(
                                                                `https://demo.frontiers.events/${event.alias}`,
                                                                "_blank"
                                                            );
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <img
                                                            src={prev}
                                                            style={{
                                                                margin: "0 0.2rem",
                                                            }}
                                                        ></img>
                                                        Preview
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={
                                                    newSponsor.template == "2"
                                                        ? {}
                                                        : {}
                                                }
                                                onClick={(e) =>
                                                    setNewSponsor({
                                                        ...newSponsor,
                                                        template: "2",
                                                    })
                                                }
                                                className="col-6"
                                            >
                                                <img
                                                    for="payment"
                                                    src={thirdTemp}
                                                    style={{
                                                        margin: "auto",
                                                        display: "flex",
                                                        width: "25rem",
                                                    }}
                                                ></img>
                                                <div
                                                    className="d-flex"
                                                    style={{
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            checked={
                                                                newSponsor.template ==
                                                                2
                                                                    ? true
                                                                    : false
                                                            }
                                                        ></input>
                                                        <label
                                                            style={{
                                                                margin: "0.5rem 1rem",
                                                            }}
                                                        >
                                                            Dark themed website
                                                        </label>
                                                    </div>
                                                    <div
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            window.open(
                                                                `https://landing.frontiers.events/${event.alias}`,
                                                                "_blank"
                                                            );
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <img
                                                            src={prev}
                                                            style={{
                                                                margin: "0 0.2rem",
                                                            }}
                                                        ></img>
                                                        Preview
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={
                                                    newSponsor.template == "2"
                                                        ? {}
                                                        : {}
                                                }
                                                onClick={(e) =>
                                                    setNewSponsor({
                                                        ...newSponsor,
                                                        template: "4",
                                                    })
                                                }
                                                className="col-6"
                                            >
                                                <img
                                                    for="payment"
                                                    src={fourthTemp}
                                                    style={{
                                                        margin: "auto",
                                                        display: "flex",
                                                        width: "25rem",
                                                    }}
                                                ></img>
                                                <div
                                                    className="d-flex"
                                                    style={{
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            checked={
                                                                newSponsor.template ==
                                                                4
                                                                    ? true
                                                                    : false
                                                            }
                                                        ></input>
                                                        <label
                                                            style={{
                                                                margin: "0.5rem 1rem",
                                                            }}
                                                        >
                                                            Register page
                                                        </label>
                                                    </div>
                                                    <div
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            window.open(
                                                                `https://register.frontiers.events/${event.alias}`,
                                                                "_blank"
                                                            );
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <img
                                                            src={prev}
                                                            style={{
                                                                margin: "0 0.2rem",
                                                            }}
                                                        ></img>
                                                        Preview
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center px-5 pb-lg-0 my-10">
                                            <button
                                                type="button"
                                                id="kt_password_reset_submit"
                                                className="save-btn fw-bolder"
                                                onClick={addind}
                                            >
                                                <span>Save Changes</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Tempalte;
