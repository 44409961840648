import React, { useEffect, useMemo, useRef, useState } from "react";

import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import inc from "../../assets/images/increase.svg";
import dec from "../../assets/images/decrease.svg";
import TextField from "@mui/material/TextField";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/material/Switch";
import { getCompanies } from "redux/Companies/actions";
import Pagintation from "../../Components/Pagintation";

import { DeleteCode, getCodes } from "redux/Codes/actions";
import {
    CreateSection,
    DeleteSection,
    EditSection,
    getSections,
} from "redux/PageBuilder/actions";
// import JoditEditor from "jodit-react";
import JoditEditor from "jodit-pro-react";
import { useParams } from "react-router";

const initalSection = {
    name: "",
    sectionorder: 1,
    type: null,
    title: "",
    title_ar: "",
    description: "",
    description_ar: "",
    extra: "",
    event_id: null,
};
const route = process.env.REACT_APP_IMAGE_URI;

function PageBuilder() {
    const defaultConfig = {
        license: "C464Q-1GIK1-BF6OK-K290Y",
        uploader: {
            url: "https://frontiers.o-projects.org/api/media/upload",
            format: "json",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            isSuccess: function (response) {
                // Assume success if there is a URL in the response
                return response && response.url;
            },
            getMsg: function (response) {
                // Return a success message or default message
                return response && response.url
                    ? "File uploaded successfully"
                    : "File upload failed";
            },
            process: function (response) {
                console.log(response.url); // Log the response for debugging

                // Determine the file type based on the response or other logic
                const fileType = response.url.match(/\.(jpg|jpeg|png|gif)$/i)
                    ? "image"
                    : "file";

                return {
                    files: [
                        {
                            path: response.url,
                            type: fileType,
                        },
                    ],
                    baseurl: "", // Base url for filebrowser
                };
            },
            defaultHandlerSuccess: function (data) {
                const field = "files";
                if (data[field] && data[field].length) {
                    for (let i = 0; i < data[field].length; i++) {
                        const file = data[field][i];

                        // Insert image if it's an image
                        if (file.type === "image") {
                            this.selection.insertImage(file.path);
                        }
                        // Insert link if it's a file (non-image)
                        else if (file.type === "file") {
                            const fileName = file.path.split("/").pop(); // Get file name from path
                            this.selection.insertHTML(
                                `<a href="${file.path}" target="_blank">${fileName}</a>`
                            );
                        }
                    }
                }
            },
        },
    };
    const dispatch = useDispatch();
    const params = useParams();
    const { sections, loader, total } = useSelector((state) => state.sections);
    const { selectedCompany, selectedEvent, event } = useSelector(
        (state) => state.events
    );
    const editor = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [eventModal, setEventModal] = useState(false);
    const [error, setError] = useState(false);
    const [newSection, setNewSection] = useState(initalSection);
    useEffect(() => {
        if (params.id) {
            dispatch(
                getSections({ event_id: params.id, pagenumber: pageNumber })
            );
        }
    }, [pageNumber, params]);

    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#00D7E1",
            },
            secondary: {
                // This is green.A700 as hex.
                main: "#222222",
            },
        },
    });

    const addind = () => {
        if (
            newSection.name == "" ||
            newSection.title == "" ||
            newSection.description == "" ||
            !newSection.type ||
            !newSection.sectionorder
        ) {
            setError(true);
            return;
        }
        if (
            event.supports_arabic == 1 &&
            (newSection.title_ar == "" || newSection.description_ar == "")
        ) {
            setError(true);
            return;
        }
        if (updateFlag != -1) {
            let form = { ...newSection };
            form.id = parseInt(updateFlag);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(EditSection({ body, selectedEvent: params.id }));
            setEventModal(false);
        } else {
            let form = { ...newSection };
            form.event_id = parseInt(params.id);

            const body = new FormData();
            for (var key in form) {
                body.append(key, form[key]);
            }
            dispatch(CreateSection({ body, selectedEvent: params.id }));
            setEventModal(false);
        }
        setNewSection(initalSection);
        setError(false);
        setUpdateFlag(-1);
    };
    const addModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: 0,
            transform: "translate(-50%, -50%)",
            borderRaduis: "16px",
            overflowY: "auto",
            height: "90vh",
        },
    };

    const changeReachTextValue = useMemo(
        () => (
            <JoditEditor
                config={defaultConfig}
                ref={editor}
                style={
                    error && !newSection.description
                        ? { border: "1px solid red", height: "7rem" }
                        : { height: "7rem" }
                }
                type="text"
                className="form-control form-control-lg form-control-solid"
                name="description"
                placeholder=""
                value={newSection?.description}
                onChange={(newContent) => {
                    setNewSection({
                        ...newSection,
                        description: newContent,
                    });
                }}
            />
        ),
        [editor]
    );

    const changeReachTextValu_AR = useMemo(
        () => (
            <JoditEditor
                config={defaultConfig}
                ref={editor}
                style={
                    error && !newSection.description_ar
                        ? { border: "1px solid red", height: "7rem" }
                        : { height: "7rem" }
                }
                type="text"
                className="form-control form-control-lg form-control-solid"
                name="description"
                placeholder=""
                value={newSection?.description_ar}
                onChange={(newContent) => {
                    setNewSection({
                        ...newSection,
                        description_ar: newContent,
                    });
                }}
            />
        ),
        []
    );

    return (
        <>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewSection(initalSection);
                    setError(false);
                    setUpdateFlag(-1);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create New Section
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <>
                            <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                                Edit Section
                            </h1>
                        </>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Type
                                    </span>
                                    <select
                                        style={
                                            error && !newSection.type
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="type"
                                        placeholder=""
                                        value={newSection.type}
                                        onChange={(e) =>
                                            setNewSection({
                                                ...newSection,
                                                [e.target.name]: parseInt(
                                                    e.target.value
                                                ),
                                            })
                                        }
                                    >
                                        <option value={null}></option>
                                        <option value={1}>Text</option>
                                        <option value={2}>
                                            Tickets Section
                                        </option>
                                        <option value={3}>
                                            Description Section
                                        </option>
                                        <option value={4}>Youtube Link</option>
                                        <option value={5}>Header</option>
                                        <option value={6}>Footer</option>
                                        <option value={7}>Module</option>
                                        <option value={8}>Banner</option>
                                    </select>
                                </div>
                            </div>
                            {newSection.type == 7 ? (
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <span
                                            className="d-flex align-items-center py-2 "
                                            style={{ color: "black" }}
                                        >
                                            Section name
                                        </span>
                                        <select
                                            style={
                                                error && !newSection.name
                                                    ? {
                                                          border: "1px solid red",
                                                      }
                                                    : {}
                                            }
                                            type="text"
                                            className="form-control form-control-lg form-control-solid"
                                            name="name"
                                            placeholder=""
                                            value={newSection.name}
                                            onChange={(e) =>
                                                setNewSection({
                                                    ...newSection,
                                                    [e.target.name]:
                                                        e.target.value,
                                                })
                                            }
                                        >
                                            <option value={null}></option>
                                            <option value={"Speakers"}>
                                                Speakers
                                            </option>
                                            <option value={"Sessions"}>
                                                Sessions
                                            </option>
                                            <option value={"Booths"}>
                                                Booths
                                            </option>
                                            <option value={"Partners"}>
                                                Partners
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            ) : (
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <span
                                            className="d-flex align-items-center py-2 "
                                            style={{ color: "black" }}
                                        >
                                            Section name
                                        </span>
                                        <input
                                            style={
                                                error && !newSection.name
                                                    ? {
                                                          border: "1px solid red",
                                                      }
                                                    : {}
                                            }
                                            type="text"
                                            className="form-control form-control-lg form-control-solid"
                                            name="name"
                                            placeholder=""
                                            value={newSection?.name}
                                            onChange={(e) =>
                                                setNewSection({
                                                    ...newSection,
                                                    [e.target.name]:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Section title
                                    </span>
                                    <input
                                        style={
                                            error && !newSection.title
                                                ? { border: "1px solid red" }
                                                : {}
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="title"
                                        placeholder=""
                                        value={newSection?.title}
                                        onChange={(e) =>
                                            setNewSection({
                                                ...newSection,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            {event.supports_arabic == 1 && (
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <span
                                            className="d-flex align-items-center py-2 "
                                            style={{ color: "black" }}
                                        >
                                            Section title in Arabic
                                        </span>
                                        <input
                                            style={
                                                error && !newSection.title_ar
                                                    ? {
                                                          border: "1px solid red",
                                                      }
                                                    : {}
                                            }
                                            type="text"
                                            className="form-control form-control-lg form-control-solid"
                                            name="title_ar"
                                            placeholder=""
                                            value={newSection?.title_ar}
                                            onChange={(e) =>
                                                setNewSection({
                                                    ...newSection,
                                                    [e.target.name]:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            {newSection.type == 8 && (
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <label
                                            style={{ color: "black" }}
                                            className="d-flex align-items-center fs-7 mb-2"
                                        >
                                            Banner image
                                        </label>

                                        {newSection.extra ? (
                                            <>
                                                <img
                                                    style={{
                                                        maxWidth: "35%",
                                                        height: "7rem",
                                                    }}
                                                    src={
                                                        newSection.extra
                                                            ? newSection.extra
                                                                  ?.name
                                                                ? URL.createObjectURL(
                                                                      newSection.extra
                                                                  )
                                                                : `${route}${newSection.extra}`
                                                            : ""
                                                    }
                                                ></img>
                                                <div
                                                    className="d-flex"
                                                    style={{ margin: "1rem 0" }}
                                                >
                                                    <label className="circles">
                                                        <img
                                                            src={edit}
                                                            style={{
                                                                width: "1rem",
                                                            }}
                                                        ></img>
                                                        <input
                                                            name="extra"
                                                            value={""}
                                                            onChange={(e) =>
                                                                setNewSection({
                                                                    ...newSection,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .files[0],
                                                                })
                                                            }
                                                            type="file"
                                                            style={{
                                                                display: "none",
                                                                cursor: "pointer",
                                                            }}
                                                            accept=".png, .jpg, .jpeg, .svg"
                                                        />
                                                    </label>
                                                    <div className="separtor"></div>
                                                    <div className="circles">
                                                        <img
                                                            src={del}
                                                            style={{
                                                                width: "0.8rem",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setNewSection({
                                                                    ...newSection,
                                                                    extra: null,
                                                                });
                                                            }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <label className="upload-btn">
                                                <input
                                                    style={
                                                        error &&
                                                        !newSection.extra
                                                            ? {
                                                                  border: "1px solid red",
                                                              }
                                                            : {}
                                                    }
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    name="extra"
                                                    value={""}
                                                    accept=".png, .jpg, .jpeg, .svg"
                                                    onChange={(e) => {
                                                        setNewSection(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .files[0],
                                                            })
                                                        );
                                                    }}
                                                />
                                                Upload image
                                            </label>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        Section order
                                    </span>
                                    <div style={{ display: "flex" }}>
                                        <img
                                            src={dec}
                                            onClick={() => {
                                                if (
                                                    newSection.sectionorder != 1
                                                ) {
                                                    setNewSection({
                                                        ...newSection,
                                                        sectionorder:
                                                            newSection.sectionorder -
                                                            1,
                                                    });
                                                }
                                            }}
                                            style={{
                                                cursor: "pointer",
                                                width: "1.5rem",
                                            }}
                                        ></img>
                                        <span
                                            style={{
                                                margin: "0 7px",
                                                fontWeight: "bold",
                                                fontSize: "1.5rem",
                                            }}
                                        >
                                            {newSection.sectionorder}
                                        </span>
                                        <img
                                            src={inc}
                                            style={{
                                                cursor: "pointer",
                                                width: "1.5rem",
                                            }}
                                            onClick={() => {
                                                setNewSection({
                                                    ...newSection,
                                                    sectionorder:
                                                        newSection.sectionorder +
                                                        1,
                                                });
                                            }}
                                        ></img>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-5">
                                <div className="col-12">
                                    <span
                                        className="d-flex align-items-center py-2 "
                                        style={{ color: "black" }}
                                    >
                                        {newSection.type == 4
                                            ? "Youtube link"
                                            : newSection.type == 5 ||
                                              newSection.type == 6
                                            ? "HTML Code"
                                            : "Description"}
                                    </span>
                                    {/* {changeReachTextValue} */}
                                    <JoditEditor
                                        config={defaultConfig}
                                        ref={editor}
                                        style={
                                            error && !newSection.description
                                                ? {
                                                      border: "1px solid red",
                                                      height: "7rem",
                                                  }
                                                : { height: "7rem" }
                                        }
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        name="description"
                                        placeholder=""
                                        value={newSection.description}
                                        onChange={(newContent) => {
                                            setNewSection((prev) => {
                                                return {
                                                    ...prev,
                                                    description: newContent,
                                                };
                                            });
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: "0.5rem" }}>
                                    <span>
                                        * Description Limit is 255 character
                                    </span>
                                </div>
                            </div>
                            {event.supports_arabic == 1 && (
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <span
                                            className="d-flex align-items-center py-2 "
                                            style={{ color: "black" }}
                                        >
                                            {newSection.type == 4
                                                ? "Youtube link in Arabic"
                                                : newSection.type == 5 ||
                                                  newSection.type == 6
                                                ? "HTML Code in Arabic"
                                                : "Description in Arabic"}
                                        </span>
                                        {/* {changeReachTextValu_AR} */}
                                        <JoditEditor
                                            config={defaultConfig}
                                            ref={editor}
                                            style={
                                                error &&
                                                !newSection.description_ar
                                                    ? {
                                                          border: "1px solid red",
                                                          height: "7rem",
                                                      }
                                                    : { height: "7rem" }
                                            }
                                            type="text"
                                            className="form-control form-control-lg form-control-solid"
                                            name="description"
                                            placeholder=""
                                            value={newSection?.description_ar}
                                            onChange={(newContent) => {
                                                setNewSection({
                                                    ...newSection,
                                                    description_ar: newContent,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div style={{ marginTop: "0.5rem" }}>
                                        <span>
                                            * Description Limit is 255 character
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                            <button
                                type="button"
                                id="kt_password_reset_submit"
                                className="save-btn fw-bolder"
                                onClick={addind}
                            >
                                <span>Save Changes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="aside-div">
                <AsideMenu selected={"Page Builder"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Page Builder"}
                        // setSearchText={setSearchText}
                        showUrl={true}
                        EventName={params.id}
                    ></PageHeader>
                    <div className="event-page">
                        {!loader ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {total} results found
                                    </div>
                                    <Pagintation
                                        setPageNumber={setPageNumber}
                                    ></Pagintation>
                                </div>
                                <div className="row" style={{ margin: "0" }}>
                                    <table className="table table-striped gy-2 gs-7">
                                        <thead>
                                            <tr
                                                className="fw-bolder fs-6  border-bottom border-gray-200"
                                                style={{
                                                    backgroundColor: "#2B6EC8",
                                                    borderRadius: "16px",
                                                }}
                                            >
                                                <th
                                                    className="tfirst"
                                                    style={{
                                                        width: "5%",
                                                        color: "white",
                                                    }}
                                                >
                                                    #
                                                </th>
                                                <th
                                                    style={{
                                                        width: "5%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Title
                                                </th>
                                                <th
                                                    style={{
                                                        width: "5%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Type
                                                </th>
                                                <th
                                                    style={{
                                                        width: "5%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Section order
                                                </th>
                                                <th
                                                    className="tlast"
                                                    style={{
                                                        width: "5%",
                                                        color: "white",
                                                    }}
                                                >
                                                    Actions
                                                </th>
                                            </tr>

                                            {sections.map((code, index) => (
                                                <React.Fragment key={index}>
                                                    <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                                                        <th
                                                            style={{
                                                                color: "black",
                                                                width: "25%",
                                                            }}
                                                        >
                                                            {code.id}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                                width: "25%",
                                                            }}
                                                        >
                                                            {code.title}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                                width: "25%",
                                                            }}
                                                        >
                                                            {code.type}
                                                        </th>
                                                        <th
                                                            style={{
                                                                color: "black",
                                                                width: "25%",
                                                            }}
                                                        >
                                                            {code.sectionorder}
                                                        </th>

                                                        <th
                                                            style={{
                                                                width: "5%",
                                                            }}
                                                        >
                                                            <span
                                                                onClick={() => {
                                                                    setUpdateFlag(
                                                                        code.id
                                                                    );

                                                                    setNewSection(
                                                                        {
                                                                            name: code.name,
                                                                            title: code.title,
                                                                            title_ar:
                                                                                code.title_ar,
                                                                            type: code.type,
                                                                            sectionorder:
                                                                                code.sectionorder,
                                                                            description:
                                                                                code.description,
                                                                            description_ar:
                                                                                code.description_ar,
                                                                            event_id:
                                                                                code.event_id,
                                                                            extra: code?.extra,
                                                                        }
                                                                    );
                                                                    setEventModal(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={edit}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        marginRight:
                                                                            "1rem",
                                                                    }}
                                                                ></img>
                                                            </span>
                                                            <span
                                                                onClick={() => {
                                                                    dispatch(
                                                                        DeleteSection(
                                                                            {
                                                                                updateFlag:
                                                                                    code.id,
                                                                                selectedEvent:
                                                                                    params.id,
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={del}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                ></img>
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                        </thead>
                                    </table>
                                    <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                                        <button
                                            type="button"
                                            id="kt_password_reset_submit"
                                            className="save-btn fw-bolder"
                                            onClick={() => {
                                                setEventModal(true);
                                            }}
                                        >
                                            <span>Add Section</span>
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Loader></Loader>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default PageBuilder;
