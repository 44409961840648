import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;
const route1 = process.env.REACT_APP_API2;

export const getFaqs = createAsyncThunk(
    "get/faqs",
    async ({ id, pagenumber }) => {
        try {
            const response = await axios({
                method: "GET",
                url: `faqs/list?event_id=${id}&page=${pagenumber + 1}`,

                params: pagenumber,
            });
            return response.data;
        } catch (err) {
            //return rejectWithValue(err.response.data);
        }
    }
);

export const CreateFaq = createAsyncThunk(
    "create/faqs",
    async ({ body }, { dispatch }) => {
        const id = body.get("event_id");
        const pageNumber = body.get("pageNumber");

        try {
            const response = await axios({
                method: "POST",
                url: "faqs/create",

                data: body,
            });
            toast.success("faq Created successfully");
            dispatch(getFaqs({ id: id, pagenumber: pageNumber }));

            return response.data;
        } catch (err) {
            toast.error("Error in creating faq");
        }
    }
);
export const EditFaq = createAsyncThunk(
    "edit/faqs",
    async ({ body }, { dispatch }) => {
        const id = body.get("event_id");
        const faq_id = body.get("id");
        const pageNumber = body.get("pageNumber");
        body.delete("pageNumber");
        body.delete("id");

        let data = { ...Object.fromEntries(body) };

        try {
            const response = await axios({
                method: "PUT",
                url: "faqs/update/" + faq_id,

                data: data,
            });

            toast.success("faq Edited successfully");
            dispatch(getFaqs({ id: id, pagenumber: pageNumber }));

            return response.data;
        } catch (err) {
            toast.error("Error in creating faq");
        }
    }
);
export const DeleteFaq = createAsyncThunk(
    "delete/faqs",
    async (body, { dispatch }) => {
        const id = body.event_id;
        const faq_id = body.id;
        const pageNumber = body.pageNumber;
        try {
            const response = await axios({
                method: "DELETE",
                url: `faqs/${faq_id}`,
            });

            if (
                response.status == 200 ||
                response.status == 204 ||
                response.status == 201
            ) {
                toast.success("Faq Deleted successfully");
                dispatch(getFaqs({ id: id, pagenumber: pageNumber }));
            }

            return response.data;
        } catch (err) {
            toast.error("Error in creating faq");
        }
    }
);
