import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsideMenu from "../../Components/Asidemenu2";
import Loader from "../../Components/Loader";
import PageHeader from "../../Components/PageHeader";
import glyph from "../../assets/images/Glyph.svg";
import { TextField } from "@mui/material";
import Modal from "react-modal";
import inc from "../../assets/images/increase.svg";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
    createEventAgenda,
    editEventAgenda,
    getEventAgenda,
} from "../../redux/Agenda/actions";

import JoditEditor from "jodit-pro-react";
// // import JoditEditor from "jodit-react";

import { useParams } from "react-router";
const initalAgenda = {
    title: "",
    description: "",
    event_id: null,
    days: [{ title: "1", date: "" }],
};
const addModalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        padding: 0,
        transform: "translate(-50%, -50%)",
        borderRaduis: "16px",
        overflowY: "auto",
        height: "90vh",
    },
};
function Agenda() {
    const defaultConfig = {
        license: "C464Q-1GIK1-BF6OK-K290Y",
        uploader: {
            url: "https://frontiers.o-projects.org/api/media/upload",
            format: "json",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            isSuccess: function (response) {
                // Assume success if there is a URL in the response
                return response && response.url;
            },
            getMsg: function (response) {
                // Return a success message or default message
                return response && response.url
                    ? "File uploaded successfully"
                    : "File upload failed";
            },
            process: function (response) {
                console.log(response.url); // Log the response for debugging

                // Determine the file type based on the response or other logic
                const fileType = response.url.match(/\.(jpg|jpeg|png|gif)$/i)
                    ? "image"
                    : "file";

                return {
                    files: [
                        {
                            path: response.url,
                            type: fileType,
                        },
                    ],
                    baseurl: "", // Base url for filebrowser
                };
            },
            defaultHandlerSuccess: function (data) {
                const field = "files";
                if (data[field] && data[field].length) {
                    for (let i = 0; i < data[field].length; i++) {
                        const file = data[field][i];

                        // Insert image if it's an image
                        if (file.type === "image") {
                            this.selection.insertImage(file.path);
                        }
                        // Insert link if it's a file (non-image)
                        else if (file.type === "file") {
                            const fileName = file.path.split("/").pop(); // Get file name from path
                            this.selection.insertHTML(
                                `<a href="${file.path}" target="_blank">${fileName}</a>`
                            );
                        }
                    }
                }
            },
        },
    };
    const { selectedCompany, selectedEvent } = useSelector(
        (state) => state.events
    );
    const { loader, agenda } = useSelector((state) => state.agenda);
    const dispatch = useDispatch();
    const params = useParams();
    const [Company, setSelectedCompany] = useState("");
    const [Event, setSelectedEvent] = useState("");
    const [updateFlag, setUpdateFlag] = useState(-1);
    const [newAgenda, setNewAgenda] = useState(initalAgenda);
    const [eventModal, setEventModal] = useState(false);
    const [error, setError] = useState(false);
    const editor = useRef(null);
    useEffect(() => {
        if (selectedCompany && params.id) {
            setSelectedCompany(selectedCompany);
            setSelectedEvent(params.id);
        }
    }, [selectedCompany, params]);
    useEffect(() => {
        if (Event) {
            dispatch(getEventAgenda({ event_id: Event }));
        }
    }, [Event]);
    useEffect(() => {
        if (agenda.atitle || agenda.adesc) {
            setUpdateFlag(params.id);
            setNewAgenda({
                title: agenda.atitle,
                description: agenda.adesc,
                days: agenda.days,
            });
        } else {
            setUpdateFlag(-1);
        }
    }, [agenda, selectedEvent]);
    const addind = () => {
        if (newAgenda.title == "" || newAgenda.description == "") {
            setError(true);
            return;
        }
        for (let i = 0; i < newAgenda.days.length; i++) {
            if (newAgenda.days[i].date == "") {
                setError(true);
                return;
            }
        }
        if (updateFlag != -1) {
            let body = { ...newAgenda };
            body.event_id = parseInt(params.id);
            dispatch(editEventAgenda({ body, selectedEvent: params.id }));
            setEventModal(false);
        } else {
            let body = { ...newAgenda };
            body.event_id = parseInt(params.id);
            dispatch(createEventAgenda({ body, selectedEvent: params.id }));
            setEventModal(false);
        }
        setNewAgenda(initalAgenda);
        setError(false);
        setUpdateFlag(-1);
    };
    return (
        <>
            <Modal
                isOpen={eventModal}
                onRequestClose={() => {
                    setEventModal(false);
                    setNewAgenda(initalAgenda);
                }}
                style={addModalStyles}
                ariaHideApp={false}
            >
                <div className="modal-input">
                    {updateFlag == -1 && (
                        <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
                            Create New Agenda
                        </h1>
                    )}
                    {updateFlag != -1 && (
                        <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                            Edit Agenda
                        </h1>
                    )}
                    <div className="flex-center" style={{ padding: "3rem" }}>
                        <div className="row mb-5">
                            <div className="col-12">
                                <span
                                    className="d-flex align-items-center py-2 "
                                    style={{ color: "black" }}
                                >
                                    Agenda title
                                </span>
                                <input
                                    // style={
                                    //   error && !newanswer.name ? { border: "1px solid red" } : {}
                                    // }
                                    style={
                                        error && !newAgenda.title
                                            ? { border: "1px solid red" }
                                            : {}
                                    }
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    name="title"
                                    placeholder=""
                                    value={newAgenda.title}
                                    onChange={(e) =>
                                        setNewAgenda({
                                            ...newAgenda,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row px-2 mb-5">
                            <span
                                className="d-flex align-items-center "
                                style={{ color: "black" }}
                            >
                                Description
                            </span>
                            <JoditEditor
                                config={defaultConfig}
                                ref={editor}
                                style={
                                    error && !newAgenda.description
                                        ? {
                                              border: "1px solid red",
                                              height: "7rem",
                                          }
                                        : { height: "7rem" }
                                }
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                name="description"
                                placeholder=""
                                value={newAgenda?.description}
                                onChange={(newContent) => {
                                    setNewAgenda({
                                        ...newAgenda,
                                        description: newContent,
                                    });
                                }}
                            />
                        </div>
                        <div style={{ marginTop: "0.5rem" }}>
                            <span>* Description Limit is 255 character</span>
                        </div>
                        <div className="row mb-5 ">
                            {newAgenda.days.map((day, index) => (
                                <>
                                    <div className="col-6 fv-row mb-5 fv-plugins-icon-container">
                                        <span
                                            className="d-flex align-items-center "
                                            style={{ color: "black" }}
                                        >
                                            Day
                                        </span>
                                        <input
                                            // style={
                                            //   error && !newanswer.name ? { border: "1px solid red" } : {}
                                            // }

                                            type="text"
                                            className="form-control form-control-lg form-control-solid"
                                            placeholder=""
                                            disabled="true"
                                            value={newAgenda.days[index].title}
                                        ></input>
                                    </div>
                                    <div className="col-6 fv-row mb-5 fv-plugins-icon-container">
                                        <span
                                            className="d-flex align-items-center "
                                            style={{ color: "black" }}
                                        >
                                            Date
                                        </span>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <TextField
                                                id="date"
                                                type="date"
                                                defaultValue="2017-05-24"
                                                sx={
                                                    error &&
                                                    !newAgenda?.days[index].date
                                                        ? {
                                                              width: "100%",
                                                              backgroundColor:
                                                                  "red",
                                                              borderRadius:
                                                                  "16px",
                                                              border: "none",
                                                          }
                                                        : {
                                                              width: "100%",
                                                              backgroundColor:
                                                                  "#eaf4fa",
                                                              borderRadius:
                                                                  "16px",
                                                              border: "none",
                                                          }
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                    disableUnderline: true,
                                                }}
                                                value={
                                                    newAgenda?.days[index].date
                                                }
                                                onChange={(e) => {
                                                    let session = [
                                                        ...newAgenda.days,
                                                    ];
                                                    session[index].date =
                                                        e.target.value;
                                                    setNewAgenda({
                                                        ...newAgenda,
                                                        days: session,
                                                    });
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </>
                            ))}
                        </div>
                        <div className="row">
                            <div
                                className="col-6 fv-row mb-5 fv-plugins-icon-container"
                                onClick={() => {
                                    let arr = [...newAgenda.days];
                                    arr.push({
                                        title: `${newAgenda.days.length + 1}`,
                                        date: "",
                                    });
                                    setNewAgenda({ ...newAgenda, days: arr });
                                }}
                            >
                                <img
                                    src={inc}
                                    style={{
                                        cursor: "pointer",
                                        width: "1rem",
                                        marginInlineEnd: "0.5rem",
                                    }}
                                ></img>
                                Add Event Day
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                            <button
                                type="button"
                                id="kt_password_reset_submit"
                                className="save-btn fw-bolder"
                                onClick={addind}
                            >
                                <span>Save Changes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="aside-div">
                <AsideMenu selected={"Agenda"}></AsideMenu>
                <div className="d-block main-page" style={{ width: "100%" }}>
                    <PageHeader
                        title={"Agenda"}
                        setSearchText={""}
                        showUrl={true}
                        EventName={params.id}
                    ></PageHeader>
                    {loader ? (
                        <Loader></Loader>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginTop: "5rem",
                            }}
                        >
                            <div>
                                <img
                                    src={glyph}
                                    style={{ width: "5rem" }}
                                ></img>
                            </div>
                            <div className="agenda-text">
                                {updateFlag == -1
                                    ? "Create Event's agenda"
                                    : "Update Event's agenda"}
                            </div>
                            <div style={{ marginTop: "52px" }}>
                                <button
                                    className="login-btn"
                                    onClick={() => {
                                        setEventModal(true);
                                    }}
                                >
                                    {updateFlag == -1
                                        ? "Create Agenda"
                                        : "Update Agenda"}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Agenda;
